import React from 'react';
import { Paper, Typography, TextField, Button, IconButton, MenuItem, Select, FormControl, InputLabel, withStyles } from '@material-ui/core';
import Progres from './Progres';
import Table from './TablePreAtendimento';
import Remarcar from './Remarcar';
import DialogTableConfirma from '../Remarcar/DialogTableConfirmaContato';
import './Recepcao.css'
import SaveIcon from '@material-ui/icons/Save';
import DataRanger from './DataRanger';
import './Dataranger.css';
import api from '../../services/api'
/*Alertas */
import Alert from '../Alertas/Alert';
import Confirm from '../Alertas/Confirm';
//Hash de URL para impressão das informações de agendamento
import Hashids from 'hashids';
import Atendimento from '../Atendimento/Atendimento'
import Arrow from '@material-ui/icons/ArrowBack';

import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';
import Encaixe from './Encaixe';

import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from '@material-ui/core/OutlinedInput';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DialogImpressao from './DialogImpressao';
import DialogAnexo from '../Atendimento/DialogAnexo';

class Recepcao extends React.Component {

    constructor(props) {
        const unidades = JSON.parse(localStorage.getItem('unidades'));
        super(props);
        this.state = {
            unidade: JSON.parse(localStorage.getItem('unidades')).map(u => u.value).join(),
            unidades: [{ value: unidades.map(u => u.value).join(), label: "TODAS UNIDADES" }].concat(unidades),


            setores: [],
            setor: '',

            selectPrestadors: [],
            selectPrestador: null,

            isDisabledUnidade: false,
            queue: this.props.queue,

            loading: false,
            success: false,

            dtInicio: format(new Date(), 'yyyy-MM-dd'),
            dtFim: format(new Date(), 'yyyy-MM-dd'),

            selected: [],
            exames_selected: [],

            impressao: false,

            callcenter: this.props.options.callcenter != undefined ? this.props.options.callcenter : false,

            prestador: "",

            dialogAnexo: false,
            item_anexo: []
        };
        this.myRef = React.createRef();
        this.myRefConfirm = React.createRef();
        this.handleChangePrestador = this.handleChangePrestador.bind(this)
        this.handleChangePaciente = this.handleChangePaciente.bind(this)
        this.handleCdAtendimento = this.handleCdAtendimento.bind(this)
        this.handleChangeCheck = this.handleChangeCheck.bind(this)
        this.closeTelaAtendimento = this.closeTelaAtendimento.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.handleCloseConfirmF = this.handleCloseConfirmF.bind(this)
        this.handleCloseConfirmT = this.handleCloseConfirmT.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.getSetor(this.state.unidade);
    }
    handleChangeCheck = event => {
        this.setState({ status: event.target.value });
    };
    onClickData = (inicio, fim, data) => {
        this.setState({ dtInicio: inicio, dtFim: fim });
    }
    handleChangeUnidade = (event) => {
        this.setState({ unidade: event.target.value, setores: [] })
        this.getSetor(event.target.value)
    };

    handleChangePrestador = (event) => {
        this.setState({ prestador: event.target.value.toUpperCase() })
    };
    handleCdAtendimento = (event) => {
        this.setState({ cd_atendimento: event.target.value })
    };
    handleChangePaciente = (event) => {
        this.setState({ paciente: event.target.value })
    };
    handleChangeSetor = (event) => {
        console.log(event.target.value)
        this.setState({ setor: event.target.value })
    };
    getUnidade = () => {
        api.post('/unidade', {
            cd_empresa: '10'
        }).then(response => {
            console.log(response.data)
            this.setState({ unidades: response.data })
        }).catch(error => {
            console.log(error)
        })
    }
    getSetor = (empresa) => {
        api.post('/searchSetor', {
            cd_empresa: empresa,
            user: localStorage.getItem('login')
        }).then(response => {
            const todos = [{ value: response.data.map(s => s.value).join(), label: "TODOS OS SETORES" }]
            this.setState({ setores: todos.concat(response.data), setor: response.data.map(s => s.value).join() })
        }).catch(error => {
            console.log(error)
        })
    }
    getPrestador = () => {
        api.post('/selectPrestador'
        ).then(response => {
            this.setState({ selectPrestadors: response.data })
        }).catch(error => console.log(error))
    }
    /*-----------------------------------------------------FUNÇÕES DA TABLE----------------------------------------------------------------------- */
    printOrientacao = async () => {
        const hashids = new Hashids();
        var pacVal = this.state.exames_selected.map(a => a.nm_paciente)
        var domain = localStorage.getItem('domain');
        if (this.state.exames_selected.filter(p => p.nm_paciente === pacVal[0]).length === this.state.exames_selected.length) {
            this.state.selected.map(s => {
                window.open(`http://api.chronos.vallions.com.br/print/orientacao/${domain}/${hashids.encode(s)}`, '_blank');
            })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível imprimir orientações de pacientes diferentes.' });
        }
    }
    print = () => {
        if (this.props.options.callcenter) {
            this.printOrientacao()
        } else {
            this.setState({ impressao: true })
        }
    }
    handleChecked = (newSelecteds, pac) => {
        this.getObsTelefone(newSelecteds)
        this.setState({ selected: newSelecteds, exames_selected: pac })
    }
    remarcar = async () => {
        const response = await api.post('/checkDesmarcar', {
            it_agc: this.state.selected.join(),
        });
        if (response.data.length === this.state.exames_selected.length) {
            var qtdPaciente = this.state.exames_selected.filter(s => s.nm_paciente == this.state.exames_selected[0].nm_paciente).length
            this.state.exames_selected.length != qtdPaciente ? this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível remarcar multiplos agendamentos de pacientes diferentes!' }) : this.enviar()
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'O paciente já foi atendido!' })
        }
    }
    desmarcar = () => {
        var pacVal = this.state.exames_selected.map(a => a.nm_paciente)
        if (this.state.exames_selected.filter(p => p.nm_paciente === pacVal[0]).length === this.state.exames_selected.length) {
            this.setState({ confirm: true, confirmTitle: "Atenção", confirmBody: "Esta ação realizará o cancelamento do exame, Deseja confirmar?" });
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível desmarcar pacientes diferentes.' });
        }
    }
    checkDesmarcar = async () => {
        //consulta os que já foram atendidos
        const response = await api.post('/checkDesmarcar', {
            it_agc: this.state.selected.join(),
        });
        if (response.data.length === this.state.exames_selected.length) {
            //se for true, vai desmarcar
            this.setState({ statusDesmarca: true })
        }
    }
    enviar = () => {
        if (this.state.exames_selected[0].sn_encaixe == 'N') {
            sessionStorage.setItem('remarca', JSON.stringify(this.state.exames_selected))
            this.setState({ remarca: true });
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção:', alertBody: 'Não é possivel remarcar encaixe' });
        }
    }

    editarPaciente = () => {
        this.setState({ editar: true });
    };
    /*---------------------------------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------------------CHAMADAS DE ALERTA---------------------------------------------------------- */
    handleCloseAlert = () => {
        this.setState({ alert: false });
    };

    handleCloseConfirmF = async () => {
        await this.setState({ confirm: false });
        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Operação cancelada!' })
    }

    handleCloseConfirmT = async () => {
        await this.checkDesmarcar();

        let desmarca = (item, index) => {
            this.myRefConfirm.current.alterLoading(true)
            api.post('/removeAgendamento', {
                it_agc: item,
                user: localStorage.getItem('login')
            }).then(() => {
                let remarca = sessionStorage.getItem('remarca');
                if (remarca !== null) {
                    remove()
                }
                if ((this.state.selected.length - 1) == index) {
                    document.location.reload(true)
                }
            }).catch(error => {
                this.myRefConfirm.current.alterLoading(false)
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        }

        let desmarcarEncaixe = (item, cd_agenda_central, index) => {
            this.myRefConfirm.current.alterLoading(true)
            api.post('/removeEncaixe', {
                cd_it_ag: item,
                user: localStorage.getItem('login'),
                cd_agenda_central: cd_agenda_central
            }).then(() => {
                let remarca = sessionStorage.getItem('remarca');
                if (remarca !== null) {
                    remove()
                }
                if ((this.state.selected.length - 1) == index) {
                    document.location.reload(true)
                }
            }).catch(error => {
                this.myRefConfirm.current.alterLoading(false)
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        }

        let remove = () => {
            this.setState({ statusDesmarca: false })
            this.myRefConfirm.current.alterLoading(false)
            sessionStorage.removeItem('remarca');
        }

        let confirmado = () => {
            this.state.selected.map((s, index) => {
                var exame = this.state.exames_selected.filter(r => r.cd_it_agenda_central == s)
                exame[0].sn_encaixe == 'S' ? desmarcarEncaixe(s, exame[0].cd_agenda_central, index) : desmarca(s, index)
            })
        }

        this.state.statusDesmarca ? confirmado() : this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'O paciente já foi atendido!' })
    }
    getTelaAtendimento = () => {
        console.log(this.state.exames_selected)
        const datas = this.state.exames_selected.map(a => a.hr_agenda);
        const cd_atendimento = this.state.exames_selected.filter(a => a.cd_atendimento != null)
        for (let i = 0; i < datas.length; i++) {
            if (datas[i].slice(6) == format(new Date(), 'dd/MM/yyyy')) {
                if (i == datas.length - 1) {
                    var pacVal = this.state.exames_selected.map(a => a.nm_paciente)
                    if (this.state.exames_selected.filter(p => p.nm_paciente === pacVal[0]).length === this.state.exames_selected.length) {
                        if (this.props.queue != undefined) {
                            if (this.props.queue.units == this.state.exames_selected[0].cd_unidade_atendimento) {
                                this.setState({ atendimento: true })
                                // api.post('/closeLineFlow', {
                                //     idflow: this.state.queue.idFlow,
                                //     user: localStorage.getItem('login')
                                // }).then(response => {
                                //     api.post('/insertRecepcaoFlow', {
                                //         empresa: this.state.exames_selected[0].cd_unidade_atendimento,
                                //         status: '6',
                                //         user: localStorage.getItem('login'),
                                //         queue: this.state.queue.idqueue,
                                //     }).then(response => {
                                //         this.setState({ atendimento: true, flow: response.data.idflow })
                                //     }).catch(error => this.setState({ alert: true, alertTitle: 'Erro', alertBody: `${error}` }))
                                // }).catch(error => {
                                //     this.setState({ alert: true, alertTitle: 'Erro', alertBody: `${error}` });
                                // })
                            } else {
                                this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Unidade da Fila diferente da unidade de atendimento` });
                            }

                        } else {
                            this.setState({ atendimento: true })
                        }
                    }
                    else {
                        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível atender pacientes diferentes.' });
                    }
                }
            } else {
                if (cd_atendimento.length === this.state.exames_selected.length) {
                    if (this.props.queue != undefined) {
                        if (this.props.queue.units == this.state.exames_selected[0].cd_unidade_atendimento) {
                            this.setState({ atendimento: true })
                            // api.post('/closeLineFlow', {
                            //     idflow: this.state.queue.idFlow,
                            //     user: localStorage.getItem('login')
                            // }).then(response => {
                            //     api.post('/insertRecepcaoFlow', {
                            //         empresa: this.state.exames_selected[0].cd_unidade_atendimento,
                            //         status: '6',
                            //         user: localStorage.getItem('login'),
                            //         queue: this.state.queue.idqueue,
                            //     }).then(response => {
                            //         this.setState({ atendimento: true, flow: response.data[0].idflow })
                            //     }).catch(error => console.log(error))
                            // }).catch(error => {
                            //     console.log(error)
                            // })
                        } else {
                            this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Unidade da Fila diferente da unidade de atendimento` });
                        }
                    } else {
                        this.setState({ atendimento: true })
                    }
                } else {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: "Exame que deseja atender não corresponde a data de hoje" });
                }
                return;
            }
        }

    }
    closeTelaAtendimento = () => {
        this.setState({ atendimento: false, ds_obs: false, ds_tel: false })
    }
    closeTelaAtendimentoQueue = () => {
        this.setState({ atendimento: false, ds_obs: false, ds_tel: false })
        this.props.close();
    }

    /*------------------------------------------------------------------------------------------------------------------------------------------ */
    /*-------------------------------------------------------------------EXAMES----------------------------------------------------------------- */

    searchAgendamento = () => {
        api.post('/agendamentosSearchRecepcao', {
            dataInicio: this.state.dtInicio,
            dataFim: this.state.dtFim,
            cd_empresa: this.state.unidade,
            cd_setor: this.state.setor,
            atendimento: this.state.cd_atendimento,
            prestador: this.state.prestador,
            confirmado: this.state.confirmado,
            status: this.state.status,
        }).then((response) => {
            this.setState({ agendamentos: response.data, success: true, loading: false })
            this.myRef.current.searchData(response.data)
            setTimeout(() => {
                this.setState({ sucess: false })
            }, 2000);
        }).catch(error => {
            this.setState({ loading: false })
        })
    }
    searchExameDisponivel = () => {
        if (this.state.unidade != null && this.state.setor != null) {
            if (!this.state.loading) {
                this.setState({ success: false, loading: true })
                this.searchAgendamento();

            }
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencher a Unidade e o Setor, Para consultar os agendamentos!' })
        }
    }
    /*------------------------------------------------------------------------------------------------------------------------------------------ */
    /*----------------------------------------------------------------- ENCAIXE----------------------------------------------------------------- */

    handleCloseEncaixe = () => {
        this.setState({ encaixe: false });
    };

    validaEncaixe = exame => {
        if (this.state.setores.filter(s => s.value == this.state.setor).map(s => s.label).join() == "TODOS OS SETORES" || this.state.unidades.filter(u => u.value == this.state.unidade).map(s => s.label).join() === "TODAS UNIDADES") {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Selecione somente uma unidade e setor' });
        } else {
            this.setState({ encaixe: true })
        }
    }
    onClickReload = () => {
        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Paciente inserido com sucesso.' });
        this.myRef.current.setExamesStart()
        this.handleCloseEncaixe()
    }
    /*------------------------------------------------------------------------------------------------------------------------------------------ */
    /*----------------------------------------------------------------- OBS E TELEFONE----------------------------------------------------------- */

    fecharObs = () => {
        this.setState({ ds_obs: false, ds_tel: false, selected: [], exames_selected: [] })
    }
    getObsTelefone = it => {
        if (it != '' && it.length == 1) {
            api.post('/selectObs', {
                cd_it_agenda: it.join()
            }).then(response => {
                api.post('/selectTelefone', {
                    cd_it_agenda: it.join()
                }).then(responseTelefone => {
                    response.data[0].ds_observacao_geral != null ? this.setState({ obs: response.data[0].ds_observacao_geral, ds_obs: true }) : this.setState({ obs: null, ds_obs: true })
                    responseTelefone.data[0].fone != null ? this.setState({ telefone: responseTelefone.data[0].fone, ds_tel: true }) : this.setState({ telefone: null, ds_tel: true })
                })
            })
        }
        if (it.length > 1 || it.length == 0) {
            this.setState({ ds_obs: false, ds_tel: false })
        }
    }
    handleChange = name => (event, { newValue }) => {
        this.setState({ [name]: newValue.toUpperCase() });
        if (name == 'valueNome' && newValue == '') this.setState({ valueNome: newValue, telefone: '', dtNasc: new Date(), valueCpf: '' })
    };
    /*------------------------------------------------------------------------------------------------------------------------------------------ */
    setOrientação = () => {
        api.post('/updateObservacao', {
            cd: this.state.selected.join(),
            obs: this.state.obs
        }).then(response => {
            this.setState({ alert: true, alertTitle: 'Concluído', alertBody: 'Alteração concluída com sucesso !' });
        }).catch(error => {
            this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
        });
    }
    /*----------------------------------------------------------------- OBS E TELEFONE----------------------------------------------------------- */

    getAnexo = (item) => {
        this.setState({ dialogAnexo: true, item_anexo: [item] })
    }
    /*------------------------------------------------------------------------------------------------------------------------------------------ */

    render() {

        const { state, open, setOpen, unidades } = this.state;
        const { classes } = this.props
        return (
            <div>
                {this.state.confirmaContato && <DialogTableConfirma close={e => this.setState({ confirmaContato: false })} it_agenda={this.state.selected.join()} />}
                {this.state.impressao && <DialogImpressao close={e => this.setState({ impressao: false })} exames_selected={this.state.exames_selected} />}
                {this.state.confirm && <Confirm ref={this.myRefConfirm} title={this.state.confirmTitle} body={this.state.confirmBody} handleCloseConfirmF={this.handleCloseConfirmF} handleCloseConfirmT={this.handleCloseConfirmT} />}
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                {this.state.encaixe && <Encaixe handleClose={this.handleCloseEncaixe} unidade={this.state.unidades.filter(u => u.value == this.state.unidade)[0]} setor={this.state.setores.filter(s => s.value == this.state.setor)[0]} dataInput={this.state.dtInicio} data={this.state.data} reload={this.onClickReload} />}
                {this.state.dialogAnexo && <DialogAnexo exames={this.state.exames} handleClose={() => this.setState({ dialogAnexo: false })} handleSave={this.handleSaveAnexo} exames={this.state.item_anexo} />}
                {this.state.remarca ? <Remarcar nome={this.state.exames_selected[0].nm_paciente} unidade={this.state.exames_selected[0].cd_multi_empresa} convenio={this.state.exames_selected[0].cd_convenio} dtNasc={this.state.exames_selected[0].dt_nascimento} plano={this.state.exames_selected[0].cd_con_pla} exame={this.state.exames_selected.map(s => s.cd_item_agendamento)} fone={this.state.exames_selected[0].fone} cd_paciente={this.state.exames_selected[0].cd_paciente} cpf={this.state.exames_selected[0].nr_cpf} /> :
                    <div>
                        {
                            this.state.atendimento ? <Atendimento examesSelecinados={this.state.exames_selected} close={this.closeTelaAtendimento} flow={this.state.flow} closeQueue={this.closeTelaAtendimentoQueue} queue={this.state.queue} unidade_atendimento={this.state.exames_selected[0].cd_unidade_atendimento} /> :
                                <Paper style={{ width: '100%', padding: '10px' }}>
                                    {this.state.queue != undefined && <div className="buttonTimer">
                                        <IconButton variant="contained" color="primary" onClick={this.props.close}>
                                            <Arrow />
                                        </IconButton>
                                    </div>}
                                    {this.state.queue != undefined && <div >
                                        <b>PACIENTE: </b>{this.props.queue.name}
                                    </div>}
                                    <div style={{ margin: '10px' }}>
                                        <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', borderRadius: '5px', textAlign: 'center', backgroundColor: '#023575', color: '#fff' }} variant="subtitle1" gutterBottom>Agenda Paciente</Typography>
                                        <div style={{ alignItems: 'center', textAlign: 'center', margin: '10px', padding: '0px', justifyContent: 'center', display: 'flex', flexWrap: 'wrap' }}>
                                            <DataRanger style={{ margin: '8px' }} onClick={this.onClickData} />
                                            <FormControl style={{ width: '200px', margin: '5px' }} >
                                                <InputLabel id="demo-simple-select-label">Unidade</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.unidade}
                                                    onChange={this.handleChangeUnidade}
                                                >

                                                    {this.state.unidades.map(unidade => {
                                                        return <MenuItem value={unidade.value}>{unidade.label}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                            <FormControl style={{ width: '200px', margin: '5px' }} >
                                                <InputLabel id="demo-simple-select-label">Setor</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.setor}
                                                    onChange={this.handleChangeSetor}
                                                >
                                                    {this.state.setores.map(setor => {
                                                        return <MenuItem value={setor.value}>{setor.label}</MenuItem>
                                                    })}

                                                </Select>
                                            </FormControl>
                                            <TextField error={false} helperText="" style={{ width: '250px', margin: '5px' }} label="Prestador" variant="standard" size="small" onChange={e => this.handleChangePrestador(e)} value={this.state.prestador} />
                                            {/* <TextField error={false} helperText="" style={{ width: '250px', margin: '5px' }} label="Paciente" variant="standard" size="small" onChange={e => this.handleChangePaciente(e)} value={this.state.paciente} /> */}
                                            <FormControl style={{ width: '200px', margin: '5px' }} >
                                                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.status}
                                                    onChange={(e) => this.handleChangeCheck(e)}
                                                >
                                                    <MenuItem value={1}>Confirmado</MenuItem>
                                                    <MenuItem value={2}>Contato sem sucesso</MenuItem>
                                                    <MenuItem value={3}>Outros</MenuItem>
                                                    <MenuItem value={4}>Atendido</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div className={classes.root}>
                                                <div className={classes.wrapper}>
                                                    <Fab
                                                        aria-label="search"
                                                        color="primary"
                                                        // className={buttonClassname}
                                                        size="small"
                                                        onClick={this.searchExameDisponivel}
                                                    >
                                                        {this.state.success ? <CheckIcon /> : <SearchIcon />}
                                                    </Fab>
                                                    {this.state.loading && <CircularProgress size={48} className={classes.fabProgress} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ border: '1px solid #ddd', borderRadius: '5px', boxShadow: '1px 2px 1px #ddd' }}>
                                            <Table ref={this.myRef} callcenter={this.state.callcenter} checked={this.handleChecked} imprimir={this.print} desmarcar={this.desmarcar} remarcar={this.remarcar} atender={this.getTelaAtendimento} anexo={this.getAnexo} />
                                        </div>
                                        <div className="observacao">
                                            <div className="remarcar-buttons" >
                                                {this.state.ds_obs && <div className="remarcar-buttons">
                                                    <textarea placeholder="Observação" className="observacoes" cols="40" onChange={e => this.setState({ obs: e.target.value })} rows="5" >{this.state.obs}</textarea>
                                                    <div className="button-obs">
                                                        <IconButton aria-label="delete" color="primary" size="small" onClick={e => this.setOrientação()}>
                                                            <SaveIcon fontSize="inherit" />
                                                        </IconButton>
                                                    </div>
                                                </div>}
                                                {this.state.ds_tel && <div className="button-tell">
                                                    <FormControl>
                                                        <OutlinedInput
                                                            placeholder="Telefone"
                                                            id="standard-adornment-password"
                                                            value={this.state.telefone}
                                                            onChange={this.handleChange("telefone")}
                                                            endAdornment={
                                                                < InputAdornment position="end" >
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={e => this.setState({ confirmaContato: true })}
                                                                    >
                                                                        <CheckIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl >
                                                </div>}
                                            </div>
                                            <div>
                                                <Button variant="contained" size="small" style={{ backgroundColor: '#ff5656', color: '#fff', padding: '10px', margin: '10px', borderRadius: '5px' }} onClick={() => this.validaEncaixe()} >ENCAIXE</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                        }
                    </div>
                }
            </div>
        );
    }

};
const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '-5px',
        margin: '10px',
        justifyContent: 'center'
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
})
export default withStyles(styles)(Recepcao);