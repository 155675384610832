import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PrimeiroLogin from './PrimeiroLogin';
import Atualizacoes from './Atualizacoes';
export default function Dashboard() {
    const [open, setOpen] = React.useState(true);
    return (

        <div>
            <Card>
                <CardHeader title="Bem vindo ao Chronos" />
                <CardContent>
                </CardContent>
            </Card>

            {localStorage.getItem('s_p') === '1' ? <PrimeiroLogin /> : <div />}
            {localStorage.getItem('s_a') === '1' ? <Atualizacoes /> : <div />}
        </div>
    )
}
