import MaterialTable from 'material-table';
import React from 'react';
import api from '../../services/api';
import Alert from '../Alertas/Alert';
import { MuiThemeProvider, createMuiTheme, Tooltip } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import ClearIcon from '@material-ui/icons/Clear';this.props.imprimir()
// import EditIcon from '@material-ui/icons/Edit';
// import PrintIcon from '@material-ui/icons/Print';
// import CheckIcon from '@material-ui/icons/Check';
// import ImportExportIcon from '@material-ui/icons/ImportExport';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AssignmentIcon from '@material-ui/icons/Assignment';

function createData(cd_it_agenda_central, cd_atendimento, hr_agenda, nm_paciente, ds_item_agendamento, nm_convenio, ds_con_pla, cd_usuario, cd_convenio, cd_con_pla, cd_item_agendamento, cd_multi_empresa, fone, sn_atendido, sn_encaixe, cd_paciente, ds_assunto, cd_agenda_central, dt_nascimento, cd_unidade_atendimento, valor, tp_convenio, ds_multi_empresa, nr_cpf, tp_sexo) {
    return { cd_it_agenda_central, cd_atendimento, hr_agenda, nm_paciente, ds_item_agendamento, nm_convenio, ds_con_pla, cd_usuario, cd_convenio, cd_con_pla, cd_item_agendamento, cd_multi_empresa, fone, sn_atendido, sn_encaixe, cd_paciente, ds_assunto, cd_agenda_central, dt_nascimento, cd_unidade_atendimento, valor, tp_convenio, ds_multi_empresa, nr_cpf, tp_sexo };
}

export default class Editable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            terminou: false,
            columns: [
                { title: 'Hora', field: 'hr_agenda', editable: 'never', type: 'datetime', filtering: false, },
                { title: 'Atendimento', field: 'cd_atendimento', hidden: this.props.callcenter, editable: 'never', filtering: false },
                { title: 'ID Pac.', field: 'cd_paciente', editable: 'never', filtering: false },
                { title: 'Nome Paciente', field: 'nm_paciente', filtering: false },
                { title: 'Exame', field: 'ds_item_agendamento', editable: 'never', filtering: false },
                { title: 'Convênio', field: 'nm_convenio', editable: 'never', filtering: false },
                { title: 'Plano', field: 'ds_con_pla', editable: 'never', filtering: false },
                { title: 'Unidade', field: 'ds_multi_empresa', editable: 'never', filtering: false },
                { title: 'Usuário', field: 'cd_usuario', editable: 'never', filtering: false },
                { title: 'cd_convenio', field: 'cd_convenio', hidden: true, filtering: false },
                { title: 'cd_con_pla', field: 'cd_con_pla', hidden: true, filtering: false },
                { title: 'cd_item_agendamento', field: 'cd_item_agendamento', hidden: true, filtering: false },
                { title: 'cd_multi_empresa', field: 'cd_multi_empresa', hidden: true, filtering: false },
                { title: 'cd_agenda_central', field: 'cd_agenda_central', hidden: true, filtering: false },
                { title: 'tp_sexo', field: 'tp_sexo', hidden: true, filtering: false },
                { title: 'ds_assunto', field: 'ds_assunto', lookup: { 'CHRONOS_CC': 'CONFIRMADO', 'CHRONOS_N_C': 'CONTATO SEM SUCESSO' }, hidden: true },
                { title: 'cd_unidade_atendimento', field: 'cd_unidade_atendimento', hidden: true },
                {
                    title: 'Anexo', field: 'study_iuid', disableClick: true, render: rowData => {
                        return (<Tooltip title="Anexo" aria-label="anexo">
                            <IconButton aria-label="anexo" size={'small'} onClick={() => { this.props.anexo(rowData) }}>
                                <AssignmentIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>)
                    },
                    // sorting: false,
                    // headerStyle: {
                    //     minWidth: 55,
                    //     width: 55,
                    //     maxWidth: 55,
                    //     textAlign: 'center'
                    // },
                    // cellStyle: {
                    //     minWidth: 55,
                    //     width: 55,
                    //     maxWidth: 55,
                    //     textAlign: 'center'
                    // },
                    // width: 55,
                },
            ],
            data: [],
            alert: false,
            alertTitle: null,
            alertBody: null,
            rows: []
        }
    }
    componentDidMount() {
        this.setExamesStart()
    }
    setExamesStart = async () => {
        let unidade = JSON.parse(localStorage.getItem('unidades'));
        if (unidade !== null && Object.keys(unidade).length > 0) {
            let unidades = [];
            unidade.forEach(element => {
                unidades.push(element.value)
            });
            let dthoje = new Date().getFullYear() + "-" + ((new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + "-" + (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate());
            unidades = unidades.toString();
            unidade.push({ value: unidades, label: "Todas as Unidades" })
            api.post('/agendamentosTodos', {
                data: dthoje,
                cd_empresa: unidade[unidade.length - 1].value,
            }).then(response => {
                const res = response.data.map(res => { return createData(res.cd_it_agenda_central, res.cd_atendimento, res.hr_agenda, res.nm_paciente, res.ds_item_agendamento, res.nm_convenio, res.ds_con_pla, res.cd_usuario, res.cd_convenio, res.cd_con_pla, res.cd_item_agendamento, res.cd_multi_empresa, res.fone, res.sn_atendido, res.sn_encaixe, res.cd_paciente, res.ds_assunto, res.cd_agenda_central, res.dt_nascimento, res.cd_unidade_atendimento, res.valor, res.tp_convenio, res.ds_multi_empresa, res.nr_cpf, res.tp_sexo) })
                this.setState({ data: res, loading: false })
                this.infoAtendimento();
            }
            ).catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}`, loading: false });
            });
        }

    }

    searchData = async res => {
        const resposta = await res.map(res => {
            return createData(res.cd_it_agenda_central, res.cd_atendimento, res.hr_agenda, res.nm_paciente, res.ds_item_agendamento, res.nm_convenio, res.ds_con_pla, res.cd_usuario, res.cd_convenio, res.cd_con_pla, res.cd_item_agendamento, res.cd_multi_empresa, res.fone, res.sn_atendido, res.sn_encaixe, res.cd_paciente, res.ds_assunto, res.cd_agenda_central, res.dt_nascimento, res.cd_unidade_atendimento, res.valor, res.tp_convenio, res.ds_multi_empresa, res.nr_cpf, res.tp_sexo)
        })
        await this.setState({
            data: resposta,
        })
        this.infoAtendimento();
    }

    handleCloseAlert = () => {
        this.setState({ alert: false });
    };
    infoAtendimento = () => {
        if (this.state.data.filter(e => e.cd_atendimento != null).map(e => e.cd_atendimento).join() != "") {
            api.post('informacoesAtendimento', {
                atendimento: this.state.data.filter(e => e.cd_atendimento != null).map(e => e.cd_atendimento).join()
            }).then(async response => {
                // const newInfo = this.state.rows.filter(r => r.cd_atendimento != _cd_atendimento)
                this.setState({ rows: response.data })
            }).catch(error => {
                console.log(error)
            })
        }

    }
    render() {
        const { loading } = this.state;
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <MuiThemeProvider theme={createMuiTheme({
                    palette: {
                        primary: {
                            main: '#ff5656'
                        },

                        secondary: {
                            main: '#ff5656'
                        }

                    },
                })}>
                    <MaterialTable
                        isLoading={loading}
                        title="Exames"
                        columns={this.state.columns}
                        data={this.state.data}
                        style={
                            { fontSize: '0.675rem !important' }
                        }
                        options={{
                            // filtering: true,
                            draggable: false,
                            selection: true,
                            selectionProps: rowData => ({
                                sn_encaixe: rowData.sn_encaixe === 'S',
                                color: 'primary',
                            }),
                            cellStyle: {
                                maxWidth: '7em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '0.675rem'
                            },
                            headerStyle: {
                                zIndex: '0',
                            },
                            rowStyle: rowData => {
                                var style = {
                                }
                                if (rowData.sn_atendido === 'S') {
                                    style = {
                                        borderLeftColor: '#1E90FF',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                } else {
                                    if (rowData.ds_assunto === 'CHRONOS_CC') {
                                        style = {
                                            borderLeftColor: '#9ACD32',
                                            borderLeftWidth: 12,
                                            borderLeftStyle: 'solid'
                                        }
                                    } else if (rowData.ds_assunto === 'CHRONOS_N_C') {
                                        style = {
                                            borderLeftColor: '#191970',
                                            borderLeftWidth: 12,
                                            borderLeftStyle: 'solid'
                                        }
                                    } else if (rowData.ds_assunto !== '' && rowData.ds_assunto != null) {
                                        style = {
                                            borderLeftColor: '#BA55D3',
                                            borderLeftWidth: 12,
                                            borderLeftStyle: 'solid'
                                        }
                                    } else {
                                        style = {
                                            borderLeftColor: '#fff',
                                            borderLeftWidth: 12,
                                            borderLeftStyle: 'solid'
                                        }
                                    }
                                }
                                return style
                            }
                        }}
                        actions={this.props.callcenter ? [
                            {
                                tooltip: 'Desmarcar',
                                icon: 'delete',
                                onClick: (evt, data) => this.props.desmarcar()
                            },
                            {
                                tooltip: 'Remarcar',
                                icon: 'swap_vert',
                                onClick: (evt, data) => this.props.remarcar()
                            },
                            {
                                tooltip: 'Imprimir',
                                icon: 'print',
                                onClick: (evt, data) => this.props.imprimir()
                            }] : [
                            {
                                tooltip: 'Atender',
                                icon: RecordVoiceOverIcon,
                                onClick: (evt, data) => this.props.atender(),
                                color: "primary"
                            },
                            {
                                tooltip: 'Desmarcar',
                                icon: 'delete',
                                onClick: (evt, data) => this.props.desmarcar()
                            },
                            {
                                tooltip: 'Remarcar',
                                icon: 'swap_vert',
                                onClick: (evt, data) => this.props.remarcar()
                            },
                            {
                                tooltip: 'Imprimir',
                                icon: 'print',
                                onClick: (evt, data) => this.props.imprimir()
                            },
                        ]}
                        onSelectionChange={(rows) => {
                            this.props.checked(rows.map(s => s.cd_it_agenda_central), rows)
                        }
                        }
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        {
                                            if (newData.nm_paciente.length > 0) {
                                                api.post('/alterarPaciente', {
                                                    nome: newData.nm_paciente.toUpperCase(),
                                                    cd: newData.cd_it_agenda_central,
                                                })
                                                    .then(async (response) => {

                                                        newData.nm_paciente = newData.nm_paciente.toUpperCase()
                                                        const data = this.state.data;
                                                        const index = data.indexOf(oldData);
                                                        data[index] = newData;
                                                        await this.setState({ data: [] })

                                                        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Paciente alterado com sucesso.' });
                                                        this.setState({ data: data, terminou: true }, () => resolve());
                                                    });
                                            } else {
                                                const dataE = this.state.data;
                                                this.setState({ data: dataE, alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o nome do paciente.' });
                                            }
                                        }
                                        resolve()
                                    }, this.state.terminou)
                                }),
                        }}

                        detailPanel={rowData => {
                            // this.infoAtendimento(rowData.cd_atendimento)
                            return (
                                <Paper margin={1} style={{ boxShadow: '1px 3px 1px #ddd', padding: '10px', margin: '10px' }}>
                                    <Typography variant="h6" gutterBottom component="div" style={{ fontSize: '0.8rem', color: '#fff', backgroundColor: '#023575', textAlign: 'center', borderRadius: '5px', padding: '10px' }}>
                                        INFORMAÇÕES DO ATENDIMENTO
                                    </Typography>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">CD PEDIDO</TableCell>
                                                <TableCell align="center">EXAME</TableCell>
                                                <TableCell align="center">N. ACESSO</TableCell>
                                                <TableCell align="center">UNIDADE</TableCell>
                                                <TableCell align="center">PRESTADOR</TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody >
                                            {this.state.rows.filter(r => r.cd_atendimento == rowData.cd_atendimento).map((Row) => (
                                                <TableRow key={Row.pedido}>
                                                    <TableCell align="center" component="th" scope="row">
                                                        {Row.cd_ped_rx}
                                                    </TableCell>
                                                    <TableCell align="center">{Row.ds_exa_rx}</TableCell>
                                                    <TableCell align="center">{Row.cd_itped_rx}</TableCell>
                                                    <TableCell align="center">{Row.ds_multi_empresa}</TableCell>
                                                    <TableCell align="center">{Row.nm_prestador}</TableCell>
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </Paper>
                            )
                        }
                        }
                    />
                </MuiThemeProvider>
            </div>
        )
    }
}

