import React from 'react';
import { Paper, withStyles, Typography, IconButton, Box } from '@material-ui/core';
import api from '../../services/api'
import SocketContext from '../../services/SocketProvider';
import song from "./audio/alerta.mp3";

const styles = theme => ({
    paper: {
        width: "100%",
        padding: 20,
        backgroundColor: '#023575'
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 275,
    },
    formControlCheck: {
        marginLeft: theme.spacing(1),
        minWidth: 175,
        fontSize: '0.5em'
    },
    titlePage: {
        fontSize: '12px',
        fontWeight: 'bold',
    },
    titleSenha: {
        color: "#42DDF5",
        fontWeight: 'bold',
        fontSize: '180px',
    },
    titleSenhaChamada: {
        color: "#42DDF5",
        fontWeight: 'bold',
        margin: '15px',
        fontSize: '60px',

    },
    colorContent: {
        color: "white",
        fontSize: '36px',
    },
    titleGuiche: {
        color: "white",
        fontWeight: 'bold'
    }
})


class Fila extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            recepcao: false,
            queue: null,
            openModalImagel: false,
            finalizado: false,
            senhas_anteriores: [],
            senha_atual: {},
            audio: new Audio(song),
            isPlaying: false,
        }
        let socket = props.socket;
    }

    componentDidMount() {
        this.props.socket.on(`senha_guiche_painel`, (data) => {
            console.log(data)
            this.state.audio.play();
            setTimeout(() => {
                this.state.audio.pause();
            }, 3000);
            this.setSenhaGuicheData(data);
        })
        this.setState({ unidade: this.props.unidade })
    }
    setSenhaGuicheData = (data) => {
        var senhas_anteiores = this.state.senhas_anteriores
        console.log(senhas_anteiores)
        this.state.senha_atual != {} && senhas_anteiores.push(this.state.senha_atual)
        this.setState({ senhas_anteriores: senhas_anteiores, senha_atual: data })
    }
    atualizaQueue = () => {
        if (this.state.unidade != null) {
            this.getQueue(this.state.unidade);
        }
    }
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        this.getQueue(value);
        sessionStorage.setItem('unidade_queue', value);
    };
    getQueue = (unidade) => {
        this.setState({ loading: true })
        api.post('/queueToday', {
            unidade: unidade,
            finalizado: this.state.finalizado
        }).then(async response => {
            this.setState({ data: await response.data, loading: false })
        })
    }
    chamarSenha = data => {
        console.log(data)
        this.setState({ queue: data, recepcao: true })
    }
    closeModel = () => {
        this.setState({ openModalImagel: false })
    }

    handleChangeCheck = async event => {
        // console.log(event.target.checked)
        await this.setState({ [event.target.value]: event.target.checked });
        this.atualizaQueue();
    };
    playPause = () => {

        // Get state of song
        let isPlaying = this.state.isPlaying;

        if (isPlaying) {
            // Pause the song if it is playing
            this.state.audio.pause();
        } else {

            // Play the song if it is paused
            this.state.audio.play();
        }

        // Change the state of song
        this.setState({ isPlaying: !isPlaying });
    };
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper} >
                {/* <button onClick={this.playPause}>
                    Play | Pause
                </button> */}
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"15px"} >
                    <Typography variant='h4' className={classes.colorContent}>
                        CHAMADA DE SENHA PARA ATENDIMENTO
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"45px"} >
                    <Typography variant='h6' className={classes.colorContent}>
                        SENHA
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"15px"} >
                    <Typography variant='h1' className={classes.titleSenha}>
                        {this.state.senha_atual?.senha}
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"45px"} >
                    <Typography variant='h6' className={classes.colorContent}>
                        POR FAVOR DIRIJA-SE AO
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"15px"} >
                    <Typography variant='h4' className={classes.titleGuiche}>
                        {this.state.senha_atual?.guiche}
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"45px"} >
                    <Typography variant='h6' className={classes.colorContent}>
                        ÚLTIMAS CHAMADAS
                    </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} margin={"45px"} >
                    {this.state.senhas_anteriores.slice(-7).map(dados => {
                        return <Typography variant='h5' className={classes.titleSenhaChamada}>
                            {dados.senha}
                        </Typography>
                    })}
                </Box>
            </Paper >
        )
    }
}
const FilaWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <Fila {...props} socket={socket} />}
    </SocketContext.Consumer>
)



export default withStyles(styles)(FilaWithSocket)