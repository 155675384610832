import { Button, CircularProgress, Paper, withStyles, FormControlLabel, Switch, FormLabel, Fab } from "@material-ui/core";
import React from "react";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import api from "../../services/api";
import AlertConcluse from '../Alertas/Concluse';
import MaterialTable from 'material-table';
import DataRanger from '../Recepcao/DataRanger';
import '../Recepcao/Dataranger.css';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import { format } from 'date-fns';

class EmpresaConfirmacao extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            unidades: JSON.parse(localStorage.getItem('unidades')) != null ? JSON.parse(localStorage.getItem('unidades')) : null,
            unidade: null,
            loading: false,
            loadingLogs: false,
            unidade_confirmacao: {},
            checkedConfirm: false,
            logs: [],
            dtInicio: format(new Date(), 'yyyy-MM-dd'),
            dtFim: format(new Date(), 'yyyy-MM-dd'),
        }
        console.log(JSON.parse(localStorage.getItem('unidades')) != null ? JSON.parse(localStorage.getItem('unidades')) : null)
    }
    getLogs = (unidade) => {
        this.setState({ loadingLogs: true })

        api.post('getLogsConfirm', {
            empresa: unidade,
            dtInicio: this.state.dtInicio,
            dtFim: this.state.dtFim,
        }).then(resp => {
            this.setState({ logs: resp.data, loadingLogs: false })
        })
    }
    getUnitConfirm = (unit) => {
        this.setState({ loading: true, checkedConfirm: false })
        api.post('getUnitsConfirm', {
            unidade_atendimento: unit
        }).then(resp => {
            this.setState({ loading: false, unidade_confirmacao: resp.data })
            if (resp.data.status_active != undefined) {
                this.setState({ checkedConfirm: resp.data.status_active == "1" ? true : false })
            }
        })
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        this.getUnitConfirm(value);
        this.getLogs(value);
        sessionStorage.setItem('unidade_queue', value);
    };

    handleChecked = (event) => {
        const name = event.target.name;
        const value = event.target.checked
        this.setState({
            [name]: value,
        });
    }
    isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    handleClick = () => {
        if (this.isEmpty(this.state.unidade_confirmacao)) {
            api.post('unitsConfirm', {
                cd_unidade_atendimento: this.state.unidade,
                status_active: this.state.checkedConfirm ? "1" : "0"
            }).then(resp => {
                this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Unidade configurada com sucesso ' });
            })
        } else {
            api.put(`unitsConfirmAlterStatus/${this.state.unidade_confirmacao.id}`, {
                status_active: this.state.checkedConfirm ? "1" : "0"
            }).then(resp => {
                this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Unidade configurada com sucesso ' });
            })
        }
    }
    onClickData = (inicio, fim, data) => {
        this.setState({ dtInicio: inicio, dtFim: fim });
    }
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper}>
                {this.state.alertConcluse && <AlertConcluse title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={() => this.setState({ alertConcluse: false })} />}

                <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            inputProps={{
                                name: 'unidade',
                            }}
                            value={this.state.unidade}
                            onChange={this.handleChange}
                        >
                            {this.state.unidades.map(u => {
                                return <MenuItem value={u.cd_unidade_atendimento}>{u.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>

                </div>
                <div className={classes.contentProgress}>
                    {this.state.loading && <CircularProgress />}
                </div>
                <div className={classes.contentSlide}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Ativar ou Desativar as notificações para o paciente.</FormLabel>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.checkedConfirm}
                                    onChange={this.handleChecked}
                                    name="checkedConfirm"
                                    color="primary"
                                    disabled={this.state.unidade == null}
                                />
                            }
                            label="Enviar confirmação"
                        />
                    </FormControl>
                </div>
                <div className={classes.action}>
                    <Button
                        variant="contained"
                        style={{ color: '#fff', backgroundColor: '#EF6351' }}
                        color="primary"
                        onClick={this.handleClick}
                    >Salvar</Button>
                </div>
                <div className={classes.root}>
                    <DataRanger style={{ margin: '8px' }} onClick={this.onClickData} />
                    <div className={classes.wrapper}>
                        <Fab
                            aria-label="search"
                            color="primary"
                            // className={buttonClassname}
                            size="small"
                            onClick={() => this.getLogs(this.state.unidade)}
                        >
                            {this.state.success ? <CheckIcon /> : <SearchIcon />}
                        </Fab>
                        {/* {this.state.loadingLogs && <CircularProgress size={48} className={classes.fabProgress} />} */}
                    </div>
                </div>
                <div className={classes.containerTable}>
                    <MaterialTable
                        title="Logs de Envio"
                        isLoading={this.state.loadingLogs}
                        columns={[
                            { title: 'Data', field: 'date_insert' },
                            { title: 'Atendimento', field: 'cd_atendimento' },
                            { title: 'Empresa', field: 'unidade' },
                            {
                                title: 'Resposta',
                                field: 'response',
                                lookup: { 'ERROR': 'Erro', 'SUCCESS': 'Sucesso' },
                            },
                            { title: 'URL', field: 'url' },
                            { title: 'Tel', field: 'phone' },
                        ]}
                        data={this.state.logs}
                    />
                </div>
            </Paper>
        )
    }
}

const styles = (theme) => ({
    paper: {
        width: "100%",
        padding: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    },
    action: {
        margin: '10px',
        display: 'flex',
        // justifyContent: 'end'
    },
    contentSlide: {
        margin: '20px'
    },
    contentProgress: {
        margin: '10px',
        textAlign: 'center'
    },
    containerTable: {
        margin: '20px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
})

export default withStyles(styles)(EmpresaConfirmacao)