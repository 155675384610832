import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import api from '../services/api'
/*Auto COmplete */
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from './Select'
import Select, { components } from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import Fab from "@material-ui/core/Fab";
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import Tooltip from '@atlaskit/tooltip';
import Tooltip2 from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';

import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import Calendar from './Calendar';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

import Alert from './Alertas/Alert';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputAdornment from "@material-ui/core/InputAdornment";

import Cadastro from './CadastroPaciente';
import Arrow from '@material-ui/icons/ArrowBack';

/*----------------------- */
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '-5px',
    justifyContent: 'center',
  },
  containerInputs: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '-5px',
    justifyContent: 'center',
    marginTop: 12,
    // boxShadow: '1px 2px 1px 2px #ddd'
  },
  page: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    display: 'inline-block',
  },
  input: {
    margin: theme.spacing(1),
    width: '250px',
  },
  inputDate: {
    margin: theme.spacing(1),
    width: '250px'
  },
  inputNome: {
    margin: theme.spacing(1),
    width: '250px',
  },
  inputExames: {
    margin: theme.spacing(1),
    width: '400px',
  },
  formControl: {
    margin: '2px',
  },
  formControlInput: {
    marginTop: '10px'
  },
  formInput: {
    marginTop: '12px',
  },
  calendarPaper: {
    margin: 12,
    borderRadius: 5,
    padding: 12,
    width: '100%',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  calendarControl: {
    width: '70%',
    height: '50%',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    alignSelf: 'flex-end',
    // display: 'grid'
  },
  popover: {
    pointerEvents: 'none',
  },
}));
var unidadeSelecionada = ''
var convenioSelecionado = ''
var planoSelecionado = ''
var exameSelecionado = ''
var suggestions = []
var suggestionNum = [
  { celular: '987898965', nm_paciente: 'LEANDRO' },
  { celular: '984494894', nm_paciente: 'RAMON' },
  { celular: '978999521', nm_paciente: 'BRUNA' },
  { celular: '856471234', nm_paciente: 'THALITA' }
]
const selectStyle = {
  option: (provided, state) => ({
    ...provided,
    padding: 15,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '0px',
    outline: '0px',
    borderBottom: '1px solid red',
    borderRadius: 'none',
    boxShadow: 'none',
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    '&:hover': {
      borderColor: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      borderColor: 'none',
      boxShadow: 'none'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  })
};

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function TextCpfMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

// TextMaskCustom.propTypes = {
//   inputRef: PropTypes.func.isRequired,
// };
// TextCpfMaskCustom.propTypes = {
//   inputRef: PropTypes.func.isRequired,
// };
function Inputs(props) {
  sessionStorage.removeItem('remarca');
  const unidades = JSON.parse(localStorage.getItem('unidades'));
  const classes = useStyles();
  var initial = {
    cd_paciente: null,
    valueCpf: '',
    valueName: '',
    booCpfName: false,
    booNome: false,
    valueNumero: '',
    dtNasc: null,
    selectedUnidade: null,
    selectedConvenio: null,
    selectedPlano: null,
    selectedExame: null,
    unidade: null,
    convenio: null,
    plano: null,
    exame: null,
    isDisabledConvenio: true,
    isDisabledPlano: true,
    isDisabledExame: true,
    isLoadingConvenio: false,
    isLoadingPlano: false,
    isLoadingExame: false,
    drawer: false,
    alert: false,
    alertTitle: null,
    alertBody: null,
    booPesquisaTelefone: false,
    loadingRefresh: false,
    cadastroPaciente: false,
    isDisabledUnidade: false
  }
  const [state, setState] = React.useState(initial);

  const ajusteDate = (data) => {
    if (data == null) {
      return data
    }
    var d = new Date(data);
    d.setDate(d.getDate() + 1);

    return d
  }
  /*------------------------------------------------------- AUTOCOMPLETE------------------------------------------ ------*/
  const [stateSuggestions, setSuggestions] = React.useState([]);
  const [stateSuggestionsTel, setSuggestionsTel] = React.useState([]);
  function searchTelefone(telefoneValue) {
    if (telefoneValue.length > 3) {
      api.post('/searchT3', {
        celular: telefoneValue
      })
        .then(function (response) {
          setSuggestionsTel(response.data)

        })
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    }

  }
  function searchPacient(nameValue) {
    if (nameValue.length > 3) {
      api.post('/searchNome', {
        nome: nameValue
      })
        .then(function (response) {
          setSuggestions(response.data)
        })
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    }

  }
  function searchDataNasc(nameValue, dtNasc) {
    api.post('/searchT4', {
      nome: nameValue,
      dt_nasc: dtNasc
    })
      .then(function (response) {
        suggestions = response.data
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }
  function renderInputComponent(inputProps) {
    const { classesAuto, inputRef = () => { }, ref, ...other } = inputProps;

    return (
      <FormControl className={classesAuto.input}>
        <InputLabel htmlFor="standard-adornment-password">
          Nome
        </InputLabel>
        <Input
          id="standard-adornment-password"
          value={state.valueName}
          InputProps={{
            inputRef: node => {
              ref(node);
              inputRef(node);
            },
          }}
          {...other}
          // onChange={handleChange("valueName")}
          endAdornment={
            < InputAdornment position="end" >
              <IconButton
                disabled={state.drawer}
                aria-label="toggle password visibility"
                onClick={e => setState(prevState => { return { ...prevState, cadastroPaciente: true } })}
              >
                <PersonAddIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl >
    );
  }
  function renderInputComponentTel(inputProps) {
    const { classesAuto, inputRef = () => { }, ref, ...other } = inputProps;

    return (
      <TextField
        fullWidth
        InputProps={{
          inputRef: node => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classesAuto.input,
          },
          value: state.valueNumero
        }}
        {...other}
      />
    );
  }

  function renderSuggestion(suggestion, { query, isHighlighted }) {
    const mae = suggestion.nm_mae == null ? " NÃO CADASTRADO" : suggestion.nm_mae;
    const celular = suggestion.celular == null ? " NÃO CADASTRADO" : suggestion.celular;
    const dt_nasc = suggestion.dt_nascimento == null ? " NÃO CADASTRADO" : suggestion.dt_nascimento;
    const matches = match(suggestion.nm_paciente, query);
    const parts = parse(suggestion.nm_paciente + "|Dt. Nasc: " + (suggestion.dt_nascimento == null || suggestion.dt_nascimento == undefined ? 'NÃO CADASTRADO' : format(ajusteDate(new Date(dt_nasc)), "dd/MM/yyyy")) + " | CEL:" + celular, matches);
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part => (
            <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  }

  function getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.nm_paciente.slice(0, inputLength).toLowerCase() === inputValue;
        if (keep) {
          count += 1;
        }
        return keep;
      });
  }

  function getSuggestionValue(suggestion) {
    setState(prevState => { return { ...prevState, cd_paciente: suggestion.cd_paciente, valueName: suggestion.nm_paciente, valueCpf: suggestion.nr_cpf != null ? suggestion.nr_cpf : '', dtNasc: ajusteDate(suggestion.dt_nascimento), valueNumero: suggestion.celular != null ? suggestion.celular : '', booNome: true } })
    searchAtendimento(suggestion.cd_paciente)
    return suggestion.nm_paciente;
  }

  function getSuggestionValueTel(suggestion) {
    setState(prevState => { return { ...prevState, cd_paciente: suggestion.cd_paciente, booPesquisaTelefone: true, valueName: suggestion.nm_paciente, valueCpf: suggestion.nr_cpf != null ? suggestion.nr_cpf : '', dtNasc: ajusteDate(suggestion.dt_nascimento), valueNumero: suggestion.celular != null ? suggestion.celular : '', booNome: true } })
    searchAtendimento(suggestion.cd_paciente)
    // setTimeout(function(){ searchAtendimento() }, 3000);
    return suggestion.celular;
  }

  const useStylesAuto = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing.unit,
    },
    container: {
      position: 'relative',
      width: '250px',
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      width: '1000px',
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',

    },
    suggestionsList: {
      margin: 0,
      padding: theme.spacing.unit * 2,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing(2),
    },
  }));
  const classesAuto = useStylesAuto();

  const handleSuggestionsFetchRequested = ({ value }) => {
    searchPacient(value.toUpperCase())
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    setState(prevState => { return { ...prevState, valueName: value.toUpperCase() } })
    if (state.valueName.length > inputValue.length) {
      setState(prevState => { return { ...prevState, valueName: value.toUpperCase(), valueCpf: '', dtNasc: null, valueNumero: '', isDisabledConvenio: true, isDisabledPlano: true, isDisabledExame: true, selectedUnidade: null, selectedPlano: null, selectedConvenio: null, booNome: false } })

    }
  };
  const handleSuggestionsFetchRequestedTel = ({ value }) => {
    searchTelefone(value.toUpperCase())
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    setState(prevState => { return { ...prevState, valueNumero: value.toUpperCase() } })
    if (state.valueNumero.length > inputValue.length) {
      if (state.booPesquisaTelefone == true) setState(prevState => { return { ...prevState, booPesquisaTelefone: false, valueNumero: value.toUpperCase(), valueCpf: '', dtNasc: null, valueName: '', isDisabledConvenio: true, isDisabledPlano: true, isDisabledExame: true, selectedUnidade: null, selectedPlano: null, selectedConvenio: null, booNome: false } })

    }
  };
  const handleChange = name => (event, { newValue }) => {
    setState(prevState => { return { ...prevState, [name]: newValue.toUpperCase() } });

  };
  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const handleSuggestionsClearRequestedTel = () => {
    setSuggestions([]);
  };
  const autosuggestProps = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };
  const autosuggestPropsTel = {
    renderInputComponent: renderInputComponentTel,
    suggestions: stateSuggestionsTel,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequestedTel,
    onSuggestionsClearRequested: handleSuggestionsClearRequestedTel,
    getSuggestionValue: getSuggestionValueTel,
    renderSuggestion,
  };
  /*PREENCHIMENTO PELO PACIENTE */
  const searchAtendimento = (cd_paciente) => {
    api.post('/ultimoAgendamento', {
      cd: cd_paciente
    })
      .then(function (response) {
        if (response.data.length > 0) {
          setState(prevState => { return { ...prevState, isDisabledUnidade: true } })
          carregaUltimoAtendimento(response.data)
        }
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }
  const carregaUltimoAtendimento = async (dados) => {
    if (state.selectedUnidade === null) {
      api.post('/empresaUltimoAtendi', {
        cd: dados[0].cd_multi_empresa
      })
        .then(function (response) {
          var updatedValues = {
            selectedUnidade: response.data[0], isDisabledUnidade: false, selectedConvenio: null, selectedPlano: null, selectedExame: null, isDisabledConvenio: true, isDisabledPlano: true, isDisabledExame: true, isLoadingConvenio: true
          }

          unidadeSelecionada = response.data[0].value
          setState(prevState => {
            return { ...prevState, ...updatedValues };
          });

          searchConvenio(response.data[0].value)
          carregaUltimoConvenio(dados)
        })
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    } else {
      setState(prevState => { return { ...prevState, isDisabledUnidade: false } })
    }

  }
  const carregaUltimoConvenio = (dados) => {
    api.post('/ultimoConvenio', {
      cd: dados[0].cd_convenio
    })
      .then(function (response) {
        var updatedValues = { selectedConvenio: response.data[0], selectedPlano: null, selectedExame: null, isDisabledPlano: true, isDisabledExame: true, isLoadingPlano: true }
        setState(prevState => {
          // Object.assign também funcionaria
          return { ...prevState, ...updatedValues };
        });
        convenioSelecionado = response.data[0].value;
        searchPlano(response.data[0].value)
        carregaUltimoPlano(dados)
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }
  const carregaUltimoPlano = (dados) => {
    api.post('/ultimoPlanoConvenio', {
      cd_convenio: dados[0].cd_convenio,
      cd: dados[0].cd_con_pla,
      empresa: dados[0].cd_multi_empresa
    })
      .then(function (response) {
        // console.log(response.data[0])
        var updatedValues = { selectedPlano: response.data[0], selectedExame: null, isDisabledExame: true, isLoadingExame: true }
        setState(prevState => {
          // Object.assign também funcionaria
          return { ...prevState, ...updatedValues };
        });
        planoSelecionado = response.data[0].value
        searchExame(dados[0].cd_convenio, dados[0].cd_con_pla, unidadeSelecionada)
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }
  /*---------------------------------------------------------------------------------------------------------------------*/

  function searchCpf(cpfValue) {
    api.post('/searchT1', {
      cpf: cpfValue
    })
      .then(function (response) {
        console.log(response.data[0])
        if (response.data.length > 0 && state.valueName == '') {
          setState(prevState => { return { ...prevState, valueName: response.data[0].nm_paciente, booCpfName: true, dtNasc: ajusteDate(response.data[0].dt_nascimento), valueNumero: response.data[0].celular, cd_paciente: response.data[0].cd_paciente } })
        }
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }
  const handleCpf = name => event => {
    setState({ ...state, [name]: event.target.value });
    if (state.booCpfName == true && event.target.value.length <= 11) {
      setState({ valueName: '', booCpfName: false, dtNasc: null, valueNumero: '' })
    }
    if (event.target.value.length == 11) {
      searchCpf(event.target.value)
    }
  }

  /*--------------------------------------------------------SELECT------------------------------------------------------ */
  const handleChangeUnidade = selectedUnidade => {
    var updatedValues = {
      selectedUnidade, valueName: state.valueName, selectedConvenio: null, selectedPlano: null, selectedExame: null, isDisabledConvenio: true, isDisabledPlano: true, isDisabledExame: true, isLoadingConvenio: true
    }
    setState(prevState => {
      // Object.assign também funcionaria
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
    unidadeSelecionada = selectedUnidade.value;
    searchConvenio(selectedUnidade.value);
  };

  const handleChangeConvenio = selectedConvenio => {
    var updatedValues = { selectedConvenio, valueName: state.valueName, selectedPlano: null, selectedExame: null, isDisabledPlano: true, isDisabledExame: true, isLoadingPlano: true }
    setState(prevState => {
      // Object.assign também funcionaria
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
    convenioSelecionado = selectedConvenio.value;
    searchPlano(selectedConvenio.value);

  };

  const handleChangePlano = selectedPlano => {
    var updatedValues = { selectedPlano, valueName: state.valueName, selectedExame: null, isDisabledExame: true, isLoadingExame: true }
    setState(prevState => {
      // Object.assign também funcionaria
      return { ...prevState, ...updatedValues };
    });
    planoSelecionado = selectedPlano.value
    searchExame(convenioSelecionado, planoSelecionado, unidadeSelecionada)
  };

  const handleChangeExame = (inputValue, action) => {
    //verifica a action
    switch (Object.values(action)[0]) {
      case 'select-option':
        setState(prevState => { return { ...prevState, selectedExame: inputValue } });
        return;
      case 'deselect-option':
        //opção deslect, constroi um indice para adicionar em vez de remover
        var selected = { cd_item: action.option.cd_item, cd_exa_rx: action.option.cd_exa_rx, ds_item_agendamento: action.option.ds_item_agendamento, proibido: action.option.proibido, sn_ativo: action.option.sn_ativo, tp_proibicao: action.option.tp_proibicao, valor: action.option.valor, value: action.option.value + '_' + Date.now(), label: action.option.label }
        setState(prevState => { return { ...prevState, selectedExame: state.selectedExame.concat(selected) } });
        return;
      case 'remove-value':
        if (inputValue != null) {
          //remove o indice selecionado
          var removeIndex = inputValue.map(function (item) { return item.value; }).indexOf(Object.values(action)[1].value);
          inputValue.splice(removeIndex, 0);
          setState(prevState => { return { ...prevState, selectedExame: inputValue } });
        } else {
          setState(prevState => { return { ...prevState, selectedExame: null } });
        }
        return;
      case 'clear':
        //limpar todos
        setState(prevState => { return { ...prevState, selectedExame: null } });
        return;
      default:
        return;
    }
  }

  function searchConvenio(cd_empresa) {
    if (localStorage.getItem('base') == 0) {
      api.post('/convenio', {
        cd_empresa: cd_empresa
      })
        .then((response) =>
          setState(prevState => { return { ...prevState, convenio: response.data, isLoadingConvenio: false, isDisabledConvenio: false } }),
        )
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    } else {
      api.post('/convenioLocal', {
        cd_unidade: cd_empresa,
        user: localStorage.getItem('login')
      })
        .then((response) =>
          setState(prevState => { return { ...prevState, convenio: response.data, isLoadingConvenio: false, isDisabledConvenio: false } }),
        )
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    }

  }

  function searchPlano(cd_convenio) {
    if (localStorage.getItem('base') == 0) {
      api.post('/planosConvenio', {
        cd_convenio: cd_convenio,
        empresa: unidadeSelecionada
      })
        .then((response) =>
          setState(prevState => { return { ...prevState, plano: response.data, isLoadingPlano: false, isDisabledPlano: false } }),
        )
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    } else {
      api.post('/planoLocal', {
        cd_convenio: cd_convenio,
        user: localStorage.getItem('login')
      })
        .then((response) =>
          setState(prevState => { return { ...prevState, plano: response.data, isLoadingPlano: false, isDisabledPlano: false } }),
        )
        .catch(error => {
          setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
        });
    }

  }

  function searchExame(cd_convenio, cd_plano, cd_empresa) {
    api.post('/examesConvenioPlano', {
      cd_convenio: cd_convenio,
      cd_plano: cd_plano,
      cd_empresa: cd_empresa
    })
      .then((response) =>
        setState(prevState => { return { ...prevState, exame: response.data, isLoadingExame: false, isDisabledExame: false } })
      )
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }

  function calendarLoad() {
    setSuccess(true);
    setLoading(false);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  }
  function stopLoadingR() {
    setState(prevState => {
      return { ...prevState, loadingRefresh: false };
    });
  }

  /*---------------------------------------------------------------------------------------------------------------------- */
  const childRef = React.useRef();
  function searchExameDisponivel() {
    if (state.loadingRefresh != true) {
      if (state.selectedExame != null && state.selectedExame != [] && state.selectedExame != '') {
        if (!loading) {
          setSuccess(false);
          setLoading(true);
          childRef.current.searchExameDisponivel(state.selectedUnidade, state.selectedConvenio, state.selectedExame, state.selectedPlano, state.valueName, state.valueNumero, state.cd_paciente, state.dtNasc);
        }
      }
      else {
        if (state.drawer == false) {
          setState(prevState => {
            return { ...prevState, alert: true, alertTitle: 'Atenção', alertBody: 'Selecionar o Exame, para prosseguir o agendamento!' };
          });
        } else {
          childRef.current.confirmacaoProsseguir()
        }

      }
    }

  }

  function searchExameDisponivelR() {
    if (loading == false) {
      if (state.selectedExame != null && state.selectedExame != [] && state.selectedExame != '') {
        childRef.current.searchExameDisponivelRefresh(state.selectedUnidade, state.selectedConvenio, state.selectedExame, state.selectedPlano, state.valueName, state.valueNumero, state.cd_paciente, state.dtNasc);
        childRef.current.iniciarLoad()
        setState(prevState => {
          return { ...prevState, loadingRefresh: true };
        });
      } else {
        setState(prevState => {
          return { ...prevState, alert: true, alertTitle: 'Atenção', alertBody: 'Selecionar o Exame, para prosseguir o agendamento!' };
        });
      }
    }

  }

  function alterDrawer(valor) {
    setState(prevState => {
      return { ...prevState, drawer: valor };
    });
  }

  function handleCloseAlert() {
    setState(prevState => {
      return { ...prevState, alert: false };
    });
  };
  /*---------------------------------------------------------------------------------------------------------------------*/
  const onChangeData = e => {
    var updatedValues = { dtNasc: e }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
  };
  const onChangeTelefone = e => {
    var updatedValues = { valueNumero: e.target.value }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
  };
  const clickButtonLimpar = () => {
    setState(initial)
    childRef.current.limparExames();
  }
  const renderCadastroSucess = (cd_paciente, nm_paciente, nr_cpf, dt_nascimento, celular) => {
    console.log(celular)
    setState(prevState => { return { ...prevState, cd_paciente: cd_paciente, valueName: nm_paciente, valueCpf: nr_cpf != null ? nr_cpf : '', dtNasc: dt_nascimento, valueNumero: celular != null ? celular : '' } })
  }
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  const MultiValueContainer = props => {
    return (
      <Tooltip content={'Customise your multi-value container!'}>
        <components.MultiValueContainer {...props} />
      </Tooltip>
    );
  };

  return (
    <div className={classes.page}>
      {state.cadastroPaciente && <Cadastro handleClose={e => setState(prevState => { return { ...prevState, cadastroPaciente: false } })} setCdPaciente={renderCadastroSucess} />}
      {state.alert && <Alert title={state.alertTitle} body={state.alertBody} handleCloseAlert={handleCloseAlert} />}
      {props.queue != undefined && <div className="buttonTimer">
        <IconButton variant="contained" color="primary" onClick={props.close}>
          <Arrow />
        </IconButton>
      </div>}
      {props.queue != undefined && <div >
        <b>PACIENTE: </b>{props.queue.name}
      </div>}
      <Paper className={classes.container}>
        <Paper className={classes.containerInputs}>
          <div>
            <div className={classes.container}>
              <FormControl className={classes.formControl}>
                <div className={classesAuto.root}>
                  <Autosuggest
                    {...autosuggestProps}
                    inputProps={{
                      disabled: state.drawer,
                      classesAuto,
                      id: 'react-autosuggest-simple',
                      label: 'Nome',
                      placeholder: 'Digite o nome',
                      value: state.valueName,
                      onChange: handleChange('valueName'),
                    }}
                    theme={{
                      container: classesAuto.container,
                      suggestionsContainerOpen: classesAuto.suggestionsContainerOpen,
                      suggestionsList: classesAuto.suggestionsList,
                      suggestion: classesAuto.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                      <Paper {...options.containerProps} square>
                        {options.children}
                      </Paper>
                    )}
                  />
                </div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={state.drawer}
                    disableToolbar
                    className={classes.input}
                    variant="inline"
                    format="dd/MM/yyyy"
                    id="date-picker-inline"
                    label="Data de Nascimento"
                    value={state.dtNasc}
                    onChange={onChangeData}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl className={classes.formControl}>
                <div className={classesAuto.root}>
                  <Autosuggest
                    {...autosuggestPropsTel}
                    inputProps={{
                      disabled: state.drawer,
                      classesAuto,
                      id: 'react-autosuggest-simple-telefone',
                      label: 'Telefone',
                      placeholder: 'Telefone',
                      value: state.valueNumero,
                      onChange: handleChange('valueNumero'),
                    }}
                    theme={{
                      container: classesAuto.container,
                      suggestionsContainerOpen: classesAuto.suggestionsContainerOpen,
                      suggestionsList: classesAuto.suggestionsList,
                      suggestion: classesAuto.suggestion,
                    }}
                    renderSuggestionsContainer={options => (
                      <Paper {...options.containerProps} square>
                        {options.children}
                      </Paper>
                    )}
                  />
                </div>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={state.drawer}
                  label='CPF'
                  id="cpf"
                  placeholder="Digite o CPF"
                  className={classes.input}
                  inputProps={{
                    'aria-label': 'CPF',
                  }}
                  onChange={handleCpf("valueCpf")}
                  value={state.valueCpf}
                />
              </FormControl>
            </div>
            <div className={classes.container}>

              <FormControl className={classes.formControl}>
                <Select
                  className={classes.input}
                  styles={selectStyle}
                  placeholder='Selecione a Unidade *'
                  value={state.selectedUnidade}
                  onChange={handleChangeUnidade}
                  options={unidades}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  isDisabled={state.isDisabledUnidade}
                  getOptionValue={option => option.value}
                  name={'unidade'}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Select
                  className={classes.input}
                  styles={selectStyle}
                  placeholder='Selecione o Convênio *'
                  value={state.selectedConvenio}
                  onChange={handleChangeConvenio}
                  options={state.convenio}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  isDisabled={state.isDisabledConvenio}
                  getOptionValue={option => option.value}
                  isLoading={state.isLoadingConvenio}
                  name={'convenio'}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Select
                  className={classes.input}
                  styles={selectStyle}
                  placeholder='Selecione o Plano *'
                  value={state.selectedPlano}
                  onChange={handleChangePlano}
                  options={state.plano}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  isDisabled={state.isDisabledPlano}
                  getOptionValue={option => option.value}
                  isLoading={state.isLoadingPlano}
                  name={'plano'}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Select
                  className={classes.input}
                  styles={{
                    option: (provided, state) => ({
                      ...provided,
                      padding: 15,
                      opacity: state.isDisabled ? 0.5 : 1,
                      transition: 'opacity 300ms',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '0px',
                      outline: '0px',
                      borderBottom: '1px solid red',
                      borderRadius: 'none',
                      boxShadow: 'none',
                      opacity: state.isDisabled ? 0.5 : 1,
                      transition: 'opacity 300ms',
                      width: '250px',
                      '&:hover': {
                        borderColor: 'none',
                        boxShadow: 'none'
                      },
                      '&:focus': {
                        borderColor: 'none',
                        boxShadow: 'none'
                      }
                    }),
                    multiValue: base => ({
                      ...base,
                      padding: '1px',
                      maxWidth: '212px'
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      padding: 0,
                    })
                  }}
                  placeholder='Selecione o Exame *'
                  value={state.selectedExame}
                  onChange={handleChangeExame}
                  options={state.exame}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  isDisabled={state.isDisabledExame}
                  isMulti
                  closeMenuOnSelect={true}
                  getOptionValue={option => option.value}
                  isLoading={state.isLoadingExame}
                  isOptionDisabled={option => option.proibido == "S" || option.sn_ativo == "N"}
                  name={'exames[]'}
                  closeMenuOnScroll={true}
                  pageSize={2}
                  hideSelectedOptions={false}
                />
              </FormControl>
            </div>
          </div>
          <div >
            <div className={classes.wrapper}>
              <Tooltip2 title="Limpar" placement="right">
                <IconButton aria-label="delete" className={classes.margin} onClick={clickButtonLimpar}>
                  <DeleteForeverIcon color="error" />
                </IconButton>
              </Tooltip2>
            </div>
            <div className={classes.wrapper}>

              {/* <Button variant="contained" size="small" color="primary">
              <Cancel />
            </Button> */}

              <div>
                <Fab
                  aria-label="save"
                  color="primary"
                  className={buttonClassname}
                  onClick={searchExameDisponivel}
                >
                  {success ? <CheckIcon /> : <SearchIcon />}
                </Fab>
                {loading && <CircularProgress size={65} className={classes.fabProgress} />}
              </div>
            </div>
          </div>
        </Paper>

        <Paper className={classes.calendarPaper}>
          <Calendar ref={childRef} clickLoad={calendarLoad} queue={props.queue} closeQueue={props.close} stopLoadingR={stopLoadingR} clickReload={searchExameDisponivelR} alterDrawer={alterDrawer} />
        </Paper>
      </Paper>
    </div>
  );
}
export default Inputs

