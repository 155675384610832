import React from 'react';
import Table, { MTableToolbar } from 'material-table';
import { Paper, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../../../services/api';
import Room from './updateRoom';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Lightbox } from "react-modal-image";
import SocketContext from '../../../services/SocketProvider';
const AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: "AKIA44J23C7BY6ASKLST",
    secretAccessKey: "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"
});
let s3 = new AWS.S3();
const styles = theme => ({
    paper: {
        width: "100%",
        padding: 20
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    },
    formGroup: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    }
})

class Fluxo extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            unidades: JSON.parse(localStorage.getItem('unidades')),
            unidade: sessionStorage.getItem('unidade_queue') != null ? sessionStorage.getItem('unidade_queue') : null,
            rooms: [],
            room: null,
            data: [
                // { checkin: '17:58 15/07/2020', name: 'LEANDRO NEGRAO RIBEIRO DE SOUZA', nameRoom: 'Espera Tomografia', status: 3, },
                // { checkin: '16:20 15/07/2020', name: 'LEANDRO NEGRAO RIBEIRO DE SOUZA', nameRoom: 'Espera Tomografia', status: 0, },
                // { checkin: '16:21 15/07/2020', name: 'LEANDRO NEGRAO RIBEIRO DE SOUZA', nameRoom: 'Espera Tomografia', status: 0, }
            ],
            columns: [
                {
                    title: 'Avatar',
                    field: 'avatar',
                    render: rowData => (
                        <img
                            style={{ height: 36, width: 36, borderRadius: '50%', cursor: 'pointer' }}
                            src={rowData.pathS3}
                            onClick={() => this.setState({ openModalImagel: true, image_modal: rowData.pathS3 })}

                        />
                    ),
                },
                { title: 'Hora Exa.', field: 'hr_agenda' },
                { title: 'Nome', field: 'nm_paciente' },
                { title: 'Exame', field: 'exame' },
                { title: 'Data Entrada', field: 'checkin' },
                { title: 'Sala', field: 'nameRoom' },
                { title: 'Status', field: 'status', lookup: { 0: 'Saiu da Recepção', 1: 'Em Espera', 2: 'Em Exame', 3: 'Aguardando próximos exames', 4: 'Aguardando Entrega', 5: 'Finalizado', 6: 'Em Atendimento', 7: 'Espera Fila' } },
            ],
            disabledRoom: false,
            roomUpdate: false,
            finalizado: false,
            openModalImagel: false
        }
        // setInterval(() => { this.atualizarFlow() }, 10000);
        sessionStorage.getItem('unidade_queue') && this.getRoom(sessionStorage.getItem('unidade_queue'));
    }
    componentDidMount() {
        this.props.socket.on(`atualizaFlowTec`, () => {
            this.atualizarFlow();
        })
    }
    atualizarFlow = () => {
        if (this.state.roomUpdate == false && (this.state.room != null && this.state.room != undefined)) {
            const broken = this.state.room.split('|');
            this.getAgenda(broken[0], broken[1])
        }
    }
    handleChangeUnidade = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        sessionStorage.setItem('unidade_queue', value);
        this.getRoom(value)
    };
    handleChangeRoom = (event) => {
        const name = event.target.name;
        // console.log(name)
        const value = event.target.value;
        this.setState({
            [name]: value,
        });
        const broken = value.split('|');
        this.getAgenda(broken[0], broken[1])
    };
    getImage = async (img) => {
        var params = { Bucket: 'vallions', Key: 'images/private/' + img };
        var url = await s3.getSignedUrl('getObject', params);
        return url;
    }
    getRoom = unidade => {
        api.post('/storeRoomExames', {
            empresa: this.state.unidades.filter(u => u.cd_unidade_atendimento == unidade).map(u => u.value).join()
        }).then(response => {
            this.setState({ rooms: response.data })
        })
    }
    getAgenda = (cd, tp) => {
        // console.log('opa')
        // console.log(this.state.finalizado)
        api.post('/agendaRoomExame', {
            cd: cd,
            tp: tp,
            finalizado: this.state.finalizado
        }).then(async response => {
            await response.data.map(async u => await Object.assign(u, { pathS3: await this.getImage(u.path) }));
            console.log(response.data)
            this.setState({ data: await response.data, loading: false });
        }).catch(error => {
            console.log(error)
        });
    }
    setAtender = (flow) => {
        this.setState({ roomUpdate: true, flow: flow.data })
    }
    closeUpdateRoom = () => {
        this.setState({ roomUpdate: false })
        const broken = this.state.room.split('|');
        this.getAgenda(broken[0], broken[1])
    }
    closeModel = () => {
        this.setState({ openModalImagel: false })
    }
    handleChangeCheck = async event => {
        // console.log(event.target.checked)
        await this.setState({ loading: true, finalizado: event.target.checked });
        this.atualizarFlow();
    };
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper}>
                {this.state.roomUpdate && <Room unidade={this.state.unidade} room={this.state.room.split('|')[0]} roomName={this.state.rooms.filter(r => r.cd_room == this.state.room.split('|')[0]).map(r => r.room).join()} close={this.closeUpdateRoom} flow={this.state.flow} />}
                {
                    this.state.openModalImagel && (
                        <Lightbox
                            medium={this.state.image_modal}
                            large={this.state.image_modal}
                            alt="Foto do Usuario"
                            onClose={this.closeModel}
                        />
                    )
                }
                <Table
                    title="Fluxo Técnico"
                    options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                    }}
                    isLoading={this.state.loading}
                    data={this.state.data}
                    actions={[{}]}
                    columns={this.state.columns}
                    components={{
                        Action: props => (
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ textTransform: 'none' }}
                                size="small"
                                onClick={() => this.setAtender(props)}
                            >
                                ATENDER
                            </Button>
                        ),
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                            name: 'unidade',
                                        }}
                                        value={this.state.unidade}
                                        onChange={this.handleChangeUnidade}
                                    >
                                        {this.state.unidades.map(u => {
                                            return <MenuItem value={u.cd_unidade_atendimento}>{u.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl} disabled={this.state.disabledRoom}>
                                    <InputLabel id="demo-simple-select-label">Salas Exames</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                            name: 'room',
                                        }}
                                        value={this.state.room}
                                        onChange={this.handleChangeRoom}
                                    >
                                        {/* {this.state.rooms.length > 0 ? <MenuItem value={{`|${r.tp_room}`}}>TODAS SALAS</MenuItem> : <MenuItem value={{`${r.cd_room}|${r.tp_room}`}}>SEM SALAS</MenuItem>} */}

                                        {this.state.rooms.map(r => {
                                            return <MenuItem value={`${r.cd_room}|${r.tp_room}`}>{r.room}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    className={classes.formControl}
                                    control={<Checkbox checked={this.state.finalizado} onChange={(e) => this.handleChangeCheck(e)} value="Finalizado" />}
                                    label="Concluido"
                                />
                            </div>
                        ),
                    }}
                />
            </Paper>
        )
    }
}
const FluxoWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <Fluxo {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default withStyles(styles)(FluxoWithSocket)