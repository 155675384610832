import React from 'react';
import MaterialTable from 'material-table';
import api from '../../services/api';
import Alert from '../Alertas/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class TableReset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [
            ],
            alert: false,
            alertTitle: null,
            alertBody: null,
            loading: false,
        }
    }
    componentDidMount() {
        this.getUsuario();
    }
    getUsuario = () => {
        api.post('/usuariosAll', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Authorization",
                "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(response => {
            this.setState({ data: response.data })
            console.log(response.data)
        })
    }
    setResetSenha = id => {
        api.post('/resetSenha', {
            id: id
        })
            .then(response => {
                this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Reset concluído com sucesso.' });
                this.getUsuario();
            })
    }
    carregarNovosUsuarios = () => {
        this.setState({ loading: true });
        api.post('/insertUsersMv', {
            clients_id: 1,
            type_users_id: 1
        })
            .then(response => {
                this.setState({ alert: true, alertTitle: 'Sucesso', alertBody: 'Usuários inseridos com sucesso.' });
                this.getUsuario();
                this.setState({ loading: false });
            })
    }
    sincronizarUsuariosInativos = () => {
        this.setState({ loading: true });
        api.post('/removeUsersInactive', {})
            .then(response => {
                this.setState({ alert: true, alertTitle: 'Sucesso', alertBody: 'Usuários alterados com sucesso.' });
                this.getUsuario();
                this.setState({ loading: false });
            })
    }
    sincronizarUsuariosAtivos = () => {
        this.setState({ loading: true });
        api.post('/updateUsersActive', {})
            .then(response => {
                this.setState({ alert: true, alertTitle: 'Sucesso', alertBody: 'Usuários alterados com sucesso.' });
                this.getUsuario();
                this.setState({ loading: false });
            })
    }
    handleCloseAlert = () => {
        this.setState({ alert: false });
    }
    render() {
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <MaterialTable
                    isLoading={this.state.loading}
                    title="Usuários"
                    columns={[
                        { title: 'Nome', field: 'name', editable: 'never' },
                        { title: 'Login', field: 'login', editable: 'never' },
                        { title: 'Empresa', field: 'name_empresa', editable: 'never' },
                        { title: 'Resetou senha ?', field: 's_p', lookup: { 0: 'Sim', 1: 'Não' }, editable: 'never' },
                        { title: 'Tipo', field: 'type_users_id', lookup: { 1: 'Operacional', 2: 'Paciente', 3: 'Suporte', 4: 'Gestão', 5: 'Atendente', 6: 'Faturamento', 7: 'Comercial' } },
                        { title: 'id', field: 'id', hidden: true },
                    ]}
                    data={this.state.data}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                api.post('/updateTypeUser', {
                                    user: newData.login,
                                    type: newData.type_users_id,
                                }).then(async resp => {
                                    const data = this.state.data;
                                    const index = data.indexOf(oldData);
                                    data[index] = newData;
                                    await this.setState({ data: [] })

                                    this.setState({ data: data, terminou: true }, () => resolve());
                                })
                            }),
                    }}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Resetar Senha',
                            onClick: (event, rowData) => this.setResetSenha(rowData.id)
                        },
                        {
                            icon: 'get_app',
                            tooltip: 'Carregar novos usuário',
                            isFreeAction: true,
                            onClick: (event) => this.carregarNovosUsuarios()
                        },
                        {
                            icon: 'block',
                            tooltip: 'Sincronizar usuários inativos',
                            isFreeAction: true,
                            onClick: () => this.sincronizarUsuariosInativos()
                        },
                        {
                            icon: 'check',
                            tooltip: 'Sincronizar usuários ativos',
                            isFreeAction: true,
                            onClick: () => this.sincronizarUsuariosAtivos()
                        }
                    ]}
                />
            </div>
        )
    }
}