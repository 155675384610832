import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Admin, Resource, ListGuesser } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import Dashboard from './Dashboard';
import authProvider from './authProvider';
import Backup from './components/InputsForm';
import Remarcar from './components/Remarcar/Remarcar';
import Calendar from '@material-ui/icons/CalendarToday';
import MeusAgendamentos from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';
import QueueIcon from '@material-ui/icons/Queue';
import FlowIcon from '@material-ui/icons/FormatListNumbered';
import { createMuiTheme } from '@material-ui/core/styles';
import { Login, Layout } from './components/layout';
import ResetSenha from './components/Suporte/ResetSenha';
import ConfiguracaoUsuarioParceiro from './components/Configuracoes/UsuarioParceiro/Configuracao';
import BuildIcon from '@material-ui/icons/Build';
import Fila from './components/Fila';
import FluxoEspera from './components/Fluxo/roomCirculante'
import FluxoExame from './components/Fluxo/roomTecnico'
import api from './services/api'
import Recepcao from './components/Recepcao/Recepcao';
import Atendimento from './components/Atendimento/Atendimento';
import io from 'socket.io-client';
import SocketContext from './services/SocketProvider'
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import Concialiacao from './components/Conciliacao';
import Guiche from './components/Guiche'
import ContactsIcon from '@material-ui/icons/Contacts';
import Fila2 from './components/Fila2';
import Painel from './components/Painel'
import EmpresaConfirmacao from './components/EmpresaConfirmacao'
import AgendaTecnico from './components/AgendaTecnico'
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff5656',
      light: '#d9d9d9',
    },
    secondary: {
      light: '#d9d9d9',
      main: '#023575',
    },
  },
  typography: {
    fontFamily: [
      'Cambay',
      'sans-serif',
    ].join(','),
  },
});

// const CustomLayout = props => ( 
//   <Layout {...props} appBar={AppBar} />
// );

const dataProvider = jsonServerProvider(api);
// const MyLoginPage = () => <Login backgroundImage='http://api.chronos.vallions.com.br/img/chronos.png' marginTop="12em" />;
function App() {
  // const socket = io('http://socket.chronos.vallions.com.br');
  const socket = io('http://18.229.102.154:85/');
  return (
    <SocketContext.Provider value={socket}>
      <Admin
        theme={theme}
        dashboard={Dashboard}
        authProvider={authProvider}
        loginPage={Login}
        dataProvider={dataProvider}
        layout={Layout}>
        {permissions => [
          <Resource name="agenda" options={{ label: 'Agenda' }} list={Backup} icon={Calendar} />,
          <Resource name="agendados" options={{ label: 'Agendados', callcenter: true }} list={Recepcao} icon={MeusAgendamentos} />,
          <Resource name="guiche" options={{ label: 'Guiche', callcenter: true }} list={Guiche} icon={ContactsIcon} />,
          <Resource name="painel" options={{ label: 'Painel', callcenter: true }} list={Painel} icon={ContactsIcon} />,
          permissions === 'suporte' || permissions === 'faturamento' || permissions === 'gestao' ? <Resource name="conciliacao" options={{ label: 'Conciliação' }} list={Concialiacao} icon={SyncAltIcon} /> : null,
          permissions === 'suporte' || permissions === 'atendente' || permissions === 'gestao' ? <Resource name="fila" options={{ label: 'Fila' }} list={Fila} icon={QueueIcon} /> : null,
          permissions === 'suporte' || permissions === 'atendente' || permissions === 'gestao' ? <Resource name="fila2" options={{ label: 'Fila2' }} list={Fila2} icon={QueueIcon} /> : null,
          permissions === 'suporte' || permissions === 'atendente' || permissions === 'gestao' ? <Resource name="fluxoCirculantes" list={FluxoEspera} options={{ label: 'Fluxo Circulante' }} icon={FlowIcon} /> : null,
          permissions === 'suporte' || permissions === 'atendente' || permissions === 'gestao' ? <Resource name="fluxoTecnico" list={FluxoExame} options={{ label: 'Fluxo Técnico' }} icon={FlowIcon} /> : null,
          permissions === 'suporte' || permissions === 'atendente' || permissions === 'gestao' ? <Resource name="recepcao" options={{ label: 'Recepção', callcenter: false }} list={Recepcao} /> : null,
          permissions === 'suporte' || permissions === 'atendente' || permissions === 'gestao' ? <Resource name="agendaTecnico" list={AgendaTecnico} options={{ label: 'Agenda Técnico' }} icon={FlowIcon} /> : null,
          // <Resource name="Usuarios" list={ResetSenha} icon={PersonIcon} />,
          // <Resource name="Configurações" list={ConfiguracaoUsuarioParceiro} icon={BuildIcon} />
          permissions === 'suporte' ? <Resource name="usuario" list={ResetSenha} options={{ label: 'Usuários' }} icon={PersonIcon} /> : null,
          permissions === 'suporte' ? <Resource name="empresas_confirmacao" list={EmpresaConfirmacao} options={{ label: 'Empresas Confirmação' }} icon={FlowIcon} /> : null,
          permissions === 'suporte' || permissions === 'gestao' || permissions === 'comercial' ? <Resource name="config" options={{ label: 'Usuário Parceiro' }} list={ConfiguracaoUsuarioParceiro} icon={BuildIcon} /> : null
        ]}
      </Admin>
    </SocketContext.Provider>
  );
}

export default App;
