import React from 'react';
import Table, { MTableToolbar } from 'material-table';
import { Paper, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../../services/api'
import Recepcao from '../Recepcao/Recepcao';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isThisSecond } from 'date-fns';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SocketContext from '../../services/SocketProvider';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import DialogNewGuiche from './DialogNewGuiche';
import Fila from '../Fila2'
// import SuccessAlert from '../Alertas/SuccessAlert';
const AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: "AKIA44J23C7BY6ASKLST",
    secretAccessKey: "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"
});
let s3 = new AWS.S3();

const styles = theme => ({
    paper: {
        width: "100%",
        padding: 20
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    },
    button: {
        margin: theme.spacing(1),
        border: 'none'
    },
    container: {
        position: 'relative',
        padding: '10px',
        width: '100%',
    },
    buttonContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex'
    },
    cardContainer: {
        padding: 20,
        display: 'flex',
        gap: '5%',
        cursor: 'pointer'

    },
    rootCard: {
        width: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    cardContent: {
        textAlign: 'center'
    },
    contentLivre: {
        color: 'green',
        fontSize: "16px"
    },
    contentInativo: {
        color: '#023575',
        fontSize: "16px"
    },
    contentOcupado: {
        color: 'red',
        fontSize: "16px"
    },
    contentName: {
        fontSize: "24px",
        fontWeight: 'bold',
    },
    contentNameInativo: {
        fontSize: "24px",
        fontWeight: 'bold',
        color: '#B5BFCB',
    },
    contentHeader: {
        fontSize: "16px",
        textAlign: 'center'
    },
    titlePage: {
        fontSize: '12px',
        fontWeight: 'bold',
        margin: '15px'
    },
})


class Guiche extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            unidades: JSON.parse(localStorage.getItem('unidades')) != null ? JSON.parse(localStorage.getItem('unidades')) : [],
            unidade: null,
            loading: false,
            recepcao: false,
            queue: null,
            openModalImagel: false,
            finalizado: false,
            openMenu: false,
            anchorEl: null,
            menuOptions: ['Editar', 'Excluir'],
            // guiches: [
            //     { name: "Guiche 1", status_active: 1, status: 1 },
            //     { name: "Guiche 2", status_active: 1, status: 0 }
            // ],
            guiches: [],
            messageSuccess: "",
            guiche_atual: null,
            openFila: false
        }
    }
    getGuiches = (branch) => {
        this.setState({ loading: true })
        api.post('/getGuiche', {
            branch: branch
        }
        ).then(resp => {
            this.setState({ guiches: resp.data, loading: false })
            console.log(resp.data)
        })
    }
    handleChangeUnidade = (event) => {
        this.setState({ unidade: event.target.value })
        this.getGuiches(event.target.value);
    };
    handleClick = (event, guiche) => {
        this.setState({ openMenu: !this.state.openMenu, anchorEl: event.currentTarget, guiche_atual: guiche })
    };
    handleClose = () => {
        this.setState({ openMenu: false, anchorEl: null, guiche_atual: null })
    };
    handleSaveAnexo = () => {
        this.getGuiches(this.state.unidade);
        this.setState({ newGuiche: false, guiche_atual: null, messageSuccess: "O guichê foi cadastrado com sucesso", openMenu: false, anchorEl: null })
    }
    handleFrontDesk = (guiche, status) => {
        api.put(`/alterStatusFrontDesk/${guiche.id}`, {
            status: status == "0" ? "1" : "0"
        }).then(resp => {
            console.log(resp.data)
            this.getGuiches(this.state.unidade)
            this.setState({ openFila: true, guiche_atual: guiche })
        })
    }
    handleItem = (event, index) => {
        var guiche = this.state.guiche_atual
        if (index == 1) {
            api.post(`/deleteGuiche/${guiche.id}`, {}).then(resp => {
                console.log(resp.data)
                this.getGuiches(this.state.unidade)
                this.setState({ guiche_atual: null })
                this.handleClose()
            })
        } else {
            this.setState({ newGuiche: true })
        }
    }
    handleCloseFila = () => {
        api.put(`/alterStatusFrontDesk/${this.state.guiche_atual?.id}`, {
            status: this.state.guiche_atual?.status != "0" ? "1" : "0"
        }).then(resp => {
            console.log(resp.data)
            this.getGuiches(this.state.unidade)
            this.setState({ guiche_atual: null, openFila: false })
        })
    }
    render() {
        const { classes } = this.props
        return (
            <>
                {
                    this.state.openFila ?
                        <Fila guiche={this.state.guiche_atual} unidade={this.state.unidade} close={this.handleCloseFila} />
                        :

                        <Paper className={classes.paper} >
                            {this.state.newGuiche && <DialogNewGuiche guiche={this.state.guiche_atual} handleClose={() => this.setState({ newGuiche: false, guiche_atual: null, openMenu: false, anchorEl: null })} handleSave={this.handleSaveAnexo} />}
                            {/* <SuccessAlert
                    open={this.state.messageSuccess !== ""}
                    message={this.state.messageSuccess}
                    onClose={() => this.setState({ messageSuccess: "" })}
                /> */}
                            <div className={classes.container}>
                                <FormControl style={{ width: '350px', margin: '5px' }} >
                                    <InputLabel id="demo-simple-select-label">Unidade</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.unidade}
                                        onChange={this.handleChangeUnidade}
                                    >

                                        {this.state.unidades.map(unidade => {
                                            return <MenuItem value={unidade.value}>{unidade.label}</MenuItem>
                                        })}

                                    </Select>
                                </FormControl>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        // variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<RefreshIcon />}
                                        disabled={this.state.unidade == null}
                                        onClick={() => this.getGuiches(this.state.unidade)}
                                    >
                                        <RefreshIcon />
                                        Atualizar
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        onClick={() => this.setState({ newGuiche: true })}
                                    >
                                        <AddIcon />
                                        Guichê
                                    </Button>

                                </div>
                            </div>
                            <div>
                                <Typography className={classes.titlePage} component="h6">
                                    SELECIONE UM GUICHÊ PARA COMEÇAR A ATENDER OS PACIENTES
                                </Typography>
                            </div>

                            {this.state.loading && <div style={{ textAlign: 'center', width: '100%' }} >
                                <CircularProgress
                                    size={70}
                                    thickness={2}
                                />
                            </div>}

                            <div className={classes.cardContainer}>
                                {this.state.guiches.map((guiche, index_guiche) => <Card className={classes.rootCard}
                                >
                                    <CardHeader
                                        action={
                                            <IconButton aria-label="settings"
                                                // onClick={guiche.status_active == 1 ? this.handleClick : () => { }}>
                                                onClick={(event) => this.handleClick(event, guiche)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        }
                                        classes={{ title: classes.contentHeader }}
                                        title="ENTRAR NO"
                                    />
                                    <Menu
                                        id="long-menu"
                                        anchorEl={this.state.anchorEl}
                                        keepMounted
                                        open={this.state.openMenu}
                                        onClose={this.handleClose}
                                    >
                                        <MenuItem key={'Editar'} selected={'Editar' === 'Pyxis'} onClick={(event) => this.handleItem(event, 0)}>
                                            {'Editar'}
                                        </MenuItem>
                                        <MenuItem key={'Excluir'} selected={'Excluir' === 'Pyxis'} onClick={(event) => this.handleItem(event, 1)}>
                                            {'Excluir'}
                                        </MenuItem>
                                    </Menu>
                                    <CardContent className={classes.cardContent}
                                        onClick={() => guiche.status_active == 1 ? this.handleFrontDesk(guiche, guiche.status) : () => { }}>
                                        <Typography variant="h4" className={guiche.status_active == 1 ? classes.contentName : classes.contentNameInativo} color="textSecondary" gutterBottom>
                                            {guiche.name}
                                        </Typography>
                                        {(guiche.status == 0 && guiche.status_active == 1) && <Typography className={classes.contentLivre} variant="h6">
                                            LIVRE
                                        </Typography>}
                                        {(guiche.status == 1 && guiche.status_active == 1) && <Typography className={classes.contentOcupado} variant="h6">
                                            EM ATENDIMENTO
                                        </Typography>}
                                        {guiche.status_active == 0 && <Typography className={classes.contentInativo} variant="h6">
                                            INATIVO
                                        </Typography>}

                                    </CardContent>
                                </Card>)}

                            </div>

                        </Paper >
                }
            </>
        )

    }
}
const GuicheWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <Guiche {...props} socket={socket} />}
    </SocketContext.Consumer>
)



export default withStyles(styles)(GuicheWithSocket)