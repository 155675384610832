import React from 'react';
import Table, { MTableToolbar } from 'material-table';
import { Paper, withStyles, Typography, IconButton, Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../../services/api'
import Recepcao from '../Recepcao/Recepcao';
import { Lightbox } from "react-modal-image";
import { isThisSecond } from 'date-fns';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import SocketContext from '../../services/SocketProvider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Agendamento from '../InputsForm';
import RefreshIcon from '@material-ui/icons/Refresh';

const AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: "AKIA44J23C7BY6ASKLST",
    secretAccessKey: "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"
});
let s3 = new AWS.S3();

const styles = theme => ({
    paper: {
        width: "100%",
        padding: 20
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 275,
    },
    formControlCheck: {
        marginLeft: theme.spacing(1),
        minWidth: 175,
        fontSize: '0.5em'
    },
    titlePage: {
        fontSize: '12px',
        fontWeight: 'bold',
    },
})


class Fila extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            unidades: JSON.parse(localStorage.getItem('unidades')) != null ? JSON.parse(localStorage.getItem('unidades')) : [],
            unidade: sessionStorage.getItem('unidade_queue') != null ? sessionStorage.getItem('unidade_queue') : null,
            loading: false,
            recepcao: false,
            queue: null,
            openModalImagel: false,
            finalizado: false
        }
        let socket = props.socket;
        sessionStorage.getItem('unidade_queue') != null && this.getQueue(sessionStorage.getItem('unidade_queue'))

        console.log(this.props.unidade)
    }
    getImage = async (img) => {
        var params = { Bucket: 'vallions', Key: 'images/private/' + img };
        var url = await s3.getSignedUrl('getObject', params);
        return url;
    }
    componentDidMount() {
        this.props.socket.on(`atualizaQueue`, () => {
            this.getQueue();
        })
        this.setState({ unidade: this.props.unidade })
        this.getQueue()
    }

    atualizaQueue = () => {
        if (this.state.unidade != null) {
            this.getQueue(this.state.unidade);
        }
    }
    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        this.getQueue(value);
        sessionStorage.setItem('unidade_queue', value);
    };
    getQueue = () => {
        this.setState({ loading: true })
        api.post('/queueTodayNotUser', {
            unidade: this.props.unidade,
            finalizado: this.state.finalizado
        }).then(async response => {
            // await response.data.map(async u => await Object.assign(u, { pathS3: await this.getImage(u.path) }));
            this.setState({ data: await response.data, loading: false })
        })
    }
    chamarSenha = data => {
        console.log(data)
        this.props.socket.emit('senha_guiche', { senha: data.pass, guiche: this.props.guiche?.name });
    }
    chamarAtendimento = (data) => {
        this.setState({ queue: data, recepcao: true })
    }
    chamarSenhaAgendamento = data => {
        console.log(data)
        this.setState({ queue: data, agendamento: true })
    }
    closeModel = () => {
        this.setState({ openModalImagel: false })
    }
    encerrarFila = (data) => {
        api.post('/encerarFila', {
            user: localStorage.getItem('login'),
            queue: data.idqueue,
            idflow: data.idFlow,
        }).then(resp => {
            this.atualizaQueue();
        }).catch(error => {

        })
    }
    handleChangeCheck = async event => {
        // console.log(event.target.checked)
        await this.setState({ [event.target.value]: event.target.checked });
        this.atualizaQueue();
    };
    render() {
        const { classes } = this.props
        return (this.state.recepcao ? <Recepcao queue={this.state.queue} options={{ callcenter: false }} close={() => this.setState({ recepcao: false })} />
            :
            this.state.agendamento ? <Agendamento queue={this.state.queue} options={{ callcenter: false }} close={() => { this.setState({ agendamento: false }); this.atualizaQueue() }} />
                :
                <Paper className={classes.paper} >
                    <Box display={"flex"} alignItems={"center"} fontSize={"16px"} >
                        <IconButton onClick={() => { this.props.close() }} >
                            <ChevronLeftIcon />
                        </IconButton>
                        <Typography className={classes.titlePage} component="h6">
                            Voltar para guichê
                        </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} fontSize={"16px"} >
                        <Typography component="h2">
                            Fila de Atendimento - {this.props.guiche?.name}
                        </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"end"} fontSize={"16px"} >
                        <Button
                            // variant="outlined"
                            size="small"
                            className={classes.button}
                            startIcon={<RefreshIcon />}
                            disabled={this.state.unidade == null}
                            onClick={() => this.atualizaQueue()}
                        >
                            <RefreshIcon />
                            Atualizar
                        </Button>
                    </Box>
                    <Table
                        title="Fila"
                        options={{
                            // selection: true,
                            actionsColumnIndex: -1,
                            sorting: true,
                            rowStyle: rowData => {
                                var style = {
                                }
                                if (rowData.type_priority == 2) {
                                    style = {
                                        borderLeftColor: '#EB1600',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                } else if (rowData.type_priority == 1) {
                                    style = {
                                        borderLeftColor: '#E08401',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                }
                                return style
                            }
                        }}
                        isLoading={this.state.loading}
                        data={this.state.data}
                        columns={[
                            { title: 'Senha', field: 'pass' },
                            {
                                title: 'Prioridade',
                                field: 'type_priority',
                                lookup: { 0: '1- Comum', 1: '2- Preferencial', 2: '3- Pref. +80' },
                                defaultSort: "desc"

                            },
                            { title: 'Tipo', field: 'type_pass', lookup: { 1: 'Atendimento Exames', 2: 'Atendimento Lab.', 3: 'Informações', 4: 'Etr. Exames' } },
                            { title: 'Entrada', field: 'checkin' },
                            { title: 'Nome', field: 'name' },
                            // { title: 'Unidade', field: 'units' },
                        ]}
                        actions={[
                            {
                                icon: RecordVoiceOverIcon,
                                tooltip: 'Chamar Paciente',
                                onClick: (event, data) => this.chamarSenha(data)
                            },
                            {
                                icon: EmojiPeopleIcon,
                                tooltip: 'Realizar Atendimento',
                                onClick: (event, data) => this.chamarAtendimento(data)
                            },
                            {
                                icon: PersonAddIcon,
                                tooltip: 'Realizar Agendamento',
                                onClick: (event, data) => this.chamarSenhaAgendamento(data)
                            },
                            {
                                icon: HowToRegIcon,
                                tooltip: 'Concluir Atendimento',
                                onClick: (event, data) => this.encerrarFila(data)
                            },
                        ]}
                        components={{
                            Toolbar: props => (
                                <div>
                                    <MTableToolbar {...props} />
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            inputProps={{
                                                name: 'unidade',
                                            }}
                                            value={this.state.unidade}
                                            onChange={this.handleChange}
                                            disabled
                                        >
                                            {this.state.unidades.map(unidade => {
                                                return <MenuItem value={unidade.value}>{unidade.label}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel
                                        className={classes.formControlCheck}
                                        control={<Checkbox size="small" checked={this.state.finalizado} onChange={(e) => this.handleChangeCheck(e)} value="finalizado" />}
                                        // label="CHAMADAS ATENDIDAS"
                                        label={
                                            <Typography style={{ fontFamily: 'Arial', color: "black", fontSize: '0.7rem' }}>
                                                TODAS CHAMADAS CONCLUÍDAS
                                            </Typography>
                                        }
                                    />
                                    {/* <FormControlLabel
                                        className={classes.formControlCheck}
                                        control={<Checkbox size="small" checked={this.state.atender} onChange={(e) => this.handleChangeCheck(e)} value="atender" />}
                                        // label="CHAMADAS ATENDER"
                                        label={
                                            <Typography style={{ fontFamily: 'Arial', color: "black", fontSize: '0.7rem' }}>
                                                CHAMADAS ATENDER
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        className={classes.formControlCheck}
                                        control={<Checkbox size="small" checked={this.state.agendamento} onChange={(e) => this.handleChangeCheck(e)} value="agendamento" />}
                                        // label="CHAMADAS AGENDAMENTO"
                                        label={
                                            <Typography style={{ fontFamily: 'Arial', color: "black", fontSize: '0.7rem' }}>
                                                CHAMADAS AGENDAMENTO
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        className={classes.formControlCheck}
                                        control={<Checkbox size="small" checked={this.state.agendados} onChange={(e) => this.handleChangeCheck(e)} value="agendados" />}
                                        // label="CHAMADAS CONCLUÍDO ATENDIMENTO"
                                        label={
                                            <Typography style={{ fontFamily: 'Arial', color: "black", fontSize: '0.7rem' }}>
                                                CHAMADAS CONCLUÍDO ATENDIMENTO
                                            </Typography>
                                        }
                                    /> */}
                                </div>
                            ),
                        }}
                    />
                </Paper >

        )
    }
}
const FilaWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <Fila {...props} socket={socket} />}
    </SocketContext.Consumer>
)



export default withStyles(styles)(FilaWithSocket)