import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import './Perguntas-Form.css';
import api from '../../services/api'
import { format } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Hashids from 'hashids';
import Fab from "@material-ui/core/Fab";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '../Alertas/Alert';
import AlertConcluse from '../Alertas/Concluse';
import { useLogout } from 'ra-core';
import DialogAnexo from './DialogAnexo';
import axios from 'axios';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const distinct = (value, index, self) => {
  return self.indexOf(value) === index;
}
const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: "AKIA44J23C7BY6ASKLST",
  secretAccessKey: "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"
});
let s3 = new AWS.S3();
export default class App extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.queue)
    console.log(props)

  }
  state = {
    open: true,
    fullWidth: true,
    maxWidth: 'md',
    perguntas: null,
    filtro_pergunta: null,
    orientacoes: null,
    cd_empresa: 1,
    status: false,
    dt_atendimento: format(new Date(), "dd/MM/yyyy"),
    res_perguntas: [],
    item_agenda: this.props.itens.filter(distinct).join(),
    nome: this.props.nome,
    unidade: this.props.unidade.label,
    convenio: this.props.convenio,
    plano: this.props.plano,
    info_itens: this.props.info_itens,
    obs: this.props.obs,
    endereco: this.props.unidade.endereco,
    telefone: this.props.telefone,
    copied: false,
    link: null,
    cd_paciente: this.props.cd_paciente,
    alert: false,
    alertTitle: null,
    alertBody: null,
    alertConcluse: false,
    loading: false,
    loadingC: false,
    dtNasc: this.props.dtNasc == null ? null : format(this.props.dtNasc, "yyyy-MM-dd"),
    dialogAnexo: false,
    files: [],
    filesPreviews: [],
    filesS3: []
  };

  handleCloseAlert = () => {
    this.setState({ alert: false });
  };
  handleConcluseAlert = () => {
    console.log(this.props.queue)
    if (this.props.queue == undefined) {
      window.location.reload();
    } else {
      api.post('/encerarFila', {
        user: localStorage.getItem('login'),
        queue: this.props.queue.idqueue,
        idflow: this.props.queue.idFlow,
      }).then(resp => {
        this.props.closeQueue()
      }).catch(error => {
      })

    }
  };
  setDataOrientacao = (cd_item_agendamento) => {
    if (this.state.info_itens.filter(s => s.exame === cd_item_agendamento).length > 1) {
      var valor = this.state.info_itens.filter(s => s.exame === cd_item_agendamento);
      var data = ''
      for (var i = 0; i <= this.state.info_itens.filter(s => s.exame === cd_item_agendamento).length; i++) {
        data += new Date(valor[i].d_i).toLocaleDateString() + "-" + new Date(valor[i].d_i).toLocaleTimeString() + "| "
        if (i == (this.state.info_itens.filter(s => s.exame === cd_item_agendamento).length - 1)) {
          return data
        }
      }

    } else {
      return new Date(this.state.info_itens.filter(s => s.exame === cd_item_agendamento).map(s => s.d_i)).toLocaleDateString() + "-" + new Date(this.state.info_itens.filter(s => s.exame === cd_item_agendamento).map(s => s.d_i)).toLocaleTimeString()
    }
  }
  setOrientacoes = (orientacoes) => orientacoes.map(orientacao => {
    return <section key={orientacao.cd_item_agendamento}>
      <h3>
        {this.setDataOrientacao(orientacao.cd_item_agendamento) + orientacao.ds_item_agendamento}
      </h3>
      <textarea readOnly rows="5" className="perguntas-orientacao">
        {orientacao.ds_orientacao}
      </textarea>
    </section>
  });

  handleClickOpen = () => {
    this.setState({ open: true });
  }

  novoValor = (res, resp, p_exame) => { return Object.assign(res, { "resposta": resp, "it_ini": this.state.info_itens.filter(s => s.exame === p_exame).map(s => s.it_ini).join() }) };

  handleRadio = (e, resp, valor) => {
    this.state.perguntas.forEach((element, index) => {
      if (element.cd_pergunta === valor) {
        var resultado = this.state.perguntas.filter(res => res.cd_pergunta === valor).map(res => this.novoValor(res, resp, res.cd_item_agendamento))
        for (let i = 0; i < this.state.res_perguntas.length; i++) {
          if (this.state.res_perguntas[i].cd_pergunta === valor) {
            this.state.res_perguntas.splice(i, 1);
            this.setState({ res_perguntas: this.state.res_perguntas.concat(resultado) })
          }
        }
        this.setState({ res_perguntas: this.state.res_perguntas.concat(resultado) })
      }
    });
  }

  setPerguntas = (perguntas) => perguntas.map(pergunta => {
    return <section key={pergunta.cd_pergunta}>
      <div className="perguntas-colPerguntas">
        <section className="perguntas-perguntas">
          <div>{pergunta.ds_pergunta}</div>
        </section>
        <section className="perguntas-respostas">
          <div className="perguntas-respostas">
            <div className="perguntas-resposta">
              <label htmlFor={pergunta.cd_pergunta}>SIM</label>
              <input type="radio" name={pergunta.cd_pergunta} onChange={e => this.handleRadio(e, 'S', pergunta.cd_pergunta)} id={pergunta.cd_pergunta} />
            </div>
            <div className="perguntas-resposta">
              <label htmlFor={pergunta.cd_pergunta + "N"}>NÃO</label>
              <input type="radio" name={pergunta.cd_pergunta} onChange={e => this.handleRadio(e, 'N', pergunta.cd_pergunta)} id={pergunta.cd_pergunta + "N"} />
            </div>
          </div>
        </section>
      </div>
    </section>
  });

  handleClose = () => {
    this.setState({ open: false });
    this.props.close();
  }

  handleCopiado = () => {
    if (this.state.perguntas.length === 0 || this.state.res_perguntas.length === this.state.perguntas.length) {
      const hashids = new Hashids();
      var domain = localStorage.getItem('domain');
      this.setState({ link: `http://api.chronos.vallions.com.br/print/orientacao/${domain}/${hashids.encode(this.state.info_itens.map(s => s.it_ini))}`, copied: true });
      this.agendar();
    } else {
      this.setState({ alert: true, alertTitle: 'Alerta', alertBody: 'Preencha as respostas!' });
      this.setState({ loadingC: false })
      this.setState({ loading: false })
    }
  };

  componentDidMount() {
    this.getPerguntas(this.state.item_agenda)

    this.getOrientacoes(this.state.convenio, this.state.plano.value, this.state.item_agenda)
    let remarca = sessionStorage.getItem('remarca') != undefined ? JSON.parse(sessionStorage.getItem('remarca')) : []
    console.log(remarca)
    if (remarca.length > 0) {
      this.getImages(remarca);
    }
  }

  getPerguntas = async (item_agenda) => {
    const response = await api.post('/perguntas', {
      cd_item_agenda: item_agenda,
    })
    const result = Array.from(new Set(response.data.map(s => s.cd_pergunta))).map(cd => {
      return {
        cd_pergunta: cd,
        ds_pergunta: response.data.find(s => s.cd_pergunta === cd).ds_pergunta
      }
    })
    this.setState({ perguntas: response.data, statusPerguntas: true, filtro_pergunta: result })
  }
  getImages = async (item) => {
    await api.post('/anexos', {
      item: item.map(item => item.cd_it_agenda_central + "|" + item.cd_agenda_central).join(",")
    }).then(async resp => {
      await resp.data.map(async u => await Object.assign(u, { path_image: await this.getImageS3(u.path) }))
      this.setState({ filesS3: await resp.data })
    })
  }

  getImageS3 = async (img) => {
    var params = { Bucket: 'vallions', Key: 'images/anexos/' + img };
    var url = await s3.getSignedUrl('getObject', params);
    return url;
  }

  getOrientacoes = async (cd_convenio, cd_plano, item) => {
    await api.post('/orientacaoConvenioPlano', {
      cd_convenio: cd_convenio,
      cd_plano: cd_plano,
      item: item,
      empresa: this.props.unidade.value
    }).then((response) => {
      this.setState({ orientacoes: response.data, statusOrientacoes: true })
    }).catch(error => {
      this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
    });
  }
  remarcar = () => {
    var z = 0
    let remarca = sessionStorage.getItem('remarca') != undefined ? JSON.parse(sessionStorage.getItem('remarca')) : []
    var remarcar_anexo = remarca.map(r => r.cd_it_agenda_central + "|" + r.cd_agenda_central).join(",")
    var atuais_anexo = this.state.info_itens.map(item => item.it_ini + "|" + item.agenda).join(",")
    if (remarca.length > 0) {
      api.post('/remarcarAnexo', {
        anterior: remarcar_anexo,
        atual: atuais_anexo
      })
      remarca.map(r => {
        z++;
        api.post('/removeAgendamento', {
          it_agc: r.cd_it_agenda_central,
          user: localStorage.getItem('login')
        }).then(response => {
          if (z == remarca.length) {
            sessionStorage.removeItem('remarca');
            this.setState({ loadingC: false })
            this.setState({ loading: false })
            this.setState({ alertConcluse: true, alertTitle: 'Alerta', alertBody: 'Agendamento concluído com sucesso!' });
          }
        }).catch(e => {
          if (z == remarca.length) {
            sessionStorage.removeItem('remarca');
            this.setState({ loadingC: false })
            this.setState({ loading: false })
            this.setState({ alert: true, alertTitle: 'Erro', alertBody: 'Erro ao remover agendamento' });
          }
        })
      })

    } else {
      this.setState({ loadingC: false })
      this.setState({ loading: false })
      this.setState({ alertConcluse: true, alertTitle: 'Alerta', alertBody: 'Agendamento concluído com sucesso!' });
    }
  }
  salvarRespostas = () => {
    var i = 0
    var z = 0
    this.state.res_perguntas.map(item => {
      api.post('/inserirResposta', {
        cd_pergunta: item.cd_pergunta,
        resposta: item.resposta,
        it_ini: item.it_ini,
      }).then((response) => {
        i++;
        if (i === this.state.res_perguntas.length) {
          this.remarcar();
        }
      }).catch(function (error) {
        sessionStorage.removeItem('remarca');
        this.setState({ loading: false })
        this.setState({ loadingC: false })
        this.setState({ alert: true, alertTitle: 'Erro', alertBody: 'Erro agendar ' + error });
        console.log(error)
      });
    })
  }

  agendar = () => {
    if (this.state.perguntas.length === 0 || this.state.res_perguntas.length === this.state.perguntas.length) {
      if (this.state.loadingC === false) {
        this.setState({ loadingC: true })
        var i = 0
        var z = 0
        this.state.info_itens.map(item => {
          api.post('/inserirAgendamento', {
            cd: item.exame,
            nome: this.state.nome,
            it_ini: item.it_ini,
            it_fi: item.it_fim,
            agd_central: item.agenda,
            convenio: this.state.convenio,
            plano: this.state.plano.value,
            user: localStorage.getItem('login'),
            obs: this.state.obs,
            fone: this.state.telefone,
            d_i: item.d_i,
            cd_paciente: this.state.cd_paciente,
            t: item.t,
            dtNasc: this.state.dtNasc
          }).then((response) => {
            i++;

            if (i === this.state.info_itens.length) {
              if (this.state.perguntas.length === 0) {
                this.remarcar();
              }
              else {
                this.salvarRespostas();
              }
              var items_anexo = this.state.info_itens.map(item => item.it_ini + "|" + item.agenda).join(",")
              for (let index = 0; index < this.state.files.length; index++) {
                const file = this.state.files[index];
                var formData = new FormData();
                formData.append("image", file);
                formData.append("item", items_anexo);
                axios.post('http://localhost:8000/api/s3Image', formData, { headers: { 'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) } })
                  // axios.post('http://api.chronos.vallions.com.br/api/s3Image', formData, { headers: { 'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) } })
                  .then(resp => {
                    console.log(resp)
                  }).catch(error =>
                    console.log('S3', error)
                  )
              }
            }
          })
            .catch((error) => {
              this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Erro ao concluir ${error}` });
            });

          // for (let index = 0; index < this.state.files.length; index++) {
          //   const file = this.state.files[index];
          //   var formData = new FormData();
          //   formData.append("image", file);
          //   formData.append("it_init", item.it_ini);
          //   // formData.append("it_fim", item.it_fim);
          //   formData.append("agd_central", item.agenda);
          //   // formData.append("domain", localStorage.getItem('domain'));
          //   // let filename = filesPreviews.preview.split('/').pop();
          //   // let match = /\.(\w+)$/.exec(filename);
          //   // let type = match ? `image/${match[1]}` : `image`;
          // }

        })
      }

    }
    else {
      this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha as respostas!' });
      this.setState({ loadingC: false })
      this.setState({ loading: false })
    }
  }

  agendarImprimir = () => {
    const hashids = new Hashids();
    if (this.state.perguntas.length === 0 || this.state.res_perguntas.length === this.state.perguntas.length) {
      if (this.state.loading === false) {
        this.setState({ loading: true })
        var i = 0
        var z = 0;
        this.state.info_itens.map(item => {
          api.post('/inserirAgendamento', {
            cd: item.exame,
            nome: this.state.nome,
            it_ini: item.it_ini,
            it_fi: item.it_fim,
            agd_central: item.agenda,
            convenio: this.state.convenio,
            plano: this.state.plano.value,
            user: localStorage.getItem('login'),
            obs: this.state.obs,
            fone: this.state.telefone,
            d_i: item.d_i,
            cd_paciente: this.state.cd_paciente,
            t: item.t,
            dtNasc: this.state.dtNasc
          }).then((response) => {
            i++;
            if (i === this.state.info_itens.length) {
              if (this.state.perguntas.length === 0) {
                this.remarcar();
              }
              else {
                this.salvarRespostas();
              }
              var domain = localStorage.getItem('domain');
              window.open(`http://api.chronos.vallions.com.br/print/orientacao/${domain}/${hashids.encode(this.state.info_itens.map(s => s.it_ini))}`, '_blank');
            }
          })
            .catch((error) => {
              this.setState({ alert: true, alertTitle: 'Erro', alertBody: `Erro ao concluir ${error}` });
            });
        })
      }

    } else {
      this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha as respostas!' });
      this.setState({ loadingC: false })
      this.setState({ loading: false })
    }
  }
  handleSaveAnexo = (files) => {
    this.setState({ files: files, dialogAnexo: false })
  }
  removeFile = async (file) => {
    console.log("remover")
    let newFiles = [...this.state.files];
    await newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({ filesS3: [] })
    this.setState({ files: await newFiles })

  };
  removeFileS3 = async (file) => {
    let newFiles = [...this.state.filesS3];
    await newFiles.splice(newFiles.indexOf(file), 1);
    this.setState({ filesS3: [] })
    this.setState({ filesS3: await newFiles })
    var delete_anexo = file.it_init + "|" + file.agd_central
    axios.post('http://api.chronos.vallions.com.br/api/deletarAnexo', {
      item: delete_anexo,
      anexo: file.id
    })
      .then(resp => {
        console.log(resp)
      }).catch(error =>
        console.log('S3', error)
      )

  };

  renderImages = (images) => {
    let contador = 0
    let imagens = [];
    let imagens_final = []
    for (let index = 0; index < images.length; index++) {
      contador++;
      const file = images[index];
      if (contador < 2) {
        images.push(<div style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box"
        }} key={file.name}>
          <div style={{
            display: "flex",
            minWidth: 0,
            overflow: "hidden"
          }}>
            <img src={file.preview} style={{
              display: "block",
              width: "auto",
              height: "100%"
            }} alt={file.name} />
          </div>
          <button onClick={this.removeFile(file)}>Remove File</button>
        </div>);
      } else {

      }


    }
  }


  handleButtonClick = (index) => {
    // Lógica para manipular o clique do botão
    console.log(`Botão clicado para a imagem ${index + 1}`);
  };
  render() {
    const { fullWidth, open, maxWidth, perguntas, orientacoes, statusPerguntas, statusOrientacoes, filtro_pergunta } = this.state
    const { nome, dt_atendimento, unidade, endereco } = this.state
    const hashids = new Hashids();
    var domain = localStorage.getItem('domain');
    return (
      <div>
        {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
        {this.state.alertConcluse && <AlertConcluse title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleConcluseAlert} />}
        {this.state.dialogAnexo && <DialogAnexo handleClose={() => this.setState({ dialogAnexo: false })} handleSave={this.handleSaveAnexo} />}
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <div className="perguntas-container">
            <Paper className="perguntas-paper">
              <div className="perguntas-grid">
                <div className="perguntas-colPaciente">
                  <section>
                    <h4>Nome Paciente: {nome}</h4>
                  </section>
                </div>
                <div>
                  <section>
                    <h4>Unidade de Atendimento: {unidade}</h4>
                  </section>
                  <section>
                    <h4>Endereço da Unidade: {endereco}</h4>
                  </section>
                </div>
              </div>
            </Paper>
            <div className="perguntas-divisor"></div>
            <Paper className="perguntas-paper">
              <div className="perguntas-grid">
                <section className="anexo-section-header">
                  <h2>Perguntas</h2>
                </section>
                {statusPerguntas ? this.setPerguntas(filtro_pergunta) : ""}

              </div>
            </Paper>

            <Paper className="anexo-container">
              <div className="perguntas-grid">
                <section className="anexo-section-header">
                  <h2>Anexo</h2>
                </section>
              </div>
              <div className='anexo-content'>
                {(this.state.files.length == 0 && this.state.filesS3.length == 0) ? "Clique no campo abaixo para anexar os documentos do paciente" :
                  <>
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}>
                      <ImageList imageArray={this.state.filesS3} handleButtonClick={this.removeFileS3} />
                    </div>
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                    }}>
                      <ImageListFile imageArray={this.state.files} handleButtonClick={this.removeFile} />
                    </div>
                  </>
                }
              </div>
              <div className='anexo-buttons'>
                <Button variant="contained" color="primary" className="perguntas-button" onClick={() => { this.setState({ dialogAnexo: true }) }}>
                  Anexar Documento
                </Button>
                <Button variant="contained" color="primary" className="perguntas-button" onClick={() => { }}>
                  Capturar Imagem
                </Button>
              </div>
            </Paper>


            <div className="perguntas-divisor"></div>
            <Paper className="perguntas-paper">
              <div className="perguntas-grid">
                <section className="anexo-section-header">
                  <h2>Orientações dos Exames</h2>
                </section>
                {statusOrientacoes ? this.setOrientacoes(orientacoes) : ""}
              </div>
            </Paper>
            <div className="perguntas-buttons">
              <Button variant="contained" color="primary" className="perguntas-button" onClick={this.agendar}>
                {this.state.loadingC && <CircularProgress size={28} style={{ color: '#FFF', marginRight: '8px' }} />}Concluir
              </Button>
              <Button variant="contained" color="primary" className="perguntas-button" onClick={this.agendarImprimir}>
                {/* <Fab variant="extended" color="primary" aria-label="delete" onClick={this.getPadrao} style={{ margin: '10px', marginRight: '10px' }}>
                
                </Fab> */}
                {this.state.loading && <CircularProgress size={28} style={{ color: '#FFF', marginRight: '8px' }} />}Imprimir
              </Button>
              <CopyToClipboard
                text={`http://api.chronos.vallions.com.br/print/orientacao/${domain}/${hashids.encode(this.state.info_itens.map(s => s.it_ini))}`}>
                <Button variant="contained" color="primary" className="perguntas-button" onClick={this.handleCopiado}>Compartilhar Link</Button>
              </CopyToClipboard>
              <section style={{ alignSelf: 'center' }}>
                {this.state.copied ? <span style={{ color: '#03305d', paddingLeft: '8px' }}>Link Copiado</span> : null}
              </section>
            </div>
          </div>

        </Dialog >
      </div >
    );
  }

}

const ImageList = ({ imageArray, handleButtonClick }) => {
  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }}>
      {imageArray.map((item, index) => (
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
          }}
          key={index}>
          <img
            style={{
              width: '100%',
              height: '100%',
              marginBottom: '10px'
            }}
            src={item.path_image} alt={`Image ${index + 1}`} />
          <IconButton aria-label="delete" size="small" onClick={() => handleButtonClick(item)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      ))
      }
    </div >
  );
};

const ImageListFile = ({ imageArray, handleButtonClick }) => {
  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }}>
      {imageArray.map((item, index) => (
        <div
          style={{
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '20px',
          }}
          key={index}>
          <img
            style={{
              width: '100%',
              height: '100%',
              marginBottom: '10px'
            }}
            src={URL.createObjectURL(item)} alt={`Image ${index + 1}`} />
          <IconButton aria-label="delete" size="small" onClick={() => handleButtonClick(item)}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      ))
      }
    </div >
  );
};