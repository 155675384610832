import React, { Fragment } from 'react';
import './Atendimento.css';
import { Paper, Button, withStyles, Typography, IconButton, MenuItem, InputLabel, Select } from '@material-ui/core'
import Dialog_guias from './Dialog_guias';
import Dialog_med_setor from './Dialog_med_setor';
import Dialog_imprimir from './Dialog_imprimir';
import api from '../../services/api';
import Arrow from '@material-ui/icons/ArrowBack';
import Alert from '../Alertas/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertConcluse from '../Alertas/Concluse';
import Hashids from 'hashids';
import { format } from 'date-fns';

import InfoAgendamento from './InfoAgendamento/InfoAgendamento';
import InfoAtendimento from './InfoAtendimento/InfoAtendimento';
import Pedido from './Pedidos/Pedido';
import SocketContext from '../../services/SocketProvider';
import DialogAnexo from './DialogAnexo';
import Axios from 'axios';
import PacienteSimplificado from '../Cadastros/PacienteSimplificado';

class Atendimentos extends React.Component {
    constructor(props) {
        super(props);
        this.myRefInfoAtendimento = React.createRef();
        this.myRefInfoAgendamento = React.createRef();
        this.myRefPedido = React.createRef();
        const examesSelecionados = [...props.examesSelecinados];
        console.log(examesSelecionados)
        this.state = {
            sn_atendido: examesSelecionados[0].sn_atendido,
            cd_atendimento: examesSelecionados[0].cd_atendimento,
            exames: Object.assign(examesSelecionados),
            selectedSetorExec: [],

            examesAtendido: [],

            selectedConvenio: null,

            isDisabledPlano: true,
            isLoadingPlano: false,

            examesConvenioPlanos: [],
            examesConvenioPlano: null,

            convenio: { value: examesSelecionados[0].cd_convenio, label: `${examesSelecionados[0].cd_convenio} - ${examesSelecionados[0].nm_convenio}` },
            convenios: [],
            planosConvenios: [],
            planosConvenio: null,
            plano: { value: examesSelecionados[0].cd_con_pla, label: `${examesSelecionados[0].cd_con_pla} - ${examesSelecionados[0].ds_con_pla}` },

            selectEspecialidades: [],
            selectedEspecialidade: '',

            selectOrigems: [],
            selectOrigem: null,

            medSol: '',
            cdMedSol: '',
            crmMedSol: '',
            suggestionsMedSol: [],

            res_perguntas: [],
            perguntas: [],

            isLoadingExame: false,
            selectedExame: null,

            dialogGuia: false,
            indexGuia: null,

            guias: [],

            dialogMedSetor: false,

            medSolSetor: [],

            nm_paciente: examesSelecionados[0].nm_paciente,
            cd_paciente: examesSelecionados[0].cd_paciente,
            dt_nascimento: examesSelecionados[0].dt_nascimento,
            tp_sexo: examesSelecionados[0].tp_sexo,
            nm_mae: examesSelecionados[0].nm_mae,

            empresa: examesSelecionados[0].cd_multi_empresa,
            cd_unidade_atendimento: examesSelecionados[0].cd_unidade_atendimento,

            loading: false,
            selectSetorExec: [],
            queue: this.props.queue,
            alertConcluse: false,

            it_agenda: examesSelecionados.filter(e => e.cd_it_agenda_central != null || e.cd_it_agenda_central != undefined),

            dialogAnexo: false,

            dialogPaciente: false
        };
        this.save = this.save.bind(this);
        this.checkSave = this.checkSave.bind(this);
        this.getProbicoes();
        this.getExame(this.props.examesSelecinados[0].cd_convenio, this.props.examesSelecinados[0].cd_con_pla, this.props.examesSelecinados[0].cd_multi_empresa);
        this.getSetorExec();


    }
    /*----------------------------------------------------------------CICLOS DE VIDA------------------------------------------------------------------------ */
    getProbicoes = () => {
        api.post('/getProibicoesAtendimento', {
            itens: this.state.exames.map(e => e.cd_item_agendamento).join(),
            empresa: this.state.empresa,
            convenio: this.state.convenio.value,
            plano: this.state.plano.value,
        }).then(resp => {
            this.setState({ proibicoes: resp.data })
        })
    }
    getProbicoesExaRx = () => {
        api.post('/getProibicoesAtendimentoExaRx', {
            itens: this.state.exames.map(e => e.cd_exa_rx).join(),
            empresa: this.state.empresa,
            convenio: this.state.convenio.value,
            plano: this.state.plano.value,
        }).then(resp => {
            this.setState({ proibicoes: resp.data })
        })
    }
    /*------------------------------------------------------------------------------------------------------------------------------------------------------*/

    /*----------------------------------------------------------------CICLOS DE VIDA------------------------------------------------------------------------ */
    componentDidMount() {
    }
    /*------------------------------------------------------------------------------------------------------------------------------------------------------*/
    deletarPedido = async () => {
        const todos_pedidos = await this.state.examesAtendido.map(e => e.cd_ped_rx).filter((value, index, self) => self.indexOf(value) === index);
        for (let l = 0; l < todos_pedidos.length; l++) {
            const excluir_pedido = await this.state.exames.filter(e => e.delete === true).filter(e => e.cd_ped_rx == todos_pedidos[l])
            const total_pedidos = await this.state.exames.filter(e => e.cd_ped_rx == todos_pedidos[l])
            if (excluir_pedido.length === total_pedidos.length) {
                api.post('/deletePedido', {
                    pedido: todos_pedidos[l],
                    user: localStorage.getItem("login")
                })
            }
        }
    }
    update = async () => {
        const todos_pedidos = await this.state.examesAtendido.map(e => e.cd_ped_rx).filter((value, index, self) => self.indexOf(value) === index);
        const excluir = await this.state.exames.filter(e => e.delete === true).filter(e => e.cd_itped_rx != null)
        const insert = await this.state.exames.filter(e => e.insert === true)
        this.setState({ loading: true })
        var contInsert = 0;
        var contDelete = 0;
        for (let i = 0; i < insert.length; i++) {
            const exame = insert[i];
            const nr_guia = await this.state.guias.filter(g => g.cd_ped_rx == exame.cd_ped_rx).filter(g => g.exame_guia == exame.cd_exa_rx).map(g => g.numeroGuia)[i];
            const senha_guia = await this.state.guias.filter(g => g.cd_ped_rx == exame.cd_ped_rx).filter(g => g.exame_guia == exame.cd_exa_rx).map(g => g.senha)[i];
            const cd_guia = await this.state.guiasPedido.filter(g => g.cd_ped_rx == exame.cd_ped_rx).map(g => g.cd_guia);
            api.post('/insertItPedido', {
                convenio: this.state.selectedConvenio.value,
                atendimento: this.state.cd_atendimento,
                pedido: exame.cd_ped_rx,
                cd_guia: nr_guia == undefined ? cd_guia.join() : null,
                cd_exame: exame.cd_item_agendamento,
                nr_guia: nr_guia != undefined ? nr_guia : null,
                senha_guia: senha_guia != undefined ? senha_guia : null,
                paciente: this.state.cd_paciente
            }).then(async resp_it => {
                contInsert++;
                if (contDelete == excluir.length && contInsert == insert.length) {
                    this.deletarPedido();
                    if (this.state.impressao == true) {
                        const hashids = new Hashids();
                        var domain = localStorage.getItem('domain');
                        window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(this.state.cd_atendimento)}`, '_blank');
                    }

                    this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento alterado com sucesso ', loading: false });
                }
            })
        }
        for (let j = 0; j < excluir.length; j++) {
            const exame = excluir[j];
            api.post('/deleteItPedido', {
                it_pedido: exame.cd_itped_rx,
                pedido: exame.cd_ped_rx,
                user: localStorage.getItem("login")

            }).then(response => {
                contDelete++;
                if (contDelete == excluir.length && contInsert == insert.length) {
                    this.deletarPedido();
                    if (this.state.impressao == true) {
                        const hashids = new Hashids();
                        var domain = localStorage.getItem('domain');
                        window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(this.state.cd_atendimento)}`, '_blank');
                    }

                    this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento alterado com sucesso ' });
                }
            }).catch(error => {

            })
        }

        api.post('/updateConvenioPlanoAtendimento', {
            convenio: this.state.selectedConvenio.value,
            plano: this.state.selectedPlano.value,
            atendimento: this.state.cd_atendimento
        }).then(response => {
            if (contDelete == excluir.length && contInsert == insert.length) {
                if (this.state.impressao == true) {
                    const hashids = new Hashids();
                    var domain = localStorage.getItem('domain');
                    window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(this.state.cd_atendimento)}`, '_blank');
                }
                this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento alterado com sucesso ' });
            }
        }).catch(error => {
            console.log(error)
        })
    }
    getIdade(nascimento, hoje) {
        var diferencaAnos = hoje.getFullYear() - nascimento.getFullYear();
        if (new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()) <
            new Date(hoje.getFullYear(), nascimento.getMonth(), nascimento.getDate()))
            diferencaAnos--;
        return diferencaAnos;
    }
    save = async () => {
        let cont = 0;
        this.setState({ loading: true })
        api.post('/insertAtendimento', {
            paciente: this.state.cd_paciente,
            prestador: this.state.cdMedExec,
            ori_ate: this.state.selectOrigem,
            convenio: this.state.selectedConvenio.value,
            plano: this.state.selectedPlano.value,
            especialidade: this.state.selectedEspecialidade,
            tp_atendimento: 'E',
            nm_usuario: localStorage.getItem('login'),
            sn_retorno: 'N',
            cd_multi_empresa: this.state.empresa,
            nr_carteira: this.state.numeroCarteira,
            dt_validade: this.state.ValCarteira,
            it_agenda: this.props.examesSelecinados.filter(e => e.cd_it_agenda_central != null || e.cd_it_agenda_central != undefined).map(e => e.cd_it_agenda_central).join(),
        }).then(async response => {

            const pedidos = this.state.selectedSetorExec.map(s => s.value).filter((value, index, self) => self.indexOf(value) === index);
            for (var i = 0; i < pedidos.length; i++) {
                const exames_setor = await this.state.selectSetorExec.filter(s => s.cd_set_exa == pedidos[i]).map(s => s.exame);
                const it_pedidos = await this.state.exames.map(e => e.cd_item_agendamento).filter((value, index, self) => exames_setor.indexOf(value) >= 0);
                const cd_setor = await this.state.selectSetorExec.filter(s => s.cd_set_exa == pedidos[i]).map(s => s.cd_setor);
                const medSolicitante = await this.state.medSolSetor.filter(med => med.setorExec == pedidos[i]).map(med => med.cdMedSol)
                api.post('/insertPedido', {
                    prestador: medSolicitante.join(),
                    convenio: this.state.selectedConvenio.value,
                    plano: this.state.selectedPlano.value,
                    nm_usuario: localStorage.getItem('login'),
                    cd_multi_empresa: this.state.empresa,
                    atendimento: response.data,
                    cd_setor_exec: pedidos[i],
                    cd_setor: cd_setor[0],
                    nr_guia: this.state.numeroGuia,
                    cd_paciente: this.state.cd_paciente,
                    senha_guia: this.state.senha,
                }).then(async resp_ped => {

                    for (let j = 0; j < it_pedidos.length; j++) {
                        const nr_guia = await this.state.guias.filter(g => g.exame_guia == it_pedidos[j]).map(g => g.numeroGuia)[j];
                        const senha_guia = await this.state.guias.filter(g => g.exame_guia == it_pedidos[j]).map(g => g.senha)[j];
                        await api.post('/insertItPedido', {
                            convenio: this.state.selectedConvenio.value,
                            atendimento: response.data,
                            pedido: resp_ped.data.pedido,
                            cd_guia: nr_guia == undefined ? resp_ped.data.guia : null,
                            cd_exame: it_pedidos[j],
                            nr_guia: nr_guia != undefined ? nr_guia : null,
                            senha_guia: senha_guia != undefined ? senha_guia : null,
                            paciente: this.state.cd_paciente
                        }).then(async resp_it => {
                            cont++;
                            if (cont == this.state.exames.length) {
                                api.post('/finalizarAtendimento', {
                                    it_agenda: this.state.it_agenda.map(e => e.cd_it_agenda_central).join(),
                                    atendimento: response.data,
                                    cd_paciente: this.state.cd_paciente,
                                    nm_paciente: this.state.nm_paciente,
                                }).then(resp => {
                                    //Valida se unidade tem acesso pra poder enviar confirmação
                                    api.post('getUnitsConfirm', {
                                        unidade_atendimento: this.state.cd_unidade_atendimento
                                    }).then(respUnit => {
                                        if (respUnit.data.status_active != undefined && respUnit.data.status_active == "1") {

                                            api.post('/selectTelefoneConfirmacao', {
                                                paciente: this.props.examesSelecinados[0].cd_paciente
                                            }).then(responseTelefone => {
                                                if (responseTelefone.data.length > 0) {
                                                    var nr_ddd_fone = responseTelefone.data[0].nr_ddd_fone == null ? '43' : responseTelefone.data[0].nr_ddd_fone
                                                    var nr_fone = responseTelefone.data[0].nr_fone
                                                    var nr_ddd_celular = responseTelefone.data[0].nr_ddd_celular == null ? '43' : responseTelefone.data[0].nr_ddd_celular
                                                    var nr_celular = responseTelefone.data[0].nr_celular
                                                    var numero = '';
                                                    if (nr_celular == null && nr_fone != null) {
                                                        numero = nr_ddd_fone + nr_fone
                                                    }
                                                    if (nr_fone == null && nr_celular != null) {
                                                        numero = nr_ddd_celular + nr_celular
                                                    }
                                                    const timeElapsed = Date.now();
                                                    const today = new Date(timeElapsed);
                                                    const today_local = today.toLocaleDateString();
                                                    today_local.replace("/", "");
                                                    var dt_nascimento = new Date(this.state.dt_nascimento)
                                                    var idade = this.getIdade(dt_nascimento, new Date())
                                                    var url = `https://litz-estrategia.questionpro.com/gruposrarecepcao?custom1=${this.state.empresa}&custom2=${localStorage.getItem('login')}&custom3=${today_local.replaceAll('/', '')}&custom4=${idade}&custom5=${this.state.tp_sexo}&custom6=${this.state.selectedConvenio.value}`
                                                    Axios.post(`http://bot.gruposra.com.br:9095/Whatsapp/EnviarMensagem`, {}, {
                                                        params: {
                                                            contato: `55${numero}`,
                                                            url: url,
                                                            token: "eyJhbGciOiJIUzI1NiJ9.eyJJc3N1ZXIiOiJJc3N1ZXIiLCJleHAiOjE3MDI5MDY2MjMsImlhdCI6MTcwMjkwNjYyM30.gVjoK4jlUURsd34XBb5h6o3buI2neTNTDBZtr65Y2q4"
                                                        }
                                                    }).then(resp => {
                                                        api.post('logSendConfirmation', {
                                                            cd_atendimento: response.data,
                                                            cd_multi_empresa: this.state.cd_unidade_atendimento,
                                                            url: url,
                                                            phone: numero,
                                                            response: 'SUCCESS',
                                                        })
                                                        this.setState({ loading: false })
                                                        if (this.state.impressao == true) {
                                                            const hashids = new Hashids();
                                                            var domain = localStorage.getItem('domain');
                                                            window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(response.data)}`, '_blank');
                                                        }

                                                        this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento realizado com sucesso ' });
                                                    }).catch(error => {
                                                        api.post('logSendConfirmation', {
                                                            cd_atendimento: response.data,
                                                            cd_multi_empresa: this.state.cd_unidade_atendimento,
                                                            url: url,
                                                            phone: numero,
                                                            response: 'ERROR',
                                                        })
                                                        this.setState({ loading: false })
                                                        if (this.state.impressao == true) {
                                                            const hashids = new Hashids();
                                                            var domain = localStorage.getItem('domain');
                                                            window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(response.data)}`, '_blank');
                                                        }

                                                        this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento realizado com sucesso ' });
                                                    })
                                                } else {
                                                    api.post('logSendConfirmation', {
                                                        cd_atendimento: response.data,
                                                        cd_multi_empresa: this.state.cd_unidade_atendimento,
                                                        url: "SEM TELEFONE",
                                                        phone: numero,
                                                        response: 'ERROR',
                                                    })
                                                    this.setState({ loading: false })
                                                    if (this.state.impressao == true) {
                                                        const hashids = new Hashids();
                                                        var domain = localStorage.getItem('domain');
                                                        window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(response.data)}`, '_blank');
                                                    }

                                                    this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento realizado com sucesso ' });
                                                }
                                            })
                                        } else {
                                            this.setState({ loading: false })
                                            if (this.state.impressao == true) {
                                                const hashids = new Hashids();
                                                var domain = localStorage.getItem('domain');
                                                window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(response.data)}`, '_blank');
                                            }

                                            this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento realizado com sucesso ' });
                                        }
                                    }).catch(error => {
                                        this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: "Erro ao checar se empresa envia mensagem para o bot", loading: false })
                                        this.setState({ loading: false })
                                        if (this.state.impressao == true) {
                                            const hashids = new Hashids();
                                            var domain = localStorage.getItem('domain');
                                            window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(response.data)}`, '_blank');
                                        }

                                        this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Atendimento realizado com sucesso ' });
                                    })

                                })
                            }
                        })
                    }
                }).catch(error => console.log(error))
            }

        }).catch(error =>
            this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: error.response.data })
        )
        if (this.state.queue != undefined) {
            const it_agendas = this.state.it_agenda.map(e => e.cd_it_agenda_central);
            it_agendas.map(it_agenda => {
                api.post('/syncQueueItAgenda', {
                    queue: this.state.queue.idqueue,
                    it_agenda: it_agenda,
                    paciente: this.state.cd_paciente,
                    nm_paciente: this.state.nm_paciente,
                    paciente_queue: this.state.queue.id
                }).then(response => {
                    this.props.socket.emit('atualizaFlowCirculante')
                    this.props.socket.emit('atualizaFlowTecnico')
                    this.props.socket.emit('finalizacaoAtendimentoFila')
                }).catch(error => console.log(error))
            })
            this.handleClose()
            // api.post('/closeLineFlow', {
            //     idflow: this.props.flow,
            //     user: localStorage.getItem('login')
            // }).then(response => {
            //     api.post('/insertRecepcaoFlow', {
            //         empresa: this.props.unidade_atendimento,
            //         status: '0',
            //         user: localStorage.getItem('login'),
            //         queue: this.props.queue.idqueue,
            //     }).then(response => {
            //         this.props.socket.emit('atualizaFlowCirculante')
            //         this.props.socket.emit('atualizaFlowTecnico')
            //         this.handleClose()
            //     }).catch(error => console.log(error))
            // }).catch(error => {
            //     console.log(error)
            // })
            // api.post('/insertInitialFlow', {
            //     room: '4',
            //     status: '0',
            //     user: localStorage.getItem('login'),
            //     queue: this.state.queue[0].idqueue,
            // }).then(response => {
            // }).catch(error => console.log(error))
        }
    }
    compareDates = (date) => {
        let parts = date.split('/') // separa a data pelo caracter '/'
        let today = format(new Date(), 'dd/MM/yyyy')      // pega a data atual
        let parts2 = today.split('/') // separa a data pelo caracter '/'

        date = new Date(parts[2], parts[1] - 1, parts[0]) // formata 'date'
        var current = new Date(parts2[2], parts2[1] - 1, parts2[0])
        // compara se a data informada é maior que a data atual
        // e retorna true ou false
        return date < current ? true : false
    }
    checkValidacoesConvenio = async () => {
        var validadoGuia = false;
        const sn_guias = this.state.proibicoes.filter(p => p.sn_guia === "S")
        const sn_obriga_senhas = this.state.proibicoes.filter(p => p.sn_obriga_senha === "S")
        const sn_validade_carteira = this.state.proibicoes.filter(p => p.sn_validade_carteira === "S")
        if (new Date(`${this.state.ValCarteira} `) !== 'Invalid date' && this.state.ValCarteira !== "" && this.state.ValCarteira !== null) {
            const retornoValidacaoData = await this.compareDates(format(new Date(`${this.state.ValCarteira} `), 'dd/MM/yyyy'));
            if (sn_validade_carteira.length > 0 && retornoValidacaoData) {
                this.setState({ alert: true, alertTitle: 'Atenção', alertBody: ' Data de validade da carteira menor que data atual' });
                return true;
            }
        }
        if (sn_guias.length > 0 && this.state.numeroGuia !== "") {
            validadoGuia = true;
        }
        if (sn_obriga_senhas.length > 0 && this.state.senha !== "") {
            validadoGuia = true;
        }

        if (validadoGuia === false) {
            for (let index = 0; index < this.state.proibicoes.length; index++) {
                const proibicao = this.state.proibicoes[index];
                const guia = this.state.guias.filter(p => p.exame_guia === proibicao.cd_item)[0]
                if (guia != undefined) {
                    if (proibicao.sn_guia === 'S' && guia.numeroGuia === "") {
                        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: `Guia Obrigatoria no exame: ${proibicao.ds_item_agendamento}` });
                        return true;
                    }
                    if (proibicao.sn_obriga_senha === 'S' && guia.senha === "") {
                        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: `Senha Obrigatoria no exame: ${proibicao.ds_item_agendamento}` });
                        return true;
                    }
                } else {
                    if (proibicao.sn_guia === 'S') {
                        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: `Guia Obrigatoria no exame: ${proibicao.ds_item_agendamento}` });
                        return true;
                    }
                    if (proibicao.sn_obriga_senha === 'S') {
                        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: `Senha Obrigatoria no exame: ${proibicao.ds_item_agendamento}` });
                        return true;
                    }
                }
            }
        }
    }
    checkSave = async (impressao) => {
        this.setState({ impressao: impressao })
        const resp_agendamento = await this.myRefInfoAgendamento.current.retornarStates();
        console.log(resp_agendamento)
        const resp_atendimento = await this.myRefInfoAtendimento.current.retornarStates();
        this.setState(prevState => { return { ...prevState, ...resp_agendamento, ...resp_atendimento } })
        const validador = await this.checkValidacoesConvenio();
        if (this.state.examesAtendido.filter(exame => exame.sn_realizado == 'S').length > 0) {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Exame já confirmado como realizado, não é possível efetuar qualquer alteração. Caso haja necessidade de modificar informações, será preciso desconfirmar o exame.' });
            return;
        }
        if (validador != true) {
            if (this.state.sn_atendido == 'N') {
                const qtdExamesDistintos = this.state.exames.map(e => e.cd_item_agendamento).filter((value, index, self) => self.indexOf(value) === index)
                if (this.state.cd_paciente === null) {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Cadastre o paciente' });
                    return;
                }
                if (this.state.selectOrigem === null) {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha a origem' });
                    return;
                }
                if (this.state.cdMedExec == null || this.state.cdMedExec == '' || this.state.cdMedExec == undefined) {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o Médico Executante ' });
                    return;
                }
                if (this.state.selectedEspecialidade == null || this.state.selectedEspecialidade == '') {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha a especialidade' });
                    return;
                }
                if (this.state.dt_nascimento == null || this.state.dt_nascimento == '') {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha a Data de Nascimento do Paciente' });
                    return;
                }
                if (this.state.nm_mae == null || this.state.nm_mae == '') {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o nome da mãe do Paciente' });
                    return;
                }
                // if (this.state.fone == null || this.state.fone == '') {
                //     this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o telefone do Paciente' });
                //     return;
                // }
                // if (this.state.perguntas.length == 0 && this.state.res_perguntas.length == this.state.perguntas.length) {
                // } else {
                //     this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha as perguntas' });
                //     return;
                // }
                if (this.state.selectedSetorExec.length == qtdExamesDistintos.length) {
                    this.setState({ dialogMedSetor: true })
                } else {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Selecione o setor executante' });
                    return;
                }
            } else if (this.state.sn_atendido == 'S') {
                this.update();
            }
        }
    }
    setMedSol = medSol => {
        this.setState({ medSolSetor: medSol, dialogMedSetor: false })
        this.save();
    }

    getExame = (convenio, plano, cd_empresa) => {
        console.log(convenio)
        this.setState({ isLoadingExame: true, convenio, plano })
        api.post('/exames', {
            cd_convenio: convenio.value,
            cd_plano: plano.value,
            cd_empresa: cd_empresa
        }).then(response => {
            this.setState({ exame: response.data, isLoadingExame: false })
            // this.myRefPedido.current.setExameSelect(response.data.map(e => { e.tp_convenio = convenio.tp_convenio; return e }));
            this.state.sn_atendido == 'S' ? this.getProbicoesExaRx() : this.getProbicoes();
        })
    }
    /*--------------------------------------------------------SETOR---------------------------------------------------------------- */
    getSetorExec = (exames) => {
        if (exames != null) {
            exames.map((e) => {
                api.post('/setorExecutante', {
                    exame: e.cd_item_agendamento,
                    empresa: this.state.empresa
                }).then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        response.data[i].exame = e.cd_item_agendamento
                    }
                    if (this.state.selectSetorExec != undefined && this.state.selectSetorExec.length > 0) {
                        const result = this.state.selectSetorExec.filter(set => set.exame != e.cd_item_agendamento).concat(response.data);
                        this.setState({ selectSetorExec: result })
                    }
                    else {
                        this.setState({ selectSetorExec: response.data })
                    }
                }).catch(error => {
                    console.log(error)
                })
            })
        } else {
            this.state.exames.map((e) => {
                api.post('/setorExecutante', {
                    exame: e.cd_item_agendamento,
                    empresa: e.cd_multi_empresa
                }).then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        response.data[i].exame = e.cd_item_agendamento
                    }
                    if (this.state.selectSetorExec != undefined && this.state.selectSetorExec.length > 0) {
                        const result = this.state.selectSetorExec.filter(set => set.exame != e.cd_item_agendamento).concat(response.data);
                        this.setState({ selectSetorExec: result })
                    }
                    else {
                        this.setState({ selectSetorExec: response.data })
                    }
                }).catch(error => {
                    console.log(error)
                })
            })

        }

    }
    setSetorExec = (setorExec) => {
        this.setState({ selectedSetorExec: setorExec })
    }
    /*---------------------------------------------------------------------------------------------------------------------------------*/

    handleClickOpenGuia = (index, exame) => {
        this.setState({ dialogGuia: true, indexGuia: index, exame_guia: exame })
    }
    handleClickOpenGuiaAtendido = (index, exame, pedido, cd_itped_rx) => {
        this.setState({ dialogGuia: true, indexGuia: index, exame_guia: exame, pedido: pedido, cd_itped_rx: cd_itped_rx })
    }
    handleClickCloseGuia = () => {
        this.setState({ dialogGuia: false })
    }
    handleClickCloseMedSetor = () => {
        this.setState({ dialogMedSetor: false })
    }
    /*--------------------------------------------------------PERGUNTAS---------------------------------------------------------------- */
    novoValor = (res, resp, p_exame) => { return Object.assign(res, { "resposta": resp, "it_ini": this.state.exames.filter(s => s.cd_item_agendamento === p_exame).map(s => s.cd_it_agenda_central).join() }) };

    handleRadio = (e, resp, valor) => {
        this.state.perguntas.forEach((element, index) => {
            if (element.cd_pergunta === valor) {
                var resultado = this.state.perguntas.filter(res => res.cd_pergunta === valor).map(res => this.novoValor(res, resp, res.cd_item_agendamento))
                for (let i = 0; i < this.state.res_perguntas.length; i++) {
                    if (this.state.res_perguntas[i].cd_pergunta === valor) {
                        this.state.res_perguntas.splice(i, 1);
                        this.setState({ res_perguntas: this.state.res_perguntas.concat(resultado) })
                    }
                }
                this.setState({ res_perguntas: this.state.res_perguntas.concat(resultado) })
            }
        });
    }
    getPerguntas = async (item_agenda) => {
        const response = await api.post('/perguntasAtendimento', {
            cd_item_agenda: item_agenda,
        })
        const result = Array.from(new Set(response.data.map(s => s.cd_pergunta))).map(cd => {
            return {
                cd_pergunta: cd,
                ds_pergunta: response.data.find(s => s.cd_pergunta === cd).ds_pergunta
            }
        })
        response.data.length > 0 && this.setState({ perguntas: response.data, statusPerguntas: true, filtro_pergunta: result })

    }
    setPerguntas = (perguntas) => perguntas.map(pergunta => {
        return <section key={pergunta.cd_pergunta}>
            <div className="perguntas-colPerguntas">
                <section className="perguntas-perguntas">
                    <div>{pergunta.ds_pergunta}</div>
                </section>
                <section className="perguntas-respostas">
                    <div className="perguntas-respostas">
                        <div className="perguntas-resposta">
                            <label htmlFor={pergunta.cd_pergunta}>SIM</label>
                            <input type="radio" name={pergunta.cd_pergunta} onChange={e => this.handleRadio(e, 'S', pergunta.cd_pergunta)} id={pergunta.cd_pergunta} />
                        </div>
                        <div className="perguntas-resposta">
                            <label htmlFor={pergunta.cd_pergunta + "N"}>NÃO</label>
                            <input type="radio" name={pergunta.cd_pergunta} onChange={e => this.handleRadio(e, 'N', pergunta.cd_pergunta)} id={pergunta.cd_pergunta + "N"} />
                        </div>
                    </div>
                </section>
            </div>
        </section>
    });
    /*---------------------------------------------------------------------------------------------------------------------------------*/
    insertGuiasEspecificasExames = (guia) => {
        this.setState({ guias: this.state.guias.filter(g => g.indexGuia != guia.indexGuia).concat(guia), dialogGuia: false })
    }
    insertGuiasEspecificasExamesAtendido = (guia) => {
        this.setState({ guias: this.state.guias.filter(g => g.cd_ped_rx != guia.cd_ped_rx).filter(g => g.indexGuia != guia.indexGuia).concat(guia), dialogGuia: false })
    }
    removeExame = (index) => {
        const newMapExame = this.state.exames
    }
    /*-------------------------------------------------------------------------------Alert------------------------------------------------------- */
    handleCloseAlert = () => {
        this.setState({ alert: false });
    };
    /**------------------------------------------------------------------------------------------------------------------------------------------ */
    consultaState = () => {
        const resp_agendamento = this.myRefInfoAgendamento.current.retornarStates();
        const resp_atendimento = this.myRefInfoAtendimento.current.retornarStates();
        this.setState(prevState => { return { ...prevState, ...resp_agendamento, ...resp_atendimento } })
    }
    handleConcluseAlert = () => {
        if (this.props.queue != undefined) {
            this.props.closeQueue();
        } else {
            this.props.close()
        }
    };
    /*---------------------------------------------------------------------------------------------------------------------------------------------- */
    setAddExame = async (exame) => {
        await this.setState({ exames: exame })
        this.state.sn_atendido == 'S' ? this.getProbicoesExaRx() : this.getProbicoes();
    }

    teste = async () => {
        const resp_agendamento = await this.myRefInfoAgendamento.current.retornarStates();
        this.setState(prevState => { return { ...prevState, ...resp_agendamento } })
    }
    setExamesAtendido = (exames) => {
        this.setState({ examesAtendido: exames })
    }
    savePacienteSimplificao = (nm_paciente, dt_nascimento, dddFone, telefone, dddCelular, celular) => {
        this.setState({ nm_paciente, dt_nascimento, dddFone, telefone, dddCelular, celular, dialogPaciente: false })
    }

    render() {
        const { state, open, setOpen } = this.state;
        const { classes } = this.props;
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                {this.state.alertConcluse && <AlertConcluse title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleConcluseAlert} />}
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                {this.state.dialogGuia && <Dialog_guias close={this.handleClickCloseGuia} exame_guia={this.state.exame_guia} guias={this.state.guias} cd_itped_rx={this.state.cd_itped_rx} indexGuia={this.state.indexGuia} pedido={this.state.pedido} guiasEspecificas={this.insertGuiasEspecificasExames} guiasEspecificasAtendido={this.insertGuiasEspecificasExamesAtendido} />}
                {this.state.dialogMedSetor && <Dialog_med_setor close={this.handleClickCloseMedSetor} setorExec={this.state.selectedSetorExec} setor_name={[...new Set(this.state.selectedSetorExec.map(item => item.nm_set_exa))]} setMedSol={this.setMedSol} />}
                {this.state.dialogAnexo && <DialogAnexo handleClose={() => this.setState({ dialogAnexo: false })} handleSave={this.handleSaveAnexo} exames={this.props.examesSelecinados} />}
                {/* {this.state.dialogPaciente && <PacienteSimplificado paciente={this.state.cd_paciente} savePacienteSimplificao={this.savePacienteSimplificao} />} */}
                <Paper style={{ width: '100%', borderRadius: '5px', padding: '15px', boxShadow: '1px 2px 1px #ddd' }}>
                    <div className="buttonTimer">
                        <IconButton variant="contained" color="primary" onClick={this.props.close}>
                            <Arrow />
                        </IconButton>
                    </div>
                    <div>
                        <InfoAgendamento ref={this.myRefInfoAgendamento} cd_atendimento={this.state.cd_atendimento} paciente={{ nm_paciente: this.state.nm_paciente, cd_paciente: this.state.cd_paciente, dt_nascimento: this.state.dt_nascimento, tp_sexo: this.state.tp_sexo }} convenio={this.state.convenio} plano={this.state.plano} empresa={this.state.empresa} getExame={this.getExame} atendido={this.state.sn_atendido == 'S'} />
                        <InfoAtendimento ref={this.myRefInfoAtendimento} cd_atendimento={this.state.cd_atendimento} empresa={this.state.empresa} exames={this.props.examesSelecinados} />
                        <Pedido ref={this.myRefPedido} sn_atendido={this.state.sn_atendido} cd_atendimento={this.state.cd_atendimento} convenio={this.state.convenio} plano={this.state.plano} empresa={this.state.empresa} exames={this.props.examesSelecinados} getPerguntas={this.getPerguntas} handleClickOpenGuia={this.handleClickOpenGuia} handleClickOpenGuiaAtendido={this.handleClickOpenGuiaAtendido} setorExec={this.setSetorExec} setAddExame={this.setAddExame} getSetorExec={this.getSetorExec} setExamesAtendido={this.setExamesAtendido} />
                        {this.state.statusPerguntas && <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', border: '1px solid #ddd', margin: '20px', boxShadow: '1px 2px 1px #ddd', padding: '10px', borderRadius: '5px' }}>
                            <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold', backgroundColor: '#023575', color: '#fff', borderRadius: '5px' }} variant="subtitle1" gutterBottom>PERGUNTAS</Typography>
                            <div>
                                {this.setPerguntas(this.state.filtro_pergunta)}
                            </div>
                        </div>}
                        <div className="button">
                            <Button style={{ backgroundColor: '#ff5656', color: '#fff', padding: '10px', borderRadius: '5px', alignItems: 'center', marginRight: '5px' }} onClick={() => this.setState({ dialogAnexo: true })}>
                                {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />} ANEXO</Button>
                            <Button style={{ backgroundColor: '#ff5656', color: '#fff', padding: '10px', borderRadius: '5px', alignItems: 'center', marginRight: '5px' }} onClick={() => this.checkSave(false)}>
                                {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />} SALVAR ATENDIMENTO</Button>
                            <Button style={{ backgroundColor: '#ff5656', color: '#fff', padding: '10px', borderRadius: '5px', alignItems: 'center' }} onClick={() => this.checkSave(true)}>
                                {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />} IMPRIMIR</Button>
                        </div>

                    </div>

                </Paper>
            </div >
        );
    }
}

const AtendimentoWithSocket = (props) => (
    <SocketContext.Consumer >
        {socket => <Atendimentos {...props} socket={socket} />}
    </SocketContext.Consumer >
)


export default (AtendimentoWithSocket);