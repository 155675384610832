import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from './services/api'

export default function ScrollDialog() {
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = scrollType => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    api.post('/updateAtualizacao', {
      login: localStorage.getItem('login'),
    })
      .then((response) => {
        localStorage.setItem('s_a', 0)
        setOpen(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const url = "http://api.chronos.vallions.com.br/api/img/atualizacao/"
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        classes={{ root: { zIndex: '-1' } }}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='md'
        scroll='body'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Atualização do Sistema</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
          // tabIndex={-1}
          >
            <p>Olá, como tivemos muitos pedidos, e pra facilitar no atendimento do paciente, agora temos no Chronos o cadastro do paciente, quando paciente não tiver cadastro, você poderá cadastrá-lo, e facilitará o atendimento do paciente, pois o ID do paciente estará cadastrado. </p>
            <p>Será muito fácil e prático cadastrar o paciente, clicando no ícone do nome do paciente, com apenas alguns dados seu paciente esta cadastrado. </p>
            <img width="100%" src={url + "cadastroPaciente1.png"}></img>
            <img width="100%" src={url + "cadastroPaciente2.png"}></img>

            {/* <p>Atenção! Você está a um passo de ter mais praticidade e menos dor de cabeça, porque cá entre nós, ninguém merece trabalhar em um sistema que falha o tempo todo, e mesmo que não falhe, existem zilhões de botões e telas que só te fazem cansar os dedos.
            Você já ouviu aquela frase “ Ter uma melhora constante é melhor do que  a perfeição adiada”? Então, nosso lindo Chronos não nasceu perfeito   mas estamos trabalhando duro para que ele possa atender todas as suas necessidades.
            </p>
            <h2>Atualizações da Tela Agendados</h2>

            <h4 style={{ color: 'blue' }}>Tela de Encaixe</h4>
            <p>●	<span style={{ color: 'blue' }}>O encaixe foi perfeito, a sintonia surreal</span> Ali no cantinho inferior direito da tela Agendados tem um botão de encaixe. Para realizar o encaixe é necessário que seja informado a Unidade e o Recurso na tela dos agendados, pois está vinculado ao código específico que determina a agenda. Agora você não precisa mais sair do Chronos para realizar os encaixes.</p>
            <img src={url + "encaixe.png"}></img>
            <h4 style={{ color: 'blue' }}>Layout Tela Agendados</h4>
            <p>●	Menos “frufru” e mais praticidade, mudamos o layout da Tela Agendados.</p>
            <h6 style={{ color: 'red' }}>Antes</h6>
            <img width="100%" src={url + "agendados1.png"}></img>
            <h6 style={{ color: 'red' }}>Agora</h6>
            <img width="100%" src={url + "agendados2.png"}></img>
            <h4 style={{ color: 'blue' }}>Layout botões Desmarcar, Remarcar e Imprimir</h4>
            <p>A tela era colorida, botões grandes e para editar o paciente abria uma segunda tela.</p>
            <h6 style={{ color: 'red' }}>Antes</h6>
            <img width="100%" src={url + "remarcar1.png"}></img>
            <h6 style={{ color: 'red' }}>Agora</h6>
            <p>Quando você selecionar um paciente no botão Actions os botões desmarcar, remarcar e imprimir, irão aparecer no canto superior direito. Para editar o nome do paciente você clica no lápis ok? </p>
            <img width="100%" src={url + "remarcar2.png"}></img>
            <h4 style={{ color: 'blue' }}>Botão editar paciente</h4>
            <p>se perceber que o nome do paciente está errado, clique no lápis que se encontra à esquerda do nome do paciente, forneça os dados do nome correto e clique no botão de confirmar</p>
            <h6 style={{ color: 'red' }}>Antes</h6>
            <img src={url + "alterarPaciente1.png"}></img>
            <h6 style={{ color: 'red' }}>Agora</h6>
            <img src={url + "alterarPaciente2.png"}></img>
            <h2>Atualizações da Tela Agendas</h2>
            <h4 style={{ color: 'blue' }} >Informação data de nascimento</h4>
            <p>●	Quando você for buscar por um cadastro, o auto suggest não mostrará mais o nome da mãe do paciente e sim a data de nascimento do paciente e telefone, também será possível buscar o cadastro pelo número de telefone do paciente.</p>
            <h6 style={{ color: 'red' }}>Antes</h6>
            <img src={url + "DataNasc1.png"}></img>
            <h6 style={{ color: 'red' }}>Agora</h6>
            <img src={url + "DataNasc2.png"}></img>
            <h4 style={{ color: 'blue' }}>Campo Telefone</h4>
            <p>Agora você pode buscar o cadastro do paciente também pelo número do telefone.</p>
            <img src={url + "telefone.png"}></img>
            <h4 style={{ color: 'blue' }}>Pesquisa de horário disponíveis</h4>
            <p>●	Jardim Keukenhof, conhecido como o Jardim da Europa, é o maior jardim do mundo! Com a atualização do campo de visão da agenda, você pode conferir se tem horários  disponíveis para até 2 meses, você pode até achar que estará no Keukenhof, mais não se empolgue, é só a maravilha de 
conseguir ver 2 meses de agenda com apenas 2 cliques, busca e setinha para o lado!
</p>
            <img width="100%" src={url + "exames.png"}></img>
            <h4 style={{ color: 'blue' }}>Exame Adicional/ Segmento Adicional</h4>
            <p>●	Quanto mais melhor! Nada de perder $$ na hora de agendar os exames, adicione quantos segmentos precisar, </p>
            <h6 style={{ color: 'red' }}>Antes</h6>
            <img width="100%" src={url + "adicional1.png"}></img>
            <h6 style={{ color: 'red' }}>Agora</h6>
            <img width="100%" src={url + "adicional2.png"}></img>

            <h2>Tela de Alertas</h2>
            
            <h4 style={{ color: 'blue' }}>Mudar de tela sem finalizar um atendimento</h4>
            <p>●	Ahh e por último mas não menos importante, colocamos uma tela de Alerta, para caso você saia da tela de agendamento acidentalmente irá emitir esse alerta:</p>
            <img src={url + "alerta.png"}></img> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
