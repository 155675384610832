import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Select from '@material-ui/core/Select';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select, { components } from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import SelectUi from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import api from '../../services/api'
import { green } from '@material-ui/core/colors';

import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Paper from '@material-ui/core/Paper';
import deburr from 'lodash/deburr'

import Alert from '../Alertas/Alert';
import Confirm from '../Alertas/Confirm';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import Cadastro from '../CadastroPaciente';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';

import './RecepcaoEncaixe.css';
const style = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing.unit,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        width: '1000px',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',

    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    dialogPaper: {
        minHeight: '380px',
        maxHeight: '380px',
    },
    dialogTextPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        maxWidth: 150,
    },
    root: {
        marginLeft: '16px'
    },
    rootSelect: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    rootSelectExames: {
        margin: theme.spacing(1),
        width: '100%'
    },
    rootSelectInput: {
        margin: theme.spacing(1),
        width: 195
    },
    inputAuto: {
        marginTop: '4px',
        width: '360px'
        // [theme.breakpoints.up('md')]: {
        //     marginTop: '8px',
        //     width: '360px'
        // },
        // [theme.breakpoints.down('md')]: {
        //     marginTop: '8px',
        //     width: '195px',
        // }
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignSelf: 'flex-end',
    },
    inputText: {
        margin: theme.spacing(1),
        width: '166px',
    },
    inputHora: {
        margin: theme.spacing(1),
        width: '150px',
    },
    inputDate: {
        // margin: theme.spacing(1),
        width: '150px'
    },
    divForm: {
        marginTop: '16px',
    },
    divAutoSuggest: {
        display: 'flex'
    },
    inputSelect: {
        margin: theme.spacing(1),
        width: '250px',
    },
    inputSelectExames: {
        width: '98%',
    }
})


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TextCpfMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
TextCpfMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};
const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        padding: 15,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid red',
        borderRadius: 'none',
        boxShadow: 'none',
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: 'none',
            boxShadow: 'none'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    })
};

const ajusteDate = (data) => {
    var d = new Date(data);
    d.setDate(d.getDate() + 1);
    return d
}
const dataAgendaInput = data => {
    var data_agenda = new Date(data)
    return format(ajusteDate(new Date(data)), "yyyy-MM-dd")
}
const dataAgenda = data => {
    var data_agenda = new Date(data)
    return format(new Date(data), "yyyy-MM-dd")
}
class Encaixe extends React.Component {
    constructor(props) {
        super(props);
        this.selectCdAgenda(this.props.data);
        this.searchConvenio(this.props.unidade.value);
        this.myRefConfirm = React.createRef();
    }
    componentDidMount() {
        this.setState({ isLoadingConvenio: true })
    }
    state = {
        nome: this.props.inputName,
        valueNome: '',
        open: true,
        perguntas: null,
        dtNasc: new Date(),
        telefone: '',
        valueCpf: '',
        stateSuggestions: [],
        stateSuggestionsTel: [],
        isLoadingConvenio: false,
        isDisabledConvenio: true,
        isDisabledPlano: true,
        isDisabledExame: true,
        isLoadingExame: false,
        cd_empresa: this.props.unidade.value,
        cd_setor: this.props.setor.value,
        unidade: this.props.unidade.label,
        setor: this.props.setor.label,
        dataInput: this.props.dataInput,
        data: moment(this.props.dataInput).format("YYYY-MM-DD"),
        isLoadingPlano: false,
        cd_agenda: '',
        selectedPlano: [],
        selectedConvenio: [],
        selectedExame: [],
        inputHora: '08:30',
        alert: false,
        alertTitle: null,
        alertBody: null,
        booCpfName: false,
        booPesquisaTelefone: false,
        cadastroPaciente: false,
        cd_paciente: null,
        hr_inicio: null,
        hr_fim: null,
        hr_horario: null,
        multi_agenda: false,
        agendas: [],
        prestador: null,
        confirm: false,
        confirmTitle: null,
        confirmBody: null,
    };

    handleCloseAlert = () => {
        this.setState({ alert: false });
    };

    onChangeData = e => {
        this.setState({ dtNasc: e });
    };
    onChangeDataAg = e => {
        // this.setState({ dataInput: dataAgendaInput(e), data: dataAgenda(e), prestador: '', cd_agenda: null });
        this.setState({ dataInput: e, data: dataAgenda(e), prestador: '', cd_agenda: null });
        this.selectCdAgenda(dataAgenda(e))
    };
    closePergunta = () => {
        this.setState({ perguntas: false })
    }
    selectCdAgenda = data => {
        api.post('/consultarCdAgenda', {
            cd_empresa: this.state.cd_empresa,
            cd_setor: this.state.cd_setor,
            data: data === undefined ? this.state.data : data
        }).then(response => {
            if (response.data[0] != undefined) {
                if (response.data.length > 1) {
                    this.setState({ agendas: response.data, multi_agenda: true })
                } else {
                    var horarios = response.data[0]
                    api.post('/consultarQtEncaixe', {
                        cd_agenda: response.data[0].cd_agenda_central,
                    }).then(response => {
                        if (response.data[0].encaixe == 'S' || response.data[0].sn_permite_encaixe_extra == 'S') {
                            this.setState({ cd_agenda: response.data[0].cd_agenda_central, prestador: horarios.nm_prestador, hr_horario: horarios.horario, hr_inicio: horarios.hr_inicio, hr_fim: horarios.hr_fim, inputHora: horarios.hr_inicio })
                        } else {
                            this.setState({ alert: true, alertTitle: 'Atenção:', alertBody: 'Excedida quantidade maxima de encaixe! ' });
                        }
                    })
                }
            } else {
                this.setState({ cd_agenda: '', prestador: '' })
            }
        }).catch(error => {
            this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
        });
        // this.save();
    }
    save = () => {
        // api.post('/atualizaQtEncaixe', {
        //     cd_agenda_central: this.state.cd_agenda,
        // }).then(response => {
        api.post('/consultarQtEncaixe', {
            cd_agenda: this.state.cd_agenda,
        }).then(response => {
            if (response.data[0].encaixe == 'S' || response.data[0].sn_permite_encaixe_extra == 'S') {
                api.post('/insertEncaixe', {
                    cd_agenda_central: this.state.cd_agenda,
                    nm_paciente: this.state.valueNome,
                    hr_agenda: this.state.data + " " + this.state.inputHora,
                    sn_encaixe: 'S',
                    cd_item_agendamento: this.state.selectedExame.value,
                    cd_paciente: this.state.cd_paciente,
                    cd_convenio: this.state.selectedConvenio.value,
                    cd_con_pla: this.state.selectedPlano.value,
                    nr_ddd_fone: this.state.telefone.substr(0, 2),
                    nr_fone: this.state.telefone,
                    cd_usuario: localStorage.getItem('login'),
                })
                    .then((response) => {
                        this.setState({ confirm: false, alert: true, alertTitle: 'Atenção', alertBody: 'Paciente inserido com sucesso.', loading: false });
                        this.props.reload();
                    }).catch(error => {
                        this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
                        this.myRefConfirm.current.alterLoading(false)
                    });
            } else {
                this.myRefConfirm.current.alterLoading(false)
                this.setState({ alert: true, alertTitle: 'Atenção:', alertBody: 'Outro colaborador inseriu o último encaixe ;( Quantidade máxima excedida', cd_agenda: null, prestador: '', hr_horario: null, hr_fim: null, hr_inicio: null, loading: false });
            }
        })
        // })
    }
    click = () => {
        this.setState({ loading: true })
        var horaInicio = moment(this.state.data + " " + this.state.hr_inicio).format('HH:mm');
        var horaFim = moment(this.state.data + " " + this.state.hr_fim).format('HH:mm');
        var horaSelecionado = moment(this.state.data + " " + this.state.inputHora).format('HH:mm');

        if (this.state.cd_agenda == null || this.state.cd_agenda == '') {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Por favor selecione uma combinação de Data, Unidade e Recurso valida.', loading: false });
        } else if (this.state.valueNome.length <= 0) {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o nome do paciente.', loading: false });
        } else if (this.state.telefone.length <= 0) {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o telefone do paciente.', loading: false });
        } else if (this.state.selectedExame.value === undefined) {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Por favor selecione um exame.', loading: false });
        } else if (this.state.inputHora === '') {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Por favor preencha a hora.', loading: false });
        } else if (horaSelecionado < horaInicio || horaSelecionado > horaFim) {
            this.setState({ confirm: true, confirmTitle: "Atenção", confirmBody: "A hora está fora do horário da agenda, deseja prosseguir ?" });
        } else {
            this.save();
        }
    }
    searchConvenio = cd_empresa => {
        api.post('/convenio', {
            cd_empresa: cd_empresa
        })
            .then((response) =>
                this.setState({ convenio: response.data, isLoadingConvenio: false, isDisabledConvenio: false }),
            )
            .catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
    }
    handleChangeConvenio = selectedConvenio => {
        this.setState({ selectedConvenio, selectedPlano: null, isLoadingPlano: true });
        this.searchPlano(selectedConvenio.value)
    };
    searchPlano = cd_convenio => {
        api.post('/planosConvenio', {
            cd_convenio: cd_convenio,
            empresa: this.state.cd_empresa
        })
            .then((response) =>
                this.setState({ plano: response.data, isLoadingPlano: false, isDisabledPlano: false }),
            )
            .catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
    }
    handleChangePlano = selectedPlano => {
        this.setState({ selectedPlano, isLoadingExame: true });
        this.searchExame(this.state.selectedConvenio.value, selectedPlano.value, this.state.cd_empresa)

    };
    searchExame = (cd_convenio, cd_plano, cd_empresa) => {
        if (this.state.cd_agenda == null || this.state.cd_agenda == '') {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Por favor selecione uma agenda válida.', loading: false });
        } else {
            api.post('/examesAgenda', {
                agenda: this.state.cd_agenda
            })
                .then((response) =>
                    this.setState({ exame: response.data, isLoadingExame: false, isDisabledExame: false })
                )
                .catch(error => {
                    this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
                });
        }
    }
    handleChangeExame = selectedExame => {
        this.setState({ selectedExame: selectedExame });
    };
    handleCpf = name => event => {
        this.setState({ [name]: event.target.value });
        if (this.state.booCpfName == true && event.target.value.length <= 11) {
            this.setState({ valueNome: '', booCpfName: false, dtNasc: null, telefone: '' })
        }
        if (event.target.value.length == 11) {
            this.searchCpf(event.target.value)
        }
    }
    searchCpf = cpfValue => {
        api.post('/searchT1', {
            cpf: cpfValue
        })
            .then(response => {
                if (response.data.length > 0 && this.state.valueNome == '') {
                    this.setState({ valueNome: response.data[0].nm_paciente, booCpfName: true, dtNasc: ajusteDate(response.data[0].dt_nascimento), telefone: response.data[0].celular, cd_paciente: response.data[0].cd_paciente })
                }
            })
            .catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
    }
    /*----------------------------- AUTO SUGE------------------------- */

    searchTelefone = telefoneValue => {
        if (telefoneValue.length > 3) {
            api.post('/searchT3', {
                celular: telefoneValue
            })
                .then(response => {
                    this.setState({ stateSuggestionsTel: response.data })
                })
                .catch(error => {
                    this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
                });
        }

    }
    searchPacient = (nameValue) => {
        if (nameValue.length > 3) {
            api.post('/searchNome', {
                nome: nameValue
            })
                .then((response) => {
                    this.setState({ stateSuggestions: response.data })
                })
                .catch(error => {
                    this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
                });
        }

    }
    renderInputComponent = (inputProps) => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <FormControl className={classes.inputAuto}>
                <InputLabel htmlFor="standard-adornment-password">
                    Nome
                </InputLabel>
                <Input
                    id="standard-adornment-password"
                    value={this.state.valueNome}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                    }}
                    {...other}
                    // onChange={handleChange("valueName")}
                    endAdornment={
                        < InputAdornment position="end" >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => this.setState({ cadastroPaciente: true })}
                            >
                                <PersonAddIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl >
        );
    }
    renderInputComponentTel = inputProps => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <TextField
                fullWidth
                InputProps={{
                    inputRef: node => {
                        ref(node);
                        inputRef(node);
                    },
                    classes: {
                        input: classes.input,
                    },
                    value: this.state.telefone
                }}
                {...other}
            />
        );
    }
    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const mae = suggestion.nm_mae == null ? " NÃO CADASTRADO" : suggestion.nm_mae;
        const celular = suggestion.celular == null ? " NÃO CADASTRADO" : suggestion.celular;
        const dt_nasc = suggestion.dt_nascimento == null ? " NÃO CADASTRADO" : suggestion.dt_nascimento;
        const matches = match(suggestion.nm_paciente, query);
        const parts = parse(suggestion.nm_paciente + "|Dt. Nasc: " + (suggestion.dt_nascimento == null || suggestion.dt_nascimento == undefined ? 'NÃO CADASTRADO' : format(ajusteDate(new Date(dt_nasc)), "dd/MM/yyyy")), matches);

        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map(part => (
                        <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                            {part.text}
                        </span>
                    ))}
                </div>
            </MenuItem>
        );
    }
    getSuggestions(value) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;
        return inputLength === 0
            ? []
            : this.state.stateSuggestions.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.nm_paciente.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }
                return keep;
            });
    }
    getSuggestionValue = (suggestion) => {
        this.setState({ cd_paciente: suggestion.cd_paciente, valueNome: suggestion.nm_paciente, telefone: suggestion.celular != null ? suggestion.celular : '', dtNasc: ajusteDate(suggestion.dt_nascimento), valueCpf: suggestion.nr_cpf != null ? suggestion.nr_cpf : '' })
        return suggestion.nm_paciente;
    }
    getSuggestionValueTel = (suggestion) => {
        this.setState({ cd_paciente: suggestion.cd_paciente, booPesquisaTelefone: true, valueNome: suggestion.nm_paciente, valueCpf: suggestion.nr_cpf != null ? suggestion.nr_cpf : '', dtNasc: ajusteDate(suggestion.dt_nascimento), telefone: suggestion.celular != null ? suggestion.celular : '' })
        return suggestion.celular;
    }
    handleSuggestionsFetchRequested = ({ value }) => {
        this.searchPacient(value.toUpperCase())
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        this.setState({ valueNome: value.toUpperCase() })
        if (this.state.valueNome.length > inputValue.length) {
            this.setState({ valueNome: value.toUpperCase(), telefone: '', dtNasc: new Date(), valueCpf: '' })

        }
    };
    handleSuggestionsFetchRequestedTel = ({ value }) => {
        this.searchTelefone(value.toUpperCase())
        const inputValue = deburr(value.trim()).toLowerCase();
        this.setState({ telefone: value.toUpperCase() })
        if (this.state.telefone.length > inputValue.length) {
            if (this.state.booPesquisaTelefone == true) this.setState({ booPesquisaTelefone: false, telefone: value.toUpperCase(), valueCpf: '', dtNasc: new Date(), valueNome: '', booNome: false })

        }
    };
    handleChange = name => (event, { newValue }) => {

        this.setState({ [name]: newValue.toUpperCase() });
        if (name == 'valueNome' && newValue == '') this.setState({ valueNome: newValue, telefone: '', dtNasc: new Date(), valueCpf: '' })
    };
    handleSuggestionsClearRequested = () => {
        this.setState({ stateSuggestions: [] })
    };
    handleSuggestionsClearRequestedTel = () => {
        this.setState({ stateSuggestionsTel: [] })
    };
    /*-------------------------------------------------------------------*/
    renderCadastroSucess = (cd_paciente, nm_paciente, nr_cpf, dt_nascimento, celular) => {
        this.setState({ cd_paciente: cd_paciente, valueNome: nm_paciente, valueCpf: nr_cpf != null ? nr_cpf : '', dtNasc: dt_nascimento, telefone: celular != null ? celular : '' })
    }

    handleChangeAgenda = event => {
        var agenda = this.state.agendas.filter(r => r.cd_agenda_central == event.target.value)

        api.post('/consultarQtEncaixe', {
            cd_agenda: event.target.value,
        }).then(response => {
            if (response.data[0].encaixe == 'S' || response.data[0].sn_permite_encaixe_extra == 'S') {
                this.setState({ cd_agenda: event.target.value, prestador: agenda.map(r => r.nm_prestador), hr_horario: agenda.map(r => r.horario), hr_fim: agenda.map(r => r.hr_fim), hr_inicio: agenda.map(r => r.hr_inicio), inputHora: agenda.map(r => r.hr_inicio) })
            } else {
                this.setState({ alert: true, alertTitle: 'Atenção:', alertBody: 'Excedida quantidade maxima de encaixe! ', cd_agenda: null, prestador: '', hr_horario: null, hr_fim: null, hr_inicio: null });
            }
        })
    };

    handleChangeInputs = name => event => {
        this.setState({ [name]: event.target.value });
    };
    handleCloseConfirmT = async () => {
        this.myRefConfirm.current.alterLoading(true)
        this.save();
    };
    render() {
        const { classes } = this.props
        return (
            <div >
                {this.state.cadastroPaciente && <Cadastro handleClose={e => this.setState({ cadastroPaciente: false })} setCdPaciente={this.renderCadastroSucess} />}
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                {this.state.confirm && <Confirm ref={this.myRefConfirm} title={this.state.confirmTitle} body={this.state.confirmBody} handleCloseConfirmF={this.handleCloseConfirmF} handleCloseConfirmT={this.handleCloseConfirmT} />}
                <Dialog
                    open={this.state.open}
                    aria-labelledby="form-dialog-title"
                    fullWidth='true'
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="form-dialog-title">Encaixe</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Informe os dados para encaixe. {this.state.hr_horario != null && '  Horários ' + this.state.hr_horario}
                        </DialogContentText>
                        <div id="inputs">
                            <div>
                                {this.state.multi_agenda ?
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="select-agenda-label">Agenda</InputLabel>
                                        <SelectUi
                                            labelId="select-agenda-label"
                                            id="select-agenda"
                                            value={this.state.cd_agenda}
                                            onChange={this.handleChangeAgenda}
                                        >
                                            {this.state.agendas.map(r => {
                                                return <MenuItem value={r.cd_agenda_central}>{r.label}</MenuItem>
                                            })}
                                        </SelectUi>
                                    </FormControl> :
                                    <TextField
                                        disabled
                                        id="standard-Agenda"
                                        label="Cd. Agenda"
                                        margin="normal"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        value={this.state.cd_agenda}
                                        classes={{ root: classes.inputHora }}
                                    />
                                }
                                <FormControl classes={{ root: classes.rootSelect }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            className={classes.inputDate}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            label="Data"
                                            value={`${this.state.dataInput} `}
                                            onChange={this.onChangeDataAg}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </FormControl>
                                <TextField
                                    disabled
                                    id="standard-unidade"
                                    label="Unidade"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    value={this.state.unidade}
                                    classes={{ root: classes.inputHora }}
                                // onChange={this.handleChangeText('inputValor')}
                                />
                                <TextField
                                    disabled
                                    id="standard-prestador"
                                    label="Prestador"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    value={this.state.prestador}
                                    classes={{ root: classes.inputHora }}
                                // onChange={this.handleChangeText('inputValor')}
                                />
                                <TextField
                                    disabled
                                    id="standard-setor"
                                    label="Setor"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                    value={this.state.setor}
                                    classes={{ root: classes.inputHora }}
                                // onChange={this.handleChangeText('inputValor')}
                                />
                            </div>
                            <div >
                                <div className={classes.divAutoSuggest}>
                                    <TextField
                                        id="standard-hora"
                                        label="Hora"
                                        margin="normal"
                                        type="time"
                                        // defaultValue="07:30"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        value={this.state.inputHora}
                                        classes={{ root: classes.inputHora }}
                                        onChange={this.handleChangeInputs('inputHora')}
                                    />
                                    <div className={classes.inputAuto}>
                                        <Autosuggest
                                            renderInputComponent={this.renderInputComponent}
                                            suggestions={this.state.stateSuggestions}
                                            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={{
                                                classes,
                                                id: 'react-autosuggest-simple',
                                                label: 'Nome',
                                                placeholder: 'Digite o nome',
                                                value: this.state.valueNome,
                                                onChange: this.handleChange('valueNome'),
                                            }}
                                            theme={{
                                                container: classes.container,
                                                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                                suggestionsList: classes.suggestionsList,
                                                suggestion: classes.suggestion,
                                            }}
                                            renderSuggestionsContainer={options => (
                                                <Paper {...options.containerProps} square>
                                                    {options.children}
                                                </Paper>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={classes.divAutoSuggest}>
                                    <FormControl classes={{ root: classes.rootSelect }}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                className={classes.inputDate}
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                id="date-picker-inline"
                                                label="Data de Nascimento"
                                                value={this.state.dtNasc}
                                                onChange={this.onChangeData}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                    <div className={classes.inputText}>
                                        <Autosuggest
                                            renderInputComponent={this.renderInputComponentTel}
                                            suggestions={this.state.stateSuggestionsTel}
                                            onSuggestionsFetchRequested={this.handleSuggestionsFetchRequestedTel}
                                            onSuggestionsClearRequested={this.handleSuggestionsClearRequestedTel}
                                            getSuggestionValue={this.getSuggestionValueTel}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={{
                                                classes,
                                                id: 'react-autosuggest-simple-telefone',
                                                label: 'Telefone',
                                                placeholder: 'Telefone',
                                                value: this.state.telefone,
                                                onChange: this.handleChange('telefone'),
                                            }}
                                            theme={{
                                                container: classes.container,
                                                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                                suggestionsList: classes.suggestionsList,
                                                suggestion: classes.suggestion,
                                            }}
                                            renderSuggestionsContainer={options => (
                                                <Paper {...options.containerProps} square>
                                                    {options.children}
                                                </Paper>
                                            )}
                                        />
                                    </div>
                                    <FormControl className={classes.inputText}>
                                        <TextField
                                            label='CPF'
                                            id="cpf"
                                            placeholder="Digite o CPF"
                                            // inputProps={{
                                            //     'aria-label': 'CPF',
                                            // }}
                                            onChange={this.handleCpf("valueCpf")}
                                            value={this.state.valueCpf}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div>
                                <FormControl >
                                    <Select
                                        className={classes.inputSelect}
                                        styles={selectStyle}
                                        menuPlacement={'top'}
                                        placeholder='Selecione o Convênio *'
                                        value={this.state.selectedConvenio}
                                        onChange={e => this.handleChangeConvenio(e)}
                                        options={this.state.convenio}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        isDisabled={this.state.isDisabledConvenio}
                                        getOptionValue={option => option.value}
                                        isLoading={this.state.isLoadingConvenio}
                                        name={'convenio'}
                                    />
                                </FormControl>
                                <FormControl >
                                    <Select
                                        className={classes.inputSelect}
                                        styles={selectStyle}
                                        menuPlacement={'top'}
                                        placeholder='Selecione o Plano *'
                                        value={this.state.selectedPlano}
                                        onChange={e => this.handleChangePlano(e)}
                                        options={this.state.plano}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        isDisabled={this.state.isDisabledPlano}
                                        getOptionValue={option => option.value}
                                        isLoading={this.state.isLoadingPlano}
                                        name={'plano'}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl classes={{ root: classes.rootSelectExames }} >
                                    <Select
                                        className={classes.inputSelectExames}
                                        styles={selectStyle}
                                        menuPlacement={'top'}
                                        placeholder='Selecione o Exame *'
                                        value={this.state.selectedExame}
                                        onChange={e => this.handleChangeExame(e)}
                                        options={this.state.exame}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        isDisabled={this.state.isDisabledExame}
                                        closeMenuOnSelect={true}
                                        getOptionValue={option => option.value}
                                        isLoading={this.state.isLoadingExame}
                                        isOptionDisabled={option => option.proibido == "S"}
                                        name={'exames[]'}
                                        closeMenuOnScroll={true}
                                        pageSize={2}
                                        hideSelectedOptions={false}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.props.handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={this.click} color="primary" disabled={this.state.loading}>
                            {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />} Salvar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );

    }
}

Encaixe.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(style)(Encaixe)