import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './Horarios.css';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../services/api'
import Button from "@material-ui/core/Button";
import Calendar from 'react-calendar';


// list of items

// One item component
// selected prop will be passed
const MenuItem = ({ text, prestador, selected }) => {
    return <div>
        <Tooltip title={prestador}>
            <Button variant={selected ? "contained" : "outlined"} color="primary" >{text}</Button>
        </Tooltip>
    </div >
}

// All items component
// Important! add unique key
export const Menu = (list) => list.map(el => {
    const { data, it_ini, prest } = el;

    return (
        <MenuItem
            text={data}
            key={it_ini}
            prestador={prest}
        />
    );
});

const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

class App extends Component {

    state = {
        selected: this.props.selecionado,
        list: [],

    };

    onSelect = key => {
        if (this.state.selected[0] === key) {
            /*Desmarca exame */
            this.setState({ selected: "" });
            this.props.click([], this.props.exames,this.props.exames_form)
        }
        else if (this.state.selected[0] != key && this.state.selected[0] != undefined && this.state.selected[0] != '') {
            /*Entra aqui quando é selecionado outro horário para um exame já marcado */
            var anterior = this.state.selected[0];
            this.setState({ selected: key });
            var valor = this.state.list.filter(l => l.it_ini === key)
            this.props.clickSelecionado(valor, this.props.exames, anterior,this.props.exames_form)
        }
        else {
            /*Condicional para selecionar o exame que não está selecionado */
            this.setState({ selected: key });
            var selececionado = this.state.list.filter(l => l.it_ini === key)
            this.props.click(selececionado, this.props.exames,this.props.exames_form)
        }

    }
    componentDidMount() {
        this.loadHorarios(this.props.exames)
    }
    loadHorarios = async (exame) => {
        const selecionados = this.props.selecionados
        const response = await api.post('/horariosLivres', {
            date: this.props.data.getFullYear() + "-" + (this.props.data.getMonth() + 1) + "-" + this.props.data.getDate(),
            cd: exame,
            convenio: this.props.convenio,
            unidade: this.props.unidade,
            selecionados: JSON.stringify(selecionados)
        })
        if (response.data.length > 0) {
            var agenda = this.props.selecionados.map(s => s.agenda).filter(s => s = response.data.filter(p => p.agenda === s))
            var agendaRes = response.data.map(s => s.agenda)
            var dataSeleciona = new Date(this.props.selecionados.filter(s => s.it_ini === this.state.selected[0]).map(s => s.d_i))
            this.setState({ list: this.props.selecionados.filter(s => s.it_ini === this.state.selected[0] && (dataSeleciona.getFullYear() + "-" + (dataSeleciona.getMonth() + 1) + "-" + dataSeleciona.getDate()) === (this.props.data.getFullYear() + "-" + (this.props.data.getMonth() + 1) + "-" + this.props.data.getDate())).concat(response.data.filter(s => s.it_ini !== this.state.selected[0])) })
        } else {
            var dataSeleciona = new Date(this.props.selecionados.filter(s => s.it_ini === this.state.selected[0]).map(s => s.d_i))
            const horario = this.props.selecionados.filter(s => s.it_ini === this.state.selected[0] && (dataSeleciona.getFullYear() + "-" + (dataSeleciona.getMonth() + 1) + "-" + dataSeleciona.getDate()) === (this.props.data.getFullYear() + "-" + (this.props.data.getMonth() + 1) + "-" + this.props.data.getDate()))
            this.setState({ list: horario.length > 0 ? horario : [{'data':"Sem horário",'it_ini':""}]})
        }
    }
    render() {
        const { selected, list } = this.state;
        const { exames } = this.props;
        const menu = Menu(list, selected);
        return (
            <Paper className="Paper">
                <div className="App">
                    <ScrollMenu
                        wrapperClass={"menu-lista-itens"}
                        innerWrapperClass={"lista-itens"}
                        data={menu}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                        selected={selected}
                        onSelect={this.onSelect}
                    />
                </div>
            </Paper>
            // <div className="container">
            //     <div className="containerCalendario">
            //         <Calendar
            //             className="calendario"
            //         />
            //     </div>
            //     <div className="">
            //         {exames.map(exame => (

            //             <Paper className="Paper">
            //                 <div className="App content">
            //                     <ScrollMenu
            //                         data={Menu(list, selected)}
            //                         arrowLeft={ArrowLeft}
            //                         arrowRight={ArrowRight}
            //                         selected={selected}
            //                         onSelect={this.onSelect}
            //                     />
            //                 </div>
            //             </Paper>
            //         ))}
            //     </div>
            // </div>
        );
    }
}


export default App