import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography, withStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import api from '../../services/api'
import CircularProgress from '@material-ui/core/CircularProgress';

class ListaPacient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nm_paciente: this.props.nm_paciente,
            dt_nasc: this.props.dataNasc,
            pacientes: [],
            consultaPacientes: true
        }
        this.handlePesquisar();
    }
    handleListItemClick = (event, index) => {
        this.setState({ selectedPaciente: index })
    };
    handlePesquisar = () => {
        api.post('/searchT4', {
            nome: this.state.nm_paciente.toUpperCase(),
            dt_nasc: this.state.dt_nasc
        }).then(response => {
            this.setState({ pacientes: response.data, consultaPacientes: false })
        }).catch(error => {
            console.log(error)
        })
    }
    handlePaciente =()=>{
        this.props.setPaciente(this.state.selectedPaciente)
    }
    render() {
        const { classes } = this.props
        return (
            <div>

                <Dialog
                    open={true}
                    onClose={this.props.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="responsive-dialog-title">{"Pacientes"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText style={{ alignItems: 'center', textAlign: 'center' }} >
                            <List component="nav" className={classes.root} aria-labelledby="nested-list-subheader" subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    <Button className={classes.button} onClick={this.handlePaciente}>OK</Button>
                                    <Button className={classes.button} onClick={this.props.handleClose}>Cancelar</Button>
                                </ListSubheader>
                            }>
                                {this.state.consultaPacientes && <CircularProgress size={100} style={{ color: '#3f51b5', marginRight: '8px' }} />}
                                {this.state.consultaPacientes == false && this.state.pacientes.length == 0 ? 'Não há registros' : ''}
                                {this.state.pacientes.map(p => <ListItem
                                    button
                                    dense={true}
                                    divider={true}
                                    selected={this.state.selectedPaciente === p.cd_paciente}
                                    onClick={(event) => this.handleListItemClick(event, p.cd_paciente)
                                    }>
                                    <ListItemText primary={p.nm_paciente} secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                Data de Nascimento -
                                              </Typography>
                                            {p.dt_nascimento}
                                            <br></br>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                Nome da Mãe -
                                              </Typography>
                                            {p.nm_mae}
                                        </React.Fragment>

                                    } />
                                </ListItem>)}
                            </List>
                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            </div>
        );
    }

}
const styles = theme => ({

    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    btn: {
        backgroundColor: theme.palette.background.paper,
        marginTop: '10px',
        justifyContent: 'center',
    },

    button: {
        backgroundColor: theme.palette.background.paper,
        backgroundColor: '#ff5656',
        color: '#fff',
        padding: '5px',
        margin: '5px',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#ff5656',
        },
    },
});

export default withStyles(styles)(ListaPacient)