import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Dropzone from 'react-dropzone';
import DialogAnexoFile from './DialogAnexoFile';
import api from '../../services/api';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { RadioSharp } from '@material-ui/icons';

const AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: "AKIA44J23C7BY6ASKLST",
    secretAccessKey: "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"
});
let s3 = new AWS.S3();
const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function DialogAnexo(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [files, setFiles] = React.useState([]);
    const [filesNew, setFilesNew] = React.useState([]);
    const [filesDelete, setFilesDelete] = React.useState([]);
    const [filesPreviews, setFilesPreviews] = React.useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [anexoOpen, setAnexoOpen] = React.useState(false);

    useEffect(() => {
        console.log("I run everytime this component rerenders")
        console.log(files)
    }, [files]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    useEffect(() => {
        getImages(props.exames);
    }, []);

    const isEqual = (obj1, obj2) => {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
    const getImages = async (item) => {
        console.log(item)
        if (item.length > 1) {
            await api.post('/anexosMultiples', {
                item: item.map(item => item.cd_it_agenda_central + "|" + item.cd_agenda_central).join(",")
            }).then(async resp => {
                await resp.data.map(async u => await Object.assign(u, { path_image: await getImageS3(u.path) }))
                // Remover valores repetidos usando Set e Array.from()
                var uniqueArray = await Array.from(new Set(resp.data.map(JSON.stringify)), JSON.parse);
                setFiles(await Array.from(new Set(resp.data.map(JSON.stringify)), JSON.parse));
                console.log(uniqueArray)
            })
        } else {
            await api.post('/anexos', {
                item: item.map(item => item.cd_it_agenda_central + "|" + item.cd_agenda_central).join(",")
            }).then(async resp => {
                await resp.data.map(async u => await Object.assign(u, { path_image: await getImageS3(u.path) }))
                // Remover valores repetidos usando Set e Array.from()
                setFiles(await resp.data);

            })
        }

    }

    const getImageS3 = async (img) => {
        var params = { Bucket: 'vallions', Key: 'images/anexos/' + img };
        var url = await s3.getSignedUrl('getObject', params);
        return url;
    }

    const removeFile = async (file) => {
        let newFiles = files
        let oldFiles = filesDelete
        oldFiles.push(file)
        await newFiles.splice(newFiles.indexOf(file), 1)
        setFiles([]);
        setFiles(await newFiles);
        setFilesDelete(oldFiles);


    }
    const removeFileNew = async (file) => {
        let newFiles = filesNew
        await newFiles.splice(newFiles.indexOf(file), 1)
        setFilesNew([]);
        setFilesNew(await newFiles);
    }

    const handleSave = () => {
        console.log(files)
        props.handleSave(files)
    }

    const handleSaveAnexo = (files) => {
        setFilesNew(files);
        setAnexoOpen(false)
    }
    const save = () => {
        var item = props.exames.map(item => item.cd_it_agenda_central + "|" + item.cd_agenda_central).join(",")
        deleteFiles();
        for (let index = 0; index < filesNew.length; index++) {
            const file = filesNew[index];
            var formData = new FormData();
            formData.append("image", file);
            formData.append("item", item);
            // formData.append("it_init", props.exames.cd_it_agenda_central);
            // formData.append("agd_central", props.exames.cd_agenda_central);
            axios.post('http://api.chronos.vallions.com.br/api/s3Image', formData, { headers: { 'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) } })
            // axios.post('http://localhost:8000/api/s3Image', formData, { headers: { 'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2) } })
                .then(resp => {
                    console.log(resp)
                    if (index == filesNew.length - 1) {
                        props.handleClose()
                    }
                }).catch(error =>
                    console.log('S3', error)
                )
        }
    }
    const deleteFiles = () => {
        console.log(filesDelete)
        for (let index = 0; index < filesDelete.length; index++) {
            const file = filesDelete[index];
            if (props.exames.length > 1) {
                axios.post('http://api.chronos.vallions.com.br/api/deletarAnexoAll', {
                    anexo: file.id
                })
                    .then(resp => {
                        console.log(resp)
                        if (filesNew.length == 0)
                            props.handleClose()
                    }).catch(error =>
                            console.log('S3', error)
                        )
            } else {
                var delete_anexo = file.it_init + "|" + file.agd_central
                axios.post('http://api.chronos.vallions.com.br/api/deletarAnexo', {
                    item: delete_anexo,
                    anexo: file.id
                })
                    .then(resp => {
                        console.log(resp)
                        if (filesNew.length == 0)
                            props.handleClose()
                    }).catch(error =>
                        console.log('S3', error)
                    )
            }
        }
    }
    return (
        <React.Fragment>
            {anexoOpen && <DialogAnexoFile handleClose={() => setAnexoOpen(false)} handleSave={handleSaveAnexo} />}
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Anexo</DialogTitle>
                <DialogContent>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                    }}>
                        <ImageListFile key={files.length} imageArray={files} handleButtonClick={removeFile} />
                        <ImageListNew key={filesNew.length} imageArray={filesNew} handleButtonClick={removeFileNew} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Voltar
                    </Button>
                    <Button onClick={() => setAnexoOpen(true)} color="primary">
                        Anexar Arquivo
                    </Button>
                    <Button onClick={save} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

const ImageListFile = ({ imageArray, handleButtonClick }) => {
    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        }}>
            {imageArray.map((item, index) => (
                <div
                    style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '20px',
                    }}
                    key={index}>
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }}
                        src={item.path_image} alt={`Image ${index + 1}`} />
                    <IconButton aria-label="delete" size="small" onClick={() => handleButtonClick(item)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
            ))
            }
        </div >
    );
};
const ImageListNew = ({ imageArray, handleButtonClick }) => {
    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        }}>
            {imageArray.map((item, index) => (
                <div
                    style={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '20px',
                    }}
                    key={index}>
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            marginBottom: '10px'
                        }}
                        src={URL.createObjectURL(item)} alt={`Image ${index + 1}`} />
                    <IconButton aria-label="delete" size="small" onClick={() => handleButtonClick(item)}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
            ))
            }
        </div >
    );
};