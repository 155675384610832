import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import { Paper, withStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../../../services/api';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Room from './updateRoom';

import { Lightbox } from "react-modal-image";
import SocketContext from '../../../services/SocketProvider';
const AWS = require('aws-sdk');
AWS.config.update({
    accessKeyId: "AKIA44J23C7BY6ASKLST",
    secretAccessKey: "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"
});
let s3 = new AWS.S3();

const styles = theme => ({
    paper: {
        width: "100%",
        padding: 20
    },
    paperTable: {
        width: "100%",
        padding: 20,
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    }
})
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
class Fluxo extends React.Component {

    constructor(props) {
        super(props)
        this.closeUpdateRoom = this.closeUpdateRoom.bind(this)
        this.state = {
            unidades: JSON.parse(localStorage.getItem('unidades')),
            unidade: sessionStorage.getItem('unidade_queue') != null ? sessionStorage.getItem('unidade_queue') : null,
            rooms: [],
            room: 0,
            data: [],
            columns: [
                {
                    title: 'Avatar',
                    field: 'avatar',
                    render: rowData => {
                        console.log(rowData)
                        return <img
                            style={{ height: 36, width: 36, borderRadius: '50%', cursor: 'pointer' }}
                            src={rowData.pathS3}
                            onClick={() => this.setState({ openModalImagel: true, image_modal: rowData.pathS3 })}
                        />
                    },
                },
                { title: 'Data Entrada', field: 'checkin' },
                { title: 'Nome', field: 'name' },
                { title: 'Sala', field: 'nameRoom' },
                { title: 'Status', field: 'status', lookup: { 0: 'Saiu da Recepção', 1: 'Em Espera', 2: 'Em Exame', 3: 'Aguardando próximos exames', 4: 'Aguardando Entrega', 5: 'Finalizado', 6: 'Em Atendimento', 7: 'Espera Fila' } },
            ],
            disabledRoom: false,
            rows: [],
            roomUpdate: false,

            openModalImagel: false
        }

        sessionStorage.getItem('unidade_queue') && this.getRoom(sessionStorage.getItem('unidade_queue'));
        // setInterval(() => { this.atualizarFlow() }, 10000);
    }

    componentDidMount() {
        this.props.socket.on(`atualizaFlowCirc`, () => {
            this.atualizarFlow();
        })
    }
    atualizarFlow = () => {
        if (this.state.roomUpdate == false && this.state.room != null) {
            this.getFlow(this.state.room)
        }
    }
    handleChangeUnidade = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        sessionStorage.setItem('unidade_queue', value);
        this.getRoom(value)
    };

    handleChangeRoom = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        this.getFlow(value);
    };

    getImage = async (img) => {
        var params = { Bucket: 'vallions', Key: 'images/private/' + img };
        var url = await s3.getSignedUrl('getObject', params);
        return url;
    }
    getRoom = unidade => {
        api.post('/room', {
            unidade: unidade
        }).then(async response => {
            this.setState({ rooms: response.data })
            response.data.length > 0 && this.getFlow(0)
        })
    }
    getFlow = room => {
        if (room == 0 && this.state.rooms.length > 0) {
            api.post('/currentFlow', {
                unidade: this.state.unidade
            }).then(async response => {
                await response.data.map(async u => await Object.assign(u, { pathS3: await this.getImage(u.path) }));
                this.setState({ data: await response.data })
            }).catch(error => {
                console.log(error)
            })
        } else if (room > 0) {
            api.post('/currentRoomFlow', {
                room
            }).then(async response => {
                await response.data.map(async u => await Object.assign(u, { pathS3: await this.getImage(u.path) }));
                this.setState({ data: await response.data })
            }).catch(error => {
                console.log(error)
            })
        }

    }
    closeUpdateRoom = () => {
        this.setState({ roomUpdate: false })
        this.getFlow(this.state.room)
    }
    handleFlow = (idFlow) => {
        this.setState({ roomUpdate: true, idFlow: idFlow })
    }
    closeModel = () => {
        this.setState({ openModalImagel: false })
    }
    atualizaSocket = () => {
        this.props.socket.emit('atualizaFlowCirculante');
    }
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper}>
                {
                    this.state.openModalImagel && (
                        <Lightbox
                            medium={this.state.image_modal}
                            large={this.state.image_modal}
                            alt="Foto do Usuario"
                            onClose={this.closeModel}
                        />
                    )
                }
                {this.state.roomUpdate && <Room unidade={this.state.unidade} close={this.closeUpdateRoom} flow={this.state.idFlow} />}
                <MaterialTable
                    title="Fluxo Circulante"
                    options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                    }}
                    // onRowClick={(event, rowData) => {
                    //     console.log(event)
                    //     this.setState({ openModalImagel: true, image_modal: rowData.path })
                    // }}
                    isLoading={this.state.loading}
                    data={this.state.data}
                    actions={[{}]}
                    columns={this.state.columns}
                    components={{
                        Action: props => {
                            return <Button
                                color="primary"
                                variant="contained"
                                disableClick={true}
                                style={{ textTransform: 'none' }}
                                size="small"
                                onClick={() => this.handleFlow(props.data.idflow)}
                            >
                                AJUSTAR
                            </Button>
                        }
                        ,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                            name: 'unidade',
                                        }}
                                        value={this.state.unidade}
                                        onChange={this.handleChangeUnidade}
                                    >
                                        {this.state.unidades.map(u => {
                                            return <MenuItem value={u.cd_unidade_atendimento}>{u.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl} disabled={this.state.disabledRoom}>
                                    <InputLabel id="demo-simple-select-label">Salas</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                            name: 'room',
                                        }}
                                        value={this.state.room}
                                        onChange={this.handleChangeRoom}
                                    >
                                        <MenuItem value={null}></MenuItem>
                                        {this.state.rooms.length > 0 ? <MenuItem value={0}>TODAS SALAS</MenuItem> : <MenuItem value={0}>SEM SALAS</MenuItem>}

                                        {this.state.rooms.map(r => {
                                            return <MenuItem value={r.idroom}>{r.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        ),
                    }}
                    detailPanel={rowData => {
                        api.post('/syncSchedule', {
                            queue: rowData.idqueue
                        }).then(response => {
                            if (this.state.row != undefined && this.state.row.length > 0) {
                                var newrow = this.state.row.filter(sync => sync.queue != rowData.idqueue)
                            } else {
                                this.setState({ row: response.data })
                            }

                        })
                        return (
                            <Paper className={classes.paper} >
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Horário</TableCell>
                                            <TableCell align="right">Exame</TableCell>
                                            <TableCell align="right">Sala</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.row != undefined && this.state.row.filter(row => row.queue == rowData.idqueue).map(row => <TableRow key={row.cd_it_agenda_central}>
                                            <TableCell component="th" scope="row">{row.hr_agenda}</TableCell>
                                            <TableCell align="right">{row.exame}</TableCell>
                                            <TableCell align="right">{row.room}</TableCell>
                                            <TableCell align="right">{row.status == 0 ? 'Em Espera' : 'Concluído exame'}</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </Paper>
                        )
                    }}
                />
                {/* <div>
                    <Button variant="contained" size="small" style={{ backgroundColor: '#ff5656', color: '#fff', padding: '10px', margin: '10px', borderRadius: '5px' }} onClick={() => this.atualizaSocket()} >Att</Button>
                </div> */}
            </Paper>
        )
    }
}
const FluxoWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <Fluxo {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default withStyles(styles)(FluxoWithSocket)