import React from 'react';
import Table, { MTableToolbar } from 'material-table';
import { Paper, withStyles, Button, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import api from '../../services/api';
import Room from './updateRoom';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Lightbox } from "react-modal-image";
import SocketContext from '../../services/SocketProvider';

const styles = theme => ({
    paper: {
        width: "100%",
        padding: 20
    },
    formControl: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    },
    formGroup: {
        marginLeft: theme.spacing(2),
        minWidth: 175,
    }
})

class Fluxo extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            unidades: JSON.parse(localStorage.getItem('unidades')),
            unidade: sessionStorage.getItem('unidade_queue') != null ? sessionStorage.getItem('unidade_queue') : null,
            rooms: [],
            room: null,
            data: [],
            columns: [
                { title: 'Hora Agenda', field: 'hr_agenda' },
                { title: 'Atend.', field: 'cd_atendimento' },
                { title: 'Nome', field: 'nm_paciente' },
                { title: 'Exame', field: 'exame' },
                { title: 'Prestador', field: 'nm_prestador' },
                { title: 'Técnico', field: 'nm_tecnico' },
                { title: 'sn_realizado', field: 'sn_realizado', hidden: true },
                { title: 'cd_item_agendamento', field: 'cd_item_agendamento', hidden: true },
                { title: 'cd_paciente', field: 'cd_paciente', hidden: true },
                { title: 'dt_nascimento', field: 'dt_nascimento', hidden: true },
                { title: 'cd_itped_rx', field: 'cd_itped_rx', hidden: true },
                { title: 'cd_ped_rx', field: 'cd_ped_rx', hidden: true },
                { title: 'cd_convenio', field: 'cd_convenio', hidden: true },
                { title: 'Status', field: 'sn_realizado', lookup: { 'S': 'Confirmado', 'N': 'Não Confirmado', null: 'Não Confirmado' } },
                { title: 'Data Confirmação', field: 'dt_realizado' },
                {
                    title: 'Confirmar', field: 'study_iuid', disableClick: true, render: (rowData, index) => {
                        return (<Tooltip title="Confirmação" aria-label="confirmacao">
                            <Checkbox
                                checked={rowData.sn_realizado == "S"}
                                onChange={() => this.handleChangeChecked(rowData, index)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </Tooltip>)
                    },

                },
            ],
            disabledRoom: false,
            roomUpdate: false,
            finalizado: false,
            openModalImagel: false,
            n_confirmado: false
        }
        // setInterval(() => { this.atualizarFlow() }, 10000);
        sessionStorage.getItem('unidade_queue') && this.getRoom(sessionStorage.getItem('unidade_queue'));
    }
    componentDidMount() {
        this.props.socket.on(`atualizaFlowTec`, () => {
            this.atualizarFlow();
        })
    }
    atualizarFlow = () => {
        if (this.state.roomUpdate == false && (this.state.room != null && this.state.room != undefined)) {
            const broken = this.state.room.split('|');
            this.getAgenda(broken[0], broken[1])
        }
    }

    handleChangeChecked = (row, index) => {
        if (row.sn_realizado == null || row.sn_realizado == 'N') {
            this.setAtender(row)
        } else {
            api.post('/desconfirmaExame', {
                // atendimento: row.cd_atendimento,
                // item: row.cd_item_agendamento,
                cd_itped_rx: row.cd_itped_rx,
            }).then(resp => {
                const broken = this.state.room.split('|');
                this.getAgenda(broken[0], broken[1])
            })
        }
    }
    handleChangeUnidade = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
        sessionStorage.setItem('unidade_queue', value);
        this.getRoom(value)
    };
    handleChangeRoom = (event) => {
        const name = event.target.name;
        // console.log(name)
        const value = event.target.value;
        this.setState({
            [name]: value,
        });
        const broken = value.split('|');
        this.getAgenda(broken[0], broken[1])
    };
    getRoom = unidade => {
        api.post('/storeRoomExames', {
            empresa: this.state.unidades.filter(u => u.cd_unidade_atendimento == unidade).map(u => u.value).join()
        }).then(response => {
            this.setState({ rooms: response.data })
        })
    }
    getAgenda = (cd, tp) => {
        this.setState({ loading: true })
        api.post('/agendaTecnico', {
            cd: cd,
            tp: tp,
            finalizado: this.state.finalizado,
            n_confirmado: this.state.n_confirmado,
        }).then(async response => {
            this.setState({ data: await response.data, loading: false });
        }).catch(error => {
            console.log(error)
        });
    }
    setAtender = (flow) => {
        this.setState({ roomUpdate: true, atendimento: flow })
    }
    closeUpdateRoom = () => {
        this.setState({ roomUpdate: false })
        const broken = this.state.room.split('|');
        this.getAgenda(broken[0], broken[1])
    }
    closeModel = () => {
        this.setState({ openModalImagel: false })
    }
    handleChangeCheck = async event => {
        // console.log(event.target.checked)
        await this.setState({ loading: true, finalizado: event.target.checked });
        this.atualizarFlow();
    };
    handleChangeCheckNConfirmado = async event => {
        // console.log(event.target.checked)
        await this.setState({ loading: true, n_confirmado: event.target.checked });
        this.atualizarFlow();
    };
    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.paper}>
                {this.state.roomUpdate && <Room unidade={this.state.unidade} unidades={this.state.unidades} room={this.state.room.split('|')[0]} roomName={this.state.rooms.filter(r => r.cd_room == this.state.room.split('|')[0]).map(r => r.room).join()} close={this.closeUpdateRoom} atendimento={this.state.atendimento} agenda={this.state.data} />}
                {
                    this.state.openModalImagel && (
                        <Lightbox
                            medium={this.state.image_modal}
                            large={this.state.image_modal}
                            alt="Foto do Usuario"
                            onClose={this.closeModel}
                        />
                    )
                }
                <Table
                    title="Fila de Atendimento Técnico"
                    options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                    }}
                    isLoading={this.state.loading}
                    data={this.state.data}
                    actions={[
                        {
                            icon: 'refresh',
                            tooltip: 'Atualizar fila',
                            isFreeAction: true,
                            onClick: (event, rowData) => this.closeUpdateRoom()
                        },
                    ]}
                    // actions={[{}]}
                    columns={this.state.columns}
                    components={{
                        // Action: props => (
                        //     <Button
                        //         color="primary"
                        //         variant="contained"
                        //         style={{ textTransform: 'none' }}
                        //         size="small"
                        //         onClick={() => this.setAtender(props)}
                        //     >
                        //         ATENDER
                        //     </Button>
                        // ),
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                            name: 'unidade',
                                        }}
                                        value={this.state.unidade}
                                        onChange={this.handleChangeUnidade}
                                    >
                                        {this.state.unidades.map(u => {
                                            return <MenuItem value={u.cd_unidade_atendimento}>{u.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.formControl} disabled={this.state.disabledRoom}>
                                    <InputLabel id="demo-simple-select-label">Salas Exames</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        inputProps={{
                                            name: 'room',
                                        }}
                                        value={this.state.room}
                                        onChange={this.handleChangeRoom}
                                    >
                                        {/* {this.state.rooms.length > 0 ? <MenuItem value={{`|${r.tp_room}`}}>TODAS SALAS</MenuItem> : <MenuItem value={{`${r.cd_room}|${r.tp_room}`}}>SEM SALAS</MenuItem>} */}

                                        {this.state.rooms.map(r => {
                                            return <MenuItem value={`${r.cd_room}|${r.tp_room}`}>{r.room}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    className={classes.formControl}
                                    control={<Checkbox checked={this.state.finalizado} onChange={(e) => this.handleChangeCheck(e)} value="Confirmado" />}
                                    label="Confirmado"
                                />
                                <FormControlLabel
                                    className={classes.formControl}
                                    control={<Checkbox checked={this.state.n_confirmado} onChange={(e) => this.handleChangeCheckNConfirmado(e)} value="Não Confirmado" />}
                                    label="Não Confirmado"
                                />
                            </div>
                        ),
                    }}
                />
            </Paper>
        )
    }
}
const FluxoWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <Fluxo {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default withStyles(styles)(FluxoWithSocket)