import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import LabelIcon from '@material-ui/icons/Label';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink, getResources } from 'react-admin';
import Pedido from '@material-ui/icons/Assignment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SubMenu from './SubMenu';
import FlowIcon from '@material-ui/icons/FormatListNumbered';
import QueueIcon from '@material-ui/icons/Queue';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import Calendar from '@material-ui/icons/CalendarToday';
import MeusAgendamentos from '@material-ui/icons/Assignment';
import DefaultIcon from '@material-ui/icons/ViewList';

const Menu = (props) => {
    const resources = useSelector(getResources);

    const [state, setState] = useState({
        menuFluxo: false,
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme); // force rerender on theme change

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            <DashboardMenuItem onClick={props.onMenuClick} sidebarIsOpen={open} />
            {resources.map(resource => {
                if (resource.name === 'agenda' || resource.name === 'agendados' || resource.name === 'usuario' || resource.name === 'config' || resource.name === 'recepcao' || resource.name === 'conciliacao' || resource.name === 'guiche' || resource.name === 'fila2' || resource.name === 'painel' || resource.name === 'empresas_confirmacao' || resource.name === "agendaTecnico") {
                    return <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={`${resource.options.label}`}
                        leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                } else if (resource.name === 'fila') {
                    return <SubMenu
                        handleToggle={() => handleToggle('menuFluxo')}
                        isOpen={state.menuFluxo}
                        sidebarIsOpen={open}
                        name="Fluxo"
                        icon={<ImportExportIcon />}
                    >
                        <MenuItemLink
                            to={`/fila`}
                            primaryText={`Fila`}
                            leftIcon={<QueueIcon />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/fluxoCirculantes`}
                            primaryText={`Fluxo Circulante`}
                            leftIcon={<FlowIcon />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/fluxoTecnico`}
                            primaryText={`Fluxo Técnico`}
                            leftIcon={<FlowIcon />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                    </SubMenu>
                }

            }
            )}
            {isXSmall && (
                <MenuItemLink
                    to="/config"
                    primaryText={translate('pos.configuration')}
                    leftIcon={<SettingsIcon />}
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                />
            )}
            {isXSmall && props.logout}
        </div>
    );
}


export default Menu;
