import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import api from '../../../services/api';
import { Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '../../Alertas/Alert';

const useStyles = theme => ({
    root: {
        width: "100%",
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'center'
    },
    rootList: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    divList: {
        maxWidth: 360,
        minWidth: 360,
    },
    paperList: {
        marginRight: '4px',
        borderRadius: '4px'
    },
    listHeader: {
        backgroundColor: '#3f51b5',
        textAlign: 'center',
        color: 'white',
        borderRadius: '4px',
        zIndex: 1
    },
    div_button: {
        padding: '15px'
    }
});
// const unidades = JSON.parse(localStorage.getItem('unidades'));

class SelectedListConfig extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        cd_usuario: null,
        alert: false,
        alertTitle: null,
        alertBody: null,
        loading: false,
        selectedUnidade: null,
        nameUnidade: null,
        endereco: null,
        cd_unidade_atendimento: null,
        selectedConvenio: null,
        nameConvenio: null,
        tp_convenio: null,
        selectedPlano: null,
        convenio: [],
        plano: [],
        unidades: [],
        selectedUnidades: [],
        selectedConvenios: [],
        selectedPlanos: []
        // selectedUnidades: [{
        //     cd_unidade: "1",
        //     nome_unidade: "ULTRAMED APUCARANA",
        //     endereco: "Rua Josevaldo jose",
        //     cd_unidade_atendimento: '10'
        // }],
        // selectedConvenios: [{
        //     cd_convenio: "48",
        //     nome_convenio: "SANEPAR",
        //     cd_unidade: '1',
        //     tp_convenio: 'C'
        // }],
        // selectedPlanos: [{
        //     cd_plano: '1',
        //     nome_plano: 'UNICO',
        //     cd_convenio: "48",
        //     cd_unidade: "1"
        // }, {
        //     cd_plano: '2',
        //     nome_plano: 'UNICO',
        //     cd_convenio: "48",
        //     cd_unidade: "1"
        // }
        //     , {
        //     cd_plano: '3',
        //     nome_plano: 'UNICO',
        //     cd_convenio: "48",
        //     cd_unidade: "1"
        // }]
    }
    insertSelectUnidade = () => {
        var objeto_selecionado = {
            cd_unidade: this.state.selectedUnidade,
            nome_unidade: this.state.nameUnidade,
            endereco: this.state.endereco,
            cd_unidade_atendimento: this.state.cd_unidade_atendimento,
        }
        this.state.selectedUnidades.push(objeto_selecionado)
        this.setState({ checked: !this.state.checked })
    }
    insertSelectConvenio = () => {
        var objeto_selecionado = {
            cd_convenio: this.state.selectedConvenio,
            nome_convenio: this.state.nameConvenio,
            cd_unidade: this.state.selectedUnidade,
            tp_convenio: this.state.tp_convenio
        }
        this.state.selectedConvenios.push(objeto_selecionado)
        this.setState({ checked: !this.state.checked })
    }
    insertSelectPlano = (cd_plano, nome_plano) => {
        var objeto_selecionado = {
            cd_plano: cd_plano,
            nome_plano: nome_plano,
            cd_convenio: this.state.selectedConvenio,
            cd_unidade: this.state.selectedUnidade,
        }
        this.state.selectedPlanos.push(objeto_selecionado)
        this.setState({ checked: !this.state.checked })

    }
    handleChangeCheck = async event => {
        const unidadeSelecionada = this.state.selectedUnidades.filter(u => u.cd_unidade == this.state.selectedUnidade);
        console.log(unidadeSelecionada)
        console.log(this.state.selectedUnidades)
        if (Object.keys(unidadeSelecionada).length === 0) {
            if (event.target.checked == true) {
                this.insertSelectUnidade();
                this.insertSelectConvenio();
                this.insertSelectPlano(event.target.value, event.target.name);
            } else if (event.target.checked == false) {

            }
        } else {
            const convenioSelecionado = this.state.selectedConvenios.filter(u => u.cd_convenio == this.state.selectedConvenio);
            if (Object.keys(convenioSelecionado).length === 0) {
                this.insertSelectConvenio();
                this.insertSelectPlano(event.target.value, event.target.name);
            } else {
                if (event.target.checked == true) {
                    this.insertSelectPlano(event.target.value, event.target.name);

                } else {
                    const planoSelecionado = this.state.selectedPlanos.filter(p => p.cd_convenio == this.state.selectedConvenio);
                    if (Object.keys(planoSelecionado).length == 1) {
                        this.setState({ selectedConvenios: this.state.selectedConvenios.filter(c => c.cd_convenio != this.state.selectedConvenio), checked: !this.state.checked });
                        const unidadeSelecionado = this.state.selectedConvenios.filter(u => u.cd_unidade == this.state.selectedUnidade);
                        if (Object.keys(unidadeSelecionado).length == 1) {
                            this.setState({ selectedUnidades: this.state.selectedUnidades.filter(u => u.cd_unidade != this.state.selectedUnidade), checked: !this.state.checked })
                        }
                    }
                    var planos_ajuste = [];
                    await this.state.selectedPlanos.forEach((item, index) => {
                        if (item.cd_plano != event.target.value || item.cd_convenio != this.state.selectedConvenio) {
                            planos_ajuste.push(item);
                        }
                    })
                    this.setState({ selectedPlanos: planos_ajuste, checked: !this.state.checked });
                }
            }
        }

    };
    handleListItemClickUnidade = (event, index, label, cd_unidade_atendimento, endereco) => {
        this.state.selectedUnidade == index ? this.setState({ selectedUnidade: 0, selectedConvenio: 0, selectedPlano: 0, convenio: [], plano: [] }) : this.setState({ selectedUnidade: index, nameUnidade: label, cd_unidade_atendimento: cd_unidade_atendimento, endereco: endereco })
        this.state.selectedUnidade != index && this.searchConvenio(index)
    };
    handleListItemClickConvenio = (event, index, label, tp_convenio) => {
        this.state.selectedConvenio == index ? this.setState({ selectedConvenio: 0, selectedPlano: 0, plano: [] }) : this.setState({ selectedConvenio: index, nameConvenio: label, tp_convenio: tp_convenio })
        this.state.selectedConvenio != index && this.searchPlano(index)
    };
    handleListItemClickPlano = (event, index) => {
        this.setState({ selectedPlano: index });
    };
    searchConvenio = (cd_empresa) => {
        this.setState({ convenio: [], plano: [] })
        api.post('/convenio', {
            cd_empresa: cd_empresa
        })
            .then((response) => {
                this.setState({ convenio: response.data })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    searchPlano = (cd_convenio) => {
        this.setState({ plano: [] })
        api.post('/planosConvenio', {
            cd_convenio: cd_convenio,
            empresa: this.state.selectedUnidade
        })
            .then((response) => {
                this.setState({ plano: response.data })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    setUnidades = (unidades, cd_usuario) => {
        this.setState({ unidades, cd_usuario, convenio: [], plano: [], selectedUnidade: null, selectedConvenio: null, selectedPlano: null })
        this.getConfiguracoesUnidades(cd_usuario);
        this.getConfiguracoesConvenios(cd_usuario);
        this.getConfiguracoesPlanos(cd_usuario);
    }
    getConfiguracoesUnidades = cd_usuario => {
        api.post('/unidadeUsuarioLocalConfig', {
            user: cd_usuario
        }).then(response => {
            this.setState({ selectedUnidades: response.data })
        }).catch(error => {
            console.log(error)
        })
    }
    getConfiguracoesConvenios = cd_usuario => {
        api.post('/convenioUsuarioLocalConfig', {
            user: cd_usuario
        }).then(response => {
            this.setState({ selectedConvenios: response.data })
        }).catch(error => {
            console.log(error)
        })
    }
    getConfiguracoesPlanos = cd_usuario => {
        api.post('/planoUsuarioLocalConfig', {
            user: cd_usuario
        }).then(response => {
            this.setState({ selectedPlanos: response.data })
        }).catch(error => {
            console.log(error)
        })
    }
    salvar = () => {
        this.setState({ loading: true });
        api.post('/unidadeUsuarioLocal', {
            user: this.state.cd_usuario,
            unidades: JSON.stringify(this.state.selectedUnidades),
        })
            .then((response) => {
                api.post('/convenioInsertLocal', {
                    user: this.state.cd_usuario,
                    convenios: JSON.stringify(this.state.selectedConvenios),
                })
                    .then((response) => {
                        api.post('/planoInsertLocal', {
                            user: this.state.cd_usuario,
                            planos: JSON.stringify(this.state.selectedPlanos),
                        })
                            .then((response) => {
                                this.setState({ loading: false, alert: true, alertTitle: 'Sucesso', alertBody: 'Configurações de usuário concluídas com sucesso.' });
                            })
                            .catch(function (error) {
                                console.log('Plano error: ' + error);
                            });
                    })
                    .catch(function (error) {
                        console.log('Coonvenio error: ' + error);
                    });
            })
            .catch(function (error) {
                console.log('Unidade error: ' + error);
            });
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={e => this.setState({ alert: false })} />}
                <div className={classes.divList}>
                    <Paper className={classes.paperList} elevation={3}>
                        <List className={classes.rootList} component="nav" aria-label="secondary mailbox folder" height={70} itemSize={5} loading={true}
                            subheader={
                                <ListSubheader className={classes.listHeader} component="div" id="nested-list-subheader">
                                    UNIDADE
                                </ListSubheader>
                            }>
                            {this.state.unidades.map(u => {
                                return <ListItem
                                    button
                                    selected={this.state.selectedUnidade === u.value}
                                    onClick={event => this.handleListItemClickUnidade(event, u.value, u.label, u.cd_unidade_atendimento, u.endereco)}
                                >
                                    <ListItemText primary={u.label} />
                                </ListItem>
                            }
                            )}
                        </List>
                    </Paper>
                </div>
                <div className={classes.divList}>
                    <Paper className={classes.paperList} elevation={3}>
                        <List className={classes.rootList} component="nav" aria-label="secondary mailbox folder"
                            subheader={
                                <ListSubheader className={classes.listHeader} component="div" id="nested-list-subheader">
                                    CONVÊNIO
                            </ListSubheader>
                            }>
                            {this.state.convenio.map(c => {
                                return <ListItem
                                    button
                                    selected={this.state.selectedConvenio === c.value}
                                    onClick={event => this.handleListItemClickConvenio(event, c.value, c.label, c.tp_convenio)}
                                >
                                    <ListItemText primary={c.label} />
                                </ListItem>
                            })}
                        </List>
                    </Paper>
                </div>
                <div className={classes.divList}>
                    <Paper elevation={3}>
                        <List className={classes.rootList} component="nav" aria-label="secondary mailbox folder"
                            subheader={
                                <ListSubheader className={classes.listHeader} component="div" id="nested-list-subheader">
                                    PLANO
                            </ListSubheader>
                            }>
                            {this.state.plano.map(p => {
                                return <ListItem>
                                    <FormControlLabel
                                        control={<Switch checked={this.state.checked ? (this.state.selectedPlanos.filter(plano => plano.cd_unidade == this.state.selectedUnidade && plano.cd_convenio == this.state.selectedConvenio && plano.cd_plano == p.value).length == 1 ? true : false) : (this.state.selectedPlanos.filter(plano => plano.cd_unidade == this.state.selectedUnidade && plano.cd_convenio == this.state.selectedConvenio && plano.cd_plano == p.value).length == 1 ? true : false)}
                                            onChange={e => this.handleChangeCheck(e)} value={p.value} name={p.descricao} descricao={p.descricao} />}
                                        label={p.label}
                                    />
                                </ListItem>
                            })}
                        </List>
                    </Paper>
                </div>
                <div className={classes.div_button}>
                    <Button color="secondary" variant={"outlined"} onClick={e => this.salvar()}>{this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />}Salvar</Button>
                </div>
            </div>
        );
    }

}
SelectedListConfig.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(useStyles)(SelectedListConfig)

