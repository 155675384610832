import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import api from '../../services/api'
import Select from 'react-select';
import SearchIcon from '@material-ui/icons/Search';
import Fab from "@material-ui/core/Fab";
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import { createMuiTheme } from "@material-ui/core";
import { format } from 'date-fns';
import Tooltip1 from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateRange from './DateRanges';

import Alert from '../Alertas/Alert';

/*----------------------- */
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '-5px',


  },
  containerButtons: {
    display: 'flex',
    alignSelf: 'center'
  },
  input: {
    margin: theme.spacing(1),
    width: '300px',
  },
  inputDate: {
    margin: theme.spacing(1),
    width: '300px'
  },
  inputSelect: {
    marginTop: '17px',
    width: '300px',
  },
  inputExames: {
    margin: theme.spacing(1),
    width: '400px',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formInput: {
    marginTop: '12px',

  },
  calendarPaper: {
    marginTop: theme.spacing.unit,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  calendarControl: {
    padding: theme.spacing(1),
    width: '70%',
    height: '50%',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -4,
    left: -4,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    alignSelf: 'flex-end',
  },
  popover: {
    pointerEvents: 'none',
  },
}));

const selectStyle = {
  option: (provided, state) => ({
    ...provided,
    padding: 15,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
  }),
  control: (provided, state) => ({
    ...provided,
    border: '0px',
    outline: '0px',
    borderBottom: '1px solid red',
    borderRadius: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    '&:hover': {
      borderColor: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      borderColor: 'none',
      boxShadow: 'none'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
  })
};

const defaultMaterialTheme = createMuiTheme({
  margin: 8,
});

function Inputs(props) {
  var unidade = JSON.parse(localStorage.getItem('unidades'));
  if (unidade !== null && Object.keys(unidade).length > 0) {
    let unidades = [];
    unidade.forEach(element => {
      unidades.push(element.value)
    });
    unidades = unidades.toString();
    unidade.push({ value: unidades, label: "Todas as Unidades" })
  }


  async function getRecursos(update) {
    var resultado = []
    await api.post('/recursosSearch', {
      cd_empresa: unidade[unidade.length - 1].value
    })
      .then(async response => {
        resultado = response.data
        let recursos = [];
        await response.data.forEach(element => {
          recursos.push(element.value)
        });

        recursos = recursos.toString();
        resultado.push({ value: recursos, label: "Todos os Recursos" })
        setState({ ...state, selectedRecurso: resultado[resultado.length - 1], recurso: resultado, isDisabledRecurso: false, ...update })
      })
  }

  async function getSetor() {
    var resultadoSetor = []
    var updatedValues = { isLoadingSetor: true, isDisabledSetor: true }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    api.post('/searchSetor', {
      cd_empresa: unidade[unidade.length - 1].value,
      user: localStorage.getItem('login')
    }).then(async response => {
      resultadoSetor = response.data
      let setor = [];
      await response.data.forEach(element => {
        setor.push(element.value)
      });
      setor = setor.toString();
      resultadoSetor.push({ value: setor, label: "Todos os Setores" })
      var update = {
        selectedSetor: resultadoSetor[resultadoSetor.length - 1],
        setor: resultadoSetor,
        isDisabledSetor: false
      }
      setState(prevState => {
        return { ...prevState, ...update };
      });
      props.changeSetor(resultadoSetor[resultadoSetor.length - 1])
      // getRecursos(update)
    })
  }

  useEffect(() => {
    if (unidade !== null && Object.keys(unidade).length > 0) {
      getSetor()
      
    }
  }, [])

  const dataInicial = () => {
    var data_agenda = new Date()
    var dataFinal = data_agenda.getFullYear() + "-" + ((data_agenda.getMonth() + 1) < 10 ? "0" + (data_agenda.getMonth() + 1) : (data_agenda.getMonth() + 1)) + "-" + data_agenda.getDate()
    return dataFinal

  }
  const selectedUnidadeValidate = () => {
    if (unidade !== null && Object.keys(unidade).length > 0) {
      return unidade[unidade.length - 1]
    } else {
      return null
    }
  }
  const classes = useStyles();
  var initial = {
    nomePaciente: '',
    fone: '',
    dtAgenda: new Date(),
    dtInicio: dataInicial(),
    dtFim: dataInicial(),
    unidade: null,
    recurso: null,
    setor: null,
    selectedUnidade: unidade[unidade.length - 1],
    selectedRecurso: null,
    selectedSetor: null,
    isDisabledRecurso: true,
    isLoadingRecurso: false,
    isDisabledSetor: true,
    isLoadingSetor: false,
    alert: false,
    alertTitle: null,
    alertBody: null,
    confirmado: false,
    n_confirmado: false,
    outros: false,
    atendido: false
  }
  var unidadeSelecionada = ''; //Não apagar
  var convenioSelecionado = ''; //Não apagar
  const [state, setState] = React.useState(initial);


  /*--------------------------------------------------------SELECT------------------------------------------------------ */
  const handleChangeUnidade = selectedUnidade => { //ONCHANGE UNIDADE
    console.log("Mudou unidade")
    var updatedValues = { selectedUnidade: selectedUnidade, selectedSetor: null, isDisabledSetor: true, isLoadingSetor: true, selectedRecurso: null, isDisabledRecurso: true, isLoadingRecurso: true }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
    props.changeUnidade(selectedUnidade)
    searchSetor(selectedUnidade.value);
  };

  const handleChangeRecurso = (option, action) => { //ONCHANGE RECURSO
    var updatedValues = { selectedRecurso: option }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    props.changeRecurso(option)
    setState(prevState => prevState);
  };
  const handleChangeSetor = (option, action) => { //ONCHANGE Setor
    console.log("Mudou setor")
    var updatedValues = { selectedSetor: option }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    props.changeSetor(option)
    // setState(prevState => prevState);
  };

  const handleCloseAlert = () => {
    setState(prevState => { return { ...prevState, alert: false } })
  };
  /*---------------------------------------------------------------------------------------------------------------------- */

  /*--------------------------------------------------------INPUT TEXT------------------------------------------------------ */
  const onChangeNome = e => {
    var updatedValues = { nomePaciente: e.target.value.toUpperCase() }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
  };

  const onChangeTelefone = e => {
    var updatedValues = { fone: e.target.value }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    setState(prevState => prevState);
  };

  function searchRecurso(cd_empresa) { //PROCURA OS RECURSOS
    api.post('/recursosSearch', {
      cd_empresa: cd_empresa
    })
      .then(async response => {
        let recursos = [];
        await response.data.forEach(element => {
          recursos.push(element.value)
        });
        recursos = recursos.toString();
        await response.data.push({ value: recursos, label: "Todos os Recursos" })
        setState(prevState => { return { ...prevState, recurso: response.data, isLoadingRecurso: false, isDisabledRecurso: false, selectedRecurso: response.data[response.data.length - 1] } })
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }

  function searchSetor(cd_empresa) { //PROCURA OS RECURSOS
    var updatedValues = { isLoadingSetor: true, isDisabledSetor: true }
    setState(prevState => {
      return { ...prevState, ...updatedValues };
    });
    api.post('/searchSetor', {
      cd_empresa: cd_empresa,
      user: localStorage.getItem('login')
    })
      .then(async response => {
        let setor = [];
        await response.data.forEach(element => {
          setor.push(element.value)
        });
        setor = setor.toString();
        await response.data.push({ value: setor, label: "Todos os Setores" })
        props.changeSetor(response.data[response.data.length - 1])
        setState(prevState => { return { ...prevState, setor: response.data, isLoadingSetor: false, isDisabledSetor: false, selectedSetor: response.data[response.data.length - 1] } })
      })
      .catch(error => {
        setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Erro:', alertBody: `${error}` } });
      });
  }

  /*---------------------------------------------------------------------------------------------------------------------- */
  function searchAgendamento(nome, fone, dataInicio, dataFim, cd_empresa, cd_setor) {
    api.post('/agendamentosSearch', {
      nome: nome,
      fone: fone,
      dataInicio: dataInicio,
      dataFim: dataFim,
      cd_empresa: cd_empresa.value,
      cd_setor: cd_setor == null ? null : cd_setor.value,
      confirmado: state.confirmado,
      n_confirmado: state.n_confirmado,
      outros: state.outros,
      atendido: state.atendido,
    }).then((response) => {
      setState(prevState => { return { ...prevState, agendamentos: response.data, status: true } });
      props.onClick(response.data); //Envia para o Remarcar.jsx
      setSuccess(true);
      setLoading(false);
      timer.current = setTimeout(() => {
        setSuccess(false);
      }, 2000);
    })
  }

  function searchExameDisponivel() {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      searchAgendamento(state.nomePaciente, state.fone, state.dtInicio, state.dtFim, state.selectedUnidade, state.selectedSetor);

    }
    // if (state.selectedUnidade != null && state.selectedSetor != null) {

    // } else {
    //   setState(prevState => { return { ...prevState, alert: true, alertTitle: 'Atenção', alertBody: 'Preencher a Unidade e o Setor, Para consultar os agendamentos!' } })
    // }
  }

  const clickButtonLimpar = async () => {
    await setState({
      nomePaciente: '',
      fone: '',
      dtAgenda: new Date(),
      dtInicio: dataInicial(),
      dtFim: dataInicial(),
      unidade: null,
      recurso: state.recurso,
      selectedUnidade: unidade[unidade.length - 1],
      selectedSetor: state.setor[state.setor.length - 1],
      isDisabledRecurso: false,
      confirmado: false,
      n_confirmado: false,
      outros: false
    });
    props.onClickRemove([]);
  }
  /*---------------------------------------------------------------------------------------------------------------------*/
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const onClick = async (inicio, fim, data) => {
    await setState(prevState => { return { ...prevState, dtInicio: inicio, dtFim: fim } });
    props.changeData(data, inicio)
  }
  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };
  return (
    <div className={classes.formInput}>
      {state.alert && <Alert title={state.alertTitle} body={state.alertBody} handleCloseAlert={handleCloseAlert} />}
      <Paper className={classes.container}>
        <div>
          <div className={classes.container}>
            <FormControl className={classes.formControl} required>
              <Select
                className={classes.inputSelect}
                styles={selectStyle}
                placeholder='Selecione a Unidade *'
                value={state.selectedUnidade}
                onChange={handleChangeUnidade}
                options={unidade}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                getOptionValue={option => option.value}
                name={'unidade'}
              />
            </FormControl>

            <FormControl className={classes.formControl} required>
              <Select
                className={classes.inputSelect}
                styles={selectStyle}
                placeholder='Selecione o Setor *'
                value={state.selectedSetor}
                onChange={handleChangeSetor}
                options={state.setor}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                isDisabled={state.isDisabledSetor}
                getOptionValue={option => option.value}
                isLoading={state.isLoadingSetor}
                name={'setor'}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <DateRange onClick={onClick} />
            </FormControl>
          </div>
          <div className={classes.container}>
            {/* <FormControl className={classes.formControl} required>
              <Select
                className={classes.inputSelect}
                styles={selectStyle}
                placeholder='Selecione o Recurso *'
                value={state.selectedRecurso}
                onChange={handleChangeRecurso}
                options={state.recurso}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null
                }}
                isDisabled={state.isDisabledRecurso}
                getOptionValue={option => option.value}
                isLoading={state.isLoadingRecurso}
                name={'recurso'}
              />
            </FormControl> */}
            <FormGroup row className={classes.formControl}>
              <FormControlLabel
                control={<Checkbox checked={state.confirmado} onChange={handleChange('confirmado')} value="confirmado" />}
                label="Confirmado"
              />
              <FormControlLabel
                control={<Checkbox checked={state.n_confirmado} onChange={handleChange('n_confirmado')} value="Não n_confirmado" />}
                label="Não Confirmado"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={state.atendido} onChange={handleChange('atendido')} value="atendido" />
                }
                label="Atendido"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={state.outros} onChange={handleChange('outros')} value="outros" />
                }
                label="Outros"
              />
            </FormGroup>

          </div>
        </div>
        <div className={classes.containerButtons} >
          <div className={classes.wrapper}>
            <Tooltip1 title="Limpar" placement="right">
              <IconButton aria-label="delete" onClick={clickButtonLimpar}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            </Tooltip1>
          </div>
          <div className={classes.wrapper}>
            <Fab
              aria-label="save"
              color="primary"
              className={buttonClassname}
              onClick={searchExameDisponivel}
            >
              {success ? <CheckIcon /> : <SearchIcon />}
            </Fab>
            {loading && <CircularProgress size={65} className={classes.fabProgress} />}
          </div>
        </div>
      </Paper>
    </div>
  );
}
export default Inputs