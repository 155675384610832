import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import api from '../../services/api'
import { green } from '@material-ui/core/colors';
import Alert from '../Alertas/Alert';
import { format } from 'date-fns';
import Hashids from 'hashids';

class DialogConfirmaContato extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.exames_selected)
        this.state = {
            open: true,
            exames_selected: this.props.exames_selected
        };
    }

    printOrientacao = async () => {
        const hashids = new Hashids();
        var pacVal = this.state.exames_selected.map(a => a.nm_paciente)
        var domain = localStorage.getItem('domain');
        if (this.state.exames_selected.filter(p => p.nm_paciente === pacVal[0]).length === this.state.exames_selected.length) {
            this.state.exames_selected.map(s => {
                window.open(`http://api.chronos.vallions.com.br/print/orientacao/${domain}/${hashids.encode(s.cd_it_agenda_central)}`, '_blank');
            })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível imprimir orientações de pacientes diferentes.' });
        }
    }
    printAtendimento = async () => {
        const hashids = new Hashids();
        var atendimento = this.state.exames_selected.map(a => a.cd_atendimento)
        var domain = localStorage.getItem('domain');
        if (this.state.exames_selected.filter(p => p.cd_atendimento === atendimento[0]).length === this.state.exames_selected.length) {
            this.state.exames_selected.map(s => {
                window.open(`http://api.chronos.vallions.com.br/print/atendimento/${domain}/${hashids.encode(s.cd_atendimento)}`, '_blank');
            })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível imprimir atendimento com códigos diferentes.' });
        }
    }
    print = async (view) => {
        const hashids = new Hashids();
        var atendimento = this.state.exames_selected.map(a => a.cd_atendimento)
        var domain = localStorage.getItem('domain');
        if (this.state.exames_selected.filter(p => p.cd_atendimento === atendimento[0]).length === this.state.exames_selected.length) {
            this.state.exames_selected.map(s => {
                window.open(`http://api.chronos.vallions.com.br/print/${view}/${domain}/${hashids.encode(s.cd_atendimento)}`, '_blank');
            })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: `Não é possível imprimir com códigos diferentes.` });
        }
    }
    printEtiqueta = async () => {
        const hashids = new Hashids();
        var atendimento = this.state.exames_selected.map(a => a.cd_atendimento)
        var domain = localStorage.getItem('domain');
        if (this.state.exames_selected.filter(p => p.cd_atendimento === atendimento[0]).length === this.state.exames_selected.length) {
            this.state.exames_selected.map(s => {
                window.open(`http://api.chronos.vallions.com.br/print/etiqueta/${domain}/${hashids.encode(s.cd_atendimento)}`, '_blank');
            })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível imprimir etiqueta com códigos diferentes.' });
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div >
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={() => this.setState({ alert: false })} />}
                <Dialog
                    open={this.state.open}
                    aria-labelledby="form-dialog-title"
                    fullWidth='true'
                    maxWidth='md'
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="form-dialog-title">Impressão</DialogTitle>
                    <DialogContent style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={this.printOrientacao} variant="contained" color="secondary">
                            AGENDAMENTO
                        </Button>
                        <Button onClick={this.printAtendimento} variant="contained" color="secondary" disabled={this.state.exames_selected[0].sn_atendido == 'N'}>
                            ATENDIMENTO
                        </Button>
                        <Button onClick={this.printEtiqueta} variant="contained" color="secondary" disabled={this.state.exames_selected[0].sn_atendido == 'N'} >
                            ETIQUETA
                        </Button>
                        <Button onClick={() => this.print("termo_responsabilidade")} variant="contained" color="secondary" disabled={this.state.exames_selected[0].sn_atendido == 'N'}>
                            Termo Resp.
                        </Button>
                        <Button onClick={() => this.print("termo_acompanhante")} variant="contained" color="secondary" disabled={this.state.exames_selected[0].sn_atendido == 'N'}>
                            TERMO ACOMPANHANTE
                        </Button>
                        <Button onClick={() => this.print("declaracao")} variant="contained" color="secondary" disabled={this.state.exames_selected[0].sn_atendido == 'N'}>
                            DECLARAÇÃO
                        </Button>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.props.close} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );

    }
}

// DialogConfirmaContato.propTypes = {
//     classes: PropTypes.object,
// };
export default DialogConfirmaContato