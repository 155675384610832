import React, { Fragment, useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

const styles = theme => ({
  root: {
    width: '195px'
  }
})

class KeyboardDatePickerExampleClass extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null
    }
  }
  onChangeData = e => {
    if (e == null) {
      this.props.handleDataNasc(null);
    }
    if (e != 'Invalid Date' && e != null) {
      this.props.handleDataNasc(format(e, 'yyyy-MM-dd'));
    } else {
      this.props.handleDataNasc(null)
    }
    this.setState({ selectedDate: e })
  };
  setData = (data) => {
    this.setState({ selectedDate: new Date(`${data} `) });
  }
  render() {
    return (
      <Fragment >
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={{ root: { width: '195px', margin: '10px' } }}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label="Data de Nascimento"
            value={this.state.selectedDate}
            onChange={e => this.onChangeData(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

export default KeyboardDatePickerExampleClass;