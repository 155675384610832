import React from 'react';
import { withStyles, Typography, IconButton, Input, FormControl, InputLabel, Paper, MenuItem, Select, TextField } from '@material-ui/core'
import SelectOrigem from '@material-ui/core/Select';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';

import api from '../../../services/api';

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const crm = suggestion.ds_codigo_conselho;
    const uf = suggestion.cd_uf;
    const nome = suggestion.nm_prestador;
    const view = `${nome} | CRM: ${crm} | UF: ${uf}`
    const matches = match(suggestion.nm_prestador, query);
    const parts = parse(view, matches);
    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map(part => (
                    <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                        {part.text}
                    </span>
                ))}
            </div>
        </MenuItem>
    );
}
class InfoAtendimento extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            cd_atendimento: this.props.cd_atendimento,

            exames_at: this.props.exames,

            medExec: '',
            cdMedExec: '',
            crmMedExec: '',
            suggestions: [],

            selectEspecialidades: [],
            selectedEspecialidade: '',
            disabledEspecialidade: true,

            selectOrigems: [],
            selectOrigem: '',

            dialogGuia: false,
            indexGuia: null,
            numeroCarteira: null,
            numeroGuia: '',
            senha: '',
            ValCarteira: null,
        }
        this.state.cd_atendimento != null && this.getInfoAtendimento();
        this.state.cd_atendimento != null && this.getGuiaAtendimento();
        this.handleChange = this.handleChange.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
        this.handleSuggestionsFetchRequested = this.handleSuggestionsFetchRequested.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);

        this.handleChangeOrigem = this.handleChangeOrigem.bind(this);
        this.handleChangeEspecialidade = this.handleChangeEspecialidade.bind(this);
    }
    componentDidMount() {
        this.getMedicoAgenda();
        this.getOrigem(this.props.empresa);
    }
    getGuiaAtendimento = () => {
        api.post('/informacoesGuiaAtendimento', {
            atendimento: this.state.cd_atendimento,
        }).then(response => {
            this.setState({ guiasPedido: response.data, numeroGuia: response.data[0].nr_guia, disabledGuia: true, disabledSenha: true, senha: response.data[0].cd_senha })
        }).catch(error => {
            console.log(error)
        })
    }
    getInfoAtendimento = () => {
        api.post('/informacoesAtendimento', {
            atendimento: this.state.cd_atendimento,
        }).then(response => {
            this.getEspecialidadeAtendimento(response.data[0].cd_prestador)
            this.setState({ medExec: response.data[0].nm_prestador, cdMedExec: response.data[0].cd_prestador, selectedEspecialidade: response.data[0].cd_especialid })
        }).catch(error => {
            console.log(error)
        })
    }
    /*-------------------------------------------------GUIA E CARTEIRA -----------------------------------------------------------------*/

    handleNumeroGuia = (event) => {
        this.setState({ numeroGuia: event.target.value })
    }
    handleNumeroCarteira = (event) => {
        this.setState({ numeroCarteira: event.target.value })
    }
    handleValidadeCarteira = (event) => {
        this.setState({ ValCarteira: event.target.value })
    }
    handleSenha = (event) => {
        this.setState({ senha: event.target.value })
    }
    /*-------------------------------------------------PRESTADOR- MÉDICO --------------------------------------------------------------*/

    getMedicoAgenda = () => {
        var itens_agenda = this.state.exames_at.map(e => e.cd_agenda_central);
        const somaIguais = itens_agenda.reduce((accumulator, element) => {
            var count = itens_agenda.filter(e => e == element);
            count.length > 1 && accumulator++
            return accumulator;
        }, 0);
        if (itens_agenda.length === 1 || somaIguais === itens_agenda.length) {
            api.post('/selectMedicoAgenda', {
                cd_agenda: this.state.exames_at[0].cd_agenda_central
            }).then(response => {
                response.data[0].nm_prestador != null && this.setState({ medExec: response.data[0].nm_prestador, cdMedExec: response.data[0].cd_prestador, crmMedExec: response.data[0].cd_conselho, disabledInput: true })
                response.data[0].nm_prestador != null && this.getEspecialidade(response.data[0].cd_prestador)
            }).catch(error => console.log(error))
        }

    }
    /*---------------------------------------------------------------------------------------------------------------------------------*/
    /*---------------------------------------------------------ORIGEM -----------------------------------------------------------------*/
    getOrigem = empresa => {
        api.post('/selectOrigem', {
            user: localStorage.getItem('login'),
            empresa: empresa
        }).then(response => {
            const paciente_externo = response.data.filter(d => d.ds_ori_ate.indexOf("EXTERNO") != -1);
            this.setState({ selectOrigems: response.data, selectOrigem: paciente_externo.length == 1 ? paciente_externo.map(p => p.cd_ori_ate).join() : null })
        }).catch()
    }
    handleChangeOrigem = (event) => {
        this.setState({ selectOrigem: event.target.value })
    };
    /*---------------------------------------------------------------------------------------------------------------------------------*/

    /*-------------------------------------------------ESPECIALIDADE ------------------------------------------------------------------*/
    getEspecialidade = cd => {
        api.post('/especialidade', {
            cd_prestador: cd
        }).then(response => {
            this.setState({ selectEspecialidades: response.data, selectedEspecialidade: response.data.filter(e => e.sn_especial_principal === 'S').map(e => e.cd_especialid).join(), disabledEspecialidade: false });
        })
    }
    getEspecialidadeAtendimento = cd => {
        api.post('/especialidade', {
            cd_prestador: cd
        }).then(response => {
            this.setState({ selectEspecialidades: response.data, disabledEspecialidade: false });
        })
    }
    handleChangeEspecialidade = (event) => {
        this.setState({ selectedEspecialidade: event.target.value })
    };
    /*---------------------------------------------------------------------------------------------------------------------------------*/

    /*-------------------------------------------------Auto Suggest -------------------------------------------------------------------*/
    handleChange = name => (event, { newValue }) => {
        this.setState({ [name]: newValue.toUpperCase() });
    };
    renderInputComponent = (inputProps) => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <FormControl className={classes.inputAuto}>
                <InputLabel htmlFor="standard-adornment-password">
                    Médico Executante
                </InputLabel>
                <Input
                    disabled={this.state.disabledInput}
                    id="standard-adornment-password"
                    value={this.state.medExec}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                    }}
                    {...other}
                // onChange={handleChange("valueName")}
                />
            </FormControl >
        );
    }
    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    handleSuggestionsFetchRequested = ({ value }) => {
        if (Number.isInteger(parseInt(value))) {
            this.searchCrmMed(value, 1);
        } else {
            this.searchNomeMed(value.toUpperCase(), 1);
        }
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        this.setState({
            medExec: value.toUpperCase()
        });
        if (this.state.medExec.length > inputValue.length) {
            this.setState({
                medExec: value.toUpperCase()
            });
        }

    }

    searchNomeMed = (value, tp) => {
        if (value.length > 2) {
            api.post('/selectNomeMedico', {
                name: value
            }).then(response => {
                tp == 1 ? this.setState({ suggestions: response.data }) : this.setState({ suggestionsMedSol: response.data })
            }).catch(error => console.log(error))
        }

    }
    searchCrmMed = (value, tp) => {
        if (value.length > 3) {
            api.post('/selectCrmMedico', {
                crm: value
            }).then(response => {
                tp == 1 ? this.setState({ suggestions: response.data }) : this.setState({ suggestionsMedSol: response.data })
            }).catch(error => console.log(error))
        }

    }
    getSuggestionValue = (suggestion) => {
        this.setState({ cdMedExec: suggestion.cd_prestador, crmMedExec: suggestion.ds_codigo_conselho })
        this.getEspecialidade(suggestion.cd_prestador);
        return suggestion.nm_prestador;
    }
    /*---------------------------------------------------------------------------------------------------------------------------------*/
    retornarStates = () => {
        return { ...this.state }
    }
    render() {
        const { classes } = this.props
        return (
            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', border: '1px solid #ddd', margin: '20px', boxShadow: '1px 2px 1px #ddd', padding: '10px', borderRadius: '5px' }}>
                <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold', backgroundColor: '#023575', color: '#fff', borderRadius: '5px' }} variant="subtitle1" gutterBottom>PREENCHA OS CAMPOS</Typography>
                <div>
                    <FormControl className={classes.formControl}>
                        <div className={classes.root}>
                            <Autosuggest
                                renderInputComponent={this.renderInputComponent}
                                suggestions={this.state.suggestions}
                                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={{
                                    classes,
                                    id: 'react-autosuggest-simple',
                                    label: 'Médico Executante',
                                    placeholder: 'Digite o nome',
                                    value: this.state.medExec,
                                    onChange: this.handleChange('medExec'),
                                }}
                                theme={{
                                    container: classes.container,
                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                    suggestionsList: classes.suggestionsList,
                                    suggestion: classes.suggestion,
                                }}
                                renderSuggestionsContainer={options => (
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                )}
                            />
                        </div>
                    </FormControl>
                    <FormControl style={{ width: '250px', margin: '5px' }} >
                        <InputLabel id="demo-simple-esp-label">Especialidade</InputLabel>
                        <Select
                            labelId="demo-simple-esp-label"
                            id="demo-simple-esp"
                            value={this.state.selectedEspecialidade}
                            onChange={this.handleChangeEspecialidade}
                            disabled={this.state.disabledEspecialidade}
                        >
                            {this.state.selectEspecialidades.map(selectEspecialidade => <MenuItem value={selectEspecialidade.cd_especialid}>{selectEspecialidade.ds_especialid}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: '250px', margin: '5px' }} >
                        <InputLabel id="demo-simple-origem-label">Origem</InputLabel>
                        <SelectOrigem
                            labelId="demo-simple-origem-label"
                            id="demo-simple-origem"
                            value={this.state.selectOrigem}
                            onChange={e => this.handleChangeOrigem(e)}
                        >
                            {this.state.selectOrigems.map(selectOrigem => {
                                return <MenuItem value={selectOrigem.cd_ori_ate}>{selectOrigem.ds_ori_ate}</MenuItem>
                            })}
                        </SelectOrigem>
                    </FormControl>
                </div>
                <div>
                    <TextField style={{ width: '250px', margin: '5px' }} label="Número de Carteira" variant="standard" size="small" onChange={e => this.handleNumeroCarteira(e)} />
                    <TextField type="date" style={{ width: '250px', margin: '5px' }} label="Validade da Carteira" variant="standard" size="small" onChange={e => this.handleValidadeCarteira(e)} defaultValue={new Date()} />
                    <TextField style={{ width: '250px', margin: '5px' }} label="Número da Guia" variant="standard" size="small" disabled={this.state.disabledGuia} onChange={e => this.handleNumeroGuia(e)} value={this.state.numeroGuia} />
                    <TextField error={false} helperText="" style={{ width: '250px', margin: '5px' }} label="Senha" variant="standard" disabled={this.state.disabledSenha} size="small" onChange={e => this.handleSenha(e)} value={this.state.senha} />
                </div>

            </div>
        )
    }
}
const styles = theme => ({
    inputAuto: {
        width: '250px',
    },
    root: {
        margin: '5px',
        display: 'flex',
        flexWrap: 'wrap',
        // padding: theme.spacing.unit,
    },
    container: {
        position: 'relative',
        width: '250px',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        width: '500px',
        // marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',

    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
})
export default withStyles(styles)(InfoAtendimento);