import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Horarios from "./Horarios"
import api from '../services/api'
import Calendar from 'react-calendar';
import Perguntas from './Perguntas/Perguntas-Form';
import InputNomeTelefone from './InputNomeTelefone';

import Alert from './Alertas/Alert';
import Confirm from './Alertas/Confirm';

const useStyles = makeStyles(theme => ({
    list: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    fullList: {
        width: "auto"
    },
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '15px'
    },
    containerCalendario: {
        alignSelf: 'center'
    },
    calendario: {
        fontFamily: 'Montserrat,Segoe UI,Tahoma,sans-serif !important',
        boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
        borderRadius: '4px',
        marginRight: '15px',
        maxWidth: '100%',
        font: 'inherit',
        border: '0 !important',
        overflow: 'hidden',
    },
    exameList: {
        margin: '20px auto 0',
        padding: '0 20px',
    },
    articleList: {
        background: '#fff',
        border: '1px solid #DDD',
        borderRadius: '5px',
        padding: '20px',
        marginBottom: '20px'
    },
    pList: {
        fontSize: '14px',
        color: '#999',
        // lineHeight: '24px',
        justifyContent: 'flex-end'
    },
    infoDiv: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    infoDivTotal: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    articleListSelecionado: {
        background: '#F4F5F7',
        border: '1px solid #DDD',
        borderRadius: '5px',
        padding: '20px',
        marginBottom: '20px'
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    divTextArea: {
        width: '100%',

    },
    textarea: {
        width: '100%',
        resize: 'none',
        borderRadius: '4px',
        border: '1px solid #DDD',
        background: '#F4F5F7',
        height: '90px'
    },
    timer: {
        fontSize: '16px',
        textAlign: 'end'
    },
    teste: {
        [theme.breakpoints.between('md', 'lg')]: {
            width: 637,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1208,
        },
        [theme.breakpoints.down('sm')]: {
            width: 350,
        }
    }
}));
export default function TemporaryDrawer(props) {
    console.log(props.queue)
    const classes = useStyles();
    const ajusteDate = (data) => {
        var d = new Date(data);
        d.setDate(d.getDate() + 1);
        return d
    }
    var teste = []
    const [state, setState] = React.useState({
        right: false,
        statusRecarregarHorarios: false,
        exames: props.itens,
        infoSelecionados: [],
        selecionados: [],
        data: ajusteDate(props.dataSelecionada),
        data2: new Date('2019-09-17'),
        data3: new Date('2019-09-21'),
        toggle: true,
        perguntas: false,
        nome: props.nome,
        unidade: props.unidade,
        dtNasc: props.dtNasc,
        plano: props.plano,
        obs: props.comentario != null ? props.comentario : '',
        convenio: props.convenio,
        telefone: props.telefone,
        cd_paciente: props.cd_paciente,
        confirmPerg: false,
        confirm: false,
        confirmTitle: null,
        confirmBody: null,
        alert: false,
        alertTitle: null,
        alertBody: null
    });
    // setState({ ...state, rigth: true });

    const toggleDrawer = (side, open) => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [side]: open });
    };
    const reservarHorario = (obj, exame, exame_form) => {
        if (state.infoSelecionados.filter(selecionado => selecionado.exame_form === exame_form && selecionado.it_ini != "").length > 0) {
            api.post('/excluirAgendamento', {
                it_ini: obj.it_ini,
                it_fi: obj.it_fim,
                agd_central: obj.agenda,
            })
            setState(prevState => { return { ...prevState, infoSelecionados: state.infoSelecionados.filter(selecionado => selecionado.exame_form != exame_form), selecionados: state.selecionados.filter(s => s != exame), statusRecarregarHorarios: true } })

        } else {
            api.post('/horariosReservadoNull', {
                it_ini: obj.it_ini,
                it_fi: obj.it_fim,
                agd_central: obj.agenda,
            }).then(async (response) => {
                if (response.data === true) {
                    api.post('/reservarAgendamento', {
                        nome: 'RESERVADO CHRONOS',
                        it_ini: obj.it_ini,
                        it_fi: obj.it_fim,
                        agd_central: obj.agenda,
                    })
                    props.clickSelecionado(obj, exame)

                    setState(prevState => { return { ...prevState, infoSelecionados: state.infoSelecionados.filter(selecionado => selecionado.exame_form != exame_form).concat(obj), selecionados: state.selecionados.concat(exame_form), statusRecarregarHorarios: true } })
                } else {
                    setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true, infoSelecionados: state.infoSelecionados.concat([{ exame: exame, exame_form: exame_form, it_ini: "" }]) } })
                    // setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true } })
                    setState(prevState => {
                        return { ...prevState, alert: true, alertTitle: 'Alerta', alertBody: 'Horário reservado por outro usuário!' };
                    });
                }
            })
        }
    }
    const horarioJaReservado = (exame, exame_form) => {
        alert("opa já reservado")
        setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true, infoSelecionados: state.infoSelecionados.concat([{ exame: exame, exame_form: exame_form, it_ini: "" }]) } })
        // setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true } })
        setState(prevState => {
            return { ...prevState, alert: true, alertTitle: 'Alerta', alertBody: 'Horário reservado por outro usuário!' };
        });
    }
    const onClick = (selecionado, exame, exame_form) => {
        if (selecionado[0] === undefined) {
            state.infoSelecionados.filter(selecionado => selecionado.exame_form === exame_form).map(s => {
                api.post('/excluirAgendamento', {
                    it_ini: s.it_ini,
                    it_fi: s.it_fim,
                    agd_central: s.agenda,
                }).then((response) => {
                    setState(prevState => { return { ...prevState, infoSelecionados: state.infoSelecionados.filter(s => s.exame_form != exame_form), selecionados: state.selecionados.filter(s => s != exame_form), statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true } })
                })
            })

        } else {
            var obj = Object.assign({}, selecionado[0], { "exame": exame, "exame_form": exame_form })
            api.post('/horariosNoAwait', {
                it_ini: obj.it_ini,
                it_fi: obj.it_fim,
                agd_central: obj.agenda
            }).then((response) => {
                response.data ? reservarHorario(obj, exame, exame_form) : horarioJaReservado(exame, exame_form)
                // response.data && props.clickSelecionado(obj, exame)
            })
        }
    }
    const onClickSelecionado = (selecionado, exame, anterior, exame_form) => {
        var obj = Object.assign({}, selecionado[0], { "exame": exame, "exame_form": exame_form })
        state.infoSelecionados.filter(selecionado => selecionado.it_ini === anterior).map(s => {
            api.post('/excluirAgendamento', {
                it_ini: s.it_ini,
                it_fi: s.it_fim,
                agd_central: s.agenda,
            }).then((response) => {
                api.post('/horariosReservadoNull', {
                    it_ini: s.it_ini,
                    it_fi: s.it_fim,
                    agd_central: s.agenda,
                }).then((response) => {
                    if (response.data === true) {
                        api.post('/reservarAgendamento', {
                            nome: 'RESERVADO CHRONOS',
                            it_ini: obj.it_ini,
                            it_fi: obj.it_fim,
                            agd_central: obj.agenda,
                        })
                        setState(prevState => { return { ...prevState, infoSelecionados: state.infoSelecionados.filter(selecionado => selecionado.exame_form != exame_form).concat(obj), selecionados: state.selecionados.concat(exame_form), statusRecarregarHorarios: true } })
                        props.clickSelecionado(obj, exame)
                    } else {
                        setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true, infoSelecionados: state.infoSelecionados.concat([{ exame: exame, exame_form: exame_form, it_ini: "" }]) } })
                        // setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? false : true } })
                        setState(prevState => {
                            return { ...prevState, alert: true, alertTitle: 'Alerta', alertBody: 'Horário reservado por outro usuário!' };
                        });
                    }

                })

            })
        })
    }

    const insereSelecao = exame => {
        return state.infoSelecionados.filter(selecionado => selecionado.exame_form == exame).map(s => s.it_ini) ? state.infoSelecionados.filter(selecionado => selecionado.exame_form == exame).map(s => s.it_ini) : null;
    }

    const verificaSelecao = exame => {
        if (state.selecionados.indexOf(exame) > -1) {
            return true;
        } else {
            return false
        }
    }

    const dataSelecionado = (exame) => {
        var data_agenda = new Date(state.infoSelecionados.filter(s => s.exame_form === exame).map(s => s.d_i))
        return <div>
            <span className={classes.pList}>Reservado:</span> {data_agenda.getDate() + "/" + ((data_agenda.getMonth() + 1) < 10 ? "0" + (data_agenda.getMonth() + 1) : (data_agenda.getMonth() + 1)) + "/" + data_agenda.getFullYear()}
        </div>
    }
    const valorTotalExames = () => {
        var total = 0
        state.exames.map(exame => {
            console.log(exame.valor)
            exame.valor != null ? total = Number.parseFloat(total) + Number.parseFloat(exame.valor) : total = Number.parseFloat(total)
        })
        // console.log(total.toFixed(2))
        return total.toFixed(2)
    }

    const carregaHorarios = () => {
        return state.exames.map(exame => {
            return "", <article key={exame.value + state.data} data={state.data} className={verificaSelecao(exame.value) ? classes.articleListSelecionado : classes.articleList}>
                <div className={classes.infoDiv}>
                    <strong>{exame.ds_item_agendamento}</strong><span>{
                        verificaSelecao(exame.value) ? dataSelecionado(exame.value) : ""}
                    </span>
                    <span className={classes.pList}>{exame.valor != null ? "R$" + Number.parseFloat(exame.valor).toFixed(2) : state.convenio.tp_convenio === 'C' ? "Convênio" : "Sem valor cadastrado"}</span>
                </div>
                <div key={state.infoSelecionados.length > 0 ? state.infoSelecionados[state.infoSelecionados.length - 1].it_ini : state.infoSelecionados.length} date={state.data} selecionado={verificaSelecao(exame.value) ? true : false}>
                    <Horarios unidade={state.unidade.cd_unidade_atendimento} convenio={state.convenio.value} exames={exame.cd_item} exames_form={exame.value} click={(selecionado, exame, exame_form) => onClick(selecionado, exame, exame_form)} clickSelecionado={(selecionado, exame, anterior) => onClickSelecionado(selecionado, exame, anterior)} data={state.data} selecionado={insereSelecao(exame.value)} selecionados={state.infoSelecionados.filter(s => s.it_ini != "")} />
                </div>
            </article>
        })
    }
    const recarregaHorarios = () => {
        return state.exames.map(exame => {
            return "", <article key={exame.value + state.data} data={state.data} className={verificaSelecao(exame.value) ? classes.articleListSelecionado : classes.articleList}>
                <div className={classes.infoDiv}>
                    <strong>{exame.ds_item_agendamento}</strong><span>{
                        verificaSelecao(exame.value) ? dataSelecionado(exame.value) : ""}
                    </span>
                    <span className={classes.pList}>{exame.valor != null ? "R$" + Number.parseFloat(exame.valor).toFixed(2) : state.convenio.tp_convenio === 'C' ? "Convênio" : "Sem valor cadastrado"}</span>
                </div>
                <div key={state.infoSelecionados.length > 0 ? state.infoSelecionados[state.infoSelecionados.length - 1].it_ini : state.infoSelecionados.length} date={state.data} selecionado={verificaSelecao(exame.value) ? true : false}>
                    <Horarios unidade={state.unidade.cd_unidade_atendimento} convenio={state.convenio.value} exames={exame.cd_item} exames_form={exame.value} click={(selecionado, exame, exame_form) => onClick(selecionado, exame, exame_form)} clickSelecionado={(selecionado, exame, anterior, exame_form) => onClickSelecionado(selecionado, exame, anterior, exame_form)} data={state.data} selecionado={insereSelecao(exame.value)} selecionados={state.infoSelecionados.filter(s => s.it_ini != "")} />
                </div>
            </article>
        });
    }

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onKeyDown={toggleDrawer(side, false)}
        >
            <List className={classes.list}>
                <div className={classes.container}>
                    <div className={classes.containerCalendario}>
                        <Calendar
                            className={classes.calendario}
                            value={state.data}
                        />
                    </div>
                    <div className="">
                        {/* {carregaHorarios()} */}
                        {state.statusRecarregarHorarios ? recarregaHorarios() : carregaHorarios()}
                    </div>
                </div>
            </List>
        </div>
    );

    const onChange = date => {
        setState(prevState => { return { ...prevState, statusRecarregarHorarios: state.statusRecarregarHorarios ? true : false, data: new Date(date) } })
    }

    const onChangeObs = e => {
        var updatedValues = { obs: e.target.value }
        setState(prevState => {
            return { ...prevState, ...updatedValues };
        });
        setState(prevState => prevState);
    };

    const openPerguntas = () => {
        if (state.infoSelecionados.length > 0) {
            if (props.nome != null && props.nome != '' && props.telefone != null && props.telefone != '') {
                setState(prevState => { return { ...prevState, perguntas: true } })
            }
            else {
                setState(prevState => { return { ...prevState, confirmPerg: true } })
            }
        }
        else {
            setState(prevState => {
                return { ...prevState, alert: true, alertTitle: 'Alerta', alertBody: 'Selecione um horário' };
            });
        }
    }

    const checkpoint = [
        {
            time: 0,
            callback: () => teste(),
        }
    ]

    const closePergunta = () => {
        setState(prevState => { return { ...prevState, perguntas: false } })
    }
    const handleClose = () => {
        setState(prevState => { return { ...prevState, confirmPerg: false } })
    }

    function handleCloseAlert() {
        setState(prevState => {
            return { ...prevState, alert: false };
        });
    };

    function handleCloseConfirmF() {
        setState(prevState => {
            return { ...prevState, alert: false };
        });
    };

    function handleCloseConfirmT() {
        setState(prevState => {
            return { ...prevState, alert: false };
        });
    };
    function closeQueue() {
        props.closeQueue();
    }
    return (
        <div>
            {state.alert && <Alert title={state.alertTitle} body={state.alertBody} handleCloseAlert={handleCloseAlert} />}
            {state.confirm && <Confirm title={state.confirmTitle} body={state.confirmBody} handleCloseConfirmF={handleCloseConfirmF} handleCloseConfirmT={handleCloseConfirmT} />}
            {state.confirmPerg ? <InputNomeTelefone obs={state.obs} queue={props.queue} itens={state.infoSelecionados.map(s => s.exame)} handleClose={handleClose} info_itens={state.infoSelecionados} close={closePergunta} closeQueue={closeQueue} nome={state.nome} plano={state.plano} unidade={state.unidade} convenio={state.convenio.value} telefone={state.telefone} dtNasc={state.dtNasc} /> : ""}
            <List className={classes.list}>
                <div className={classes.container}>
                    <div className={classes.containerCalendario}>
                        <Calendar
                            className={classes.calendario}
                            value={state.data}
                            onChange={onChange}
                            calendarType="US"
                            minDate={new Date()}
                            showNeighboringMonth={true}
                        />
                    </div>
                    <div className={classes.teste}>
                        {state.statusRecarregarHorarios ? recarregaHorarios() : carregaHorarios()}
                        <div className={classes.divTextArea}>
                            <div className={classes.infoDivTotal}>
                                <strong>VALOR TOTAL PARTICULAR:</strong><span className={classes.pList}>{"R$" + valorTotalExames()}</span>
                            </div>
                            <textarea placeholder="Observação" className={classes.textarea} onChange={onChangeObs} value={state.obs}>

                            </textarea>
                        </div>
                        <div className={classes.footer}>
                            <Button variant="contained" color="primary" onClick={openPerguntas}>Confirmar</Button>
                        </div>

                    </div>
                </div>
            </List>

            {state.perguntas ? <Perguntas obs={state.obs} queue={props.queue} itens={state.infoSelecionados.map(s => s.exame)} info_itens={state.infoSelecionados} close={closePergunta} closeQueue={closeQueue} nome={state.nome} plano={state.plano} dtNasc={state.dtNasc} unidade={state.unidade} convenio={state.convenio.value} telefone={state.telefone} cd_paciente={state.cd_paciente} /> : ""}

        </div >
    );
}