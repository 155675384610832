import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from './services/api'
import Alert from './components/Alertas/Alert';

export default function FormDialog() {
  const [open, setOpen] = React.useState(true);
  const [state, setState] = React.useState({
    value: null,
    valueConfirm: null,
    alert: false,
    alertTitle: null,
    alertBody: null,
  });

  function handleClose() {
    setOpen(false);
  }
  const handleSenha = event => {
    setState({ ...state, value: event.target.value });
  }
  const handleSenhaConfirm = event => {
    setState({ ...state, valueConfirm: event.target.value });
  }
  function handleClickSAave() {
    if (state.value === state.valueConfirm) {
      api.post('/primeiroAcesso', {
        login: localStorage.getItem('login'),
        p: state.value

      })
        .then((response) => {
          setState({ ...state, alert: true, alertTitle: 'Sucesso', alertBody: 'Senha alterada com sucesso.' })
          localStorage.setItem('s_p', 0)
          setOpen(false)
        })
        .catch(function (error) {
          setState({ ...state, alert: true, alertTitle: 'Atenção', alertBody: `Erro: ${error}` })
        });
    } else {
      setState({ ...state, alert: true, alertTitle: 'Atenção', alertBody: 'Insira a mesma senha.' })
    }

  }

  return (
    <div>
      {state.alert && <Alert title={state.alertTitle} body={state.alertBody} handleCloseAlert={e=>setState({...state, alert: false })} />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Primeiro acesso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Necessário alterar a senha para o próximo login.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nova Senha"
            type="password"
            value={state.value}
            onChange={e => handleSenha(e)}
            fullWidth
          />
          <TextField
            margin="dense"
            id="name"
            label="Confirmar Senha"
            type="password"
            value={state.valueConfirm}
            onChange={e => handleSenhaConfirm(e)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleClickSAave} color="primary">
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
