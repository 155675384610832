import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import api from '../../../services/api'
import Alert from '../../Alertas/Alert';
import Timer from 'react-compound-timer';
import FinishExam from './finishExam';
import SocketContext from '../../../services/SocketProvider';

class FormDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            valueConfirm: null,
            alert: false,
            alertTitle: null,
            alertBody: null,
            open: true,
            unidade: this.props.unidade,
            rooms: [],
            room: this.props.room,
            roomName: this.props.roomName,
            flow: this.props.flow,
            finishExam: false,
            prestadores: [],
            tecnico: '',
            medico: ''
        }
        this.getRoom();
        this.getPrestadores();
        // this.setIniciarExame();
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
    }

    handleClose = () => {
        this.props.close()
    }
    handleChangeSelect = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
    };
    getRoom = () => {
        api.post('/room', {
            unidade: this.state.unidade
        }).then(response => {
            this.setState({ rooms: response.data })
        })
    }
    handleClickAlterar = () => {
        api.post('/examesSyncQueue', {
            queue: this.state.flow.idqueue
        }).then(response => {
            const exames = response.data.filter(e => e.status == 0)
            if (exames.length > 1) {
                this.setState({ finishExam: true, tp: 1 });
            } else {
                this.setState({ finishExam: true, tp: 2 });
            }
            api.post('/confirmaExame', {
                atendimento: this.props.flow.cd_atendimento,
                item: this.props.flow.cd_item_agendamento,
                medico: this.state.medico,
                tecnico: this.state.tecnico
            }).then(resp => {
                console.log(resp.data)
            })
        }).catch(error => {
            console.log(error)
        })
    }
    setIniciarExame = () => {
        api.post('/inicioExame', {
            cd_room: this.state.room,
            user: localStorage.getItem('login'),
            nome: this.state.roomName,
            idflow: this.state.flow.idflow,
            unidade: this.state.unidade,
            queue: this.state.flow.idqueue
        }).then(response => {
            this.props.socket.emit('atualizaFlowCirculante')
            this.props.socket.emit('atualizaFlowTecnico')
            this.setState({ idflowIniciado: response.data.idflow })
        }).catch(error => {
            console.log(error)
        })
    }
    closeFinishExam = () => {
        this.setState({ finishExam: false })
        this.props.close()
    }
    getPrestadores = () => {
        api.post('/getPrestadores', {})
            .then(resp => {
                var usuario_prestador = resp.data.filter(users => users.cd_usuario == localStorage.getItem('login'));
                if (usuario_prestador.length > 0) {
                    let id_current = usuario_prestador[0].value;
                    console.log(id_current);
                    this.setState({ prestadores: resp.data, tecnico: id_current, medico: id_current })
                } else {
                    this.setState({ prestadores: resp.data })
                }
            })
    }
    render() {
        const { classes } = this.props
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={e => this.setState({ alert: false })} />}
                {this.state.finishExam && <FinishExam flow={this.state.flow} idFlowExame={this.state.idflowIniciado} tp={this.state.tp} close={e => this.setState({ finishExam: false })} unidade={this.state.unidade} finalizado={this.closeFinishExam} />}
                < Dialog
                    open={this.state.open}
                    // onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'sm'}>
                    <DialogTitle id="form-dialog-title">Execução do exame</DialogTitle>
                    <DialogContent className={classes.content}>
                        <div className={classes.temporizador}>
                            <Timer>
                                <Timer.Hours formatValue={(value) => `${(value < 10 ? `0${value}` : value)}:`} />
                                <Timer.Minutes formatValue={(value) => `${(value < 10 ? `0${value}` : value)}:`} />
                                <Timer.Seconds formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`} />
                            </Timer>
                        </div>
                        <div className={classes.select}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Técnico</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    inputProps={{
                                        name: 'tecnico',
                                    }}
                                    value={this.state.tecnico}
                                    onChange={this.handleChangeSelect}
                                >
                                    {this.state.prestadores.map(u => {
                                        return <MenuItem value={u.value}>{u.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Médico</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    inputProps={{
                                        name: 'medico',
                                    }}
                                    value={this.state.medico}
                                    onChange={this.handleChangeSelect}
                                >
                                    {this.state.prestadores.map(u => {
                                        return <MenuItem value={u.value}>{u.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">Cancelar</Button>
                        <Button onClick={this.handleClose} color="primary">Não realizado</Button>
                        <Button onClick={this.handleClickAlterar} color="primary"> Finalizar</Button>
                    </DialogActions>
                </Dialog >
            </div >
        );
    }

}
const styles = theme => ({
    formControl: {
        width: '100%'
        // marginLeft: theme.spacing(2),
        // minWidth: 175,
    },
    temporizador: {
        fontSize: '2.5em',
        color: '#333',
        margin: '0 auto',
        padding: '0.5rem',
        textAlign: 'center',
        flex: 1,
        alignSelf: 'center'
    },
    content: {
        display: 'flex'
    },
    select: {
        flex: 1
    }
}
)

const FormDialogWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <FormDialog {...props} socket={socket} />}
    </SocketContext.Consumer>
)

export default withStyles(styles)(FormDialogWithSocket)