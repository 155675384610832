import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from '../../../services/api'
import Alert from '../../Alertas/Alert';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SocketContext from '../../../services/SocketProvider';

class FormDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            valueConfirm: null,
            alert: false,
            alertTitle: null,
            alertBody: null,
            open: true,
            unidade: this.props.unidade,
            rooms: [],
            flow: this.props.flow
        }
        console.log(this.props.flow)

        this.getRoom();
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
    }

    handleClose = () => {
        this.props.close()
    }
    handleChangeSelect = (event) => {
        const name = event.target.name;
        const value = event.target.value
        this.setState({
            [name]: value,
        });
    };
    getRoom = () => {
        api.post('/room', {
            unidade: this.state.unidade
        }).then(response => {
            this.setState({ rooms: response.data })
        })
    }
    handleClickAlterar = () => {
        if (this.state.status != null) {
            api.post('/closeLineFlow', {
                idflow: this.state.flow,
                user: localStorage.getItem('login')
            }).then(response => {
                api.post('/insertInitialFlow', {
                    room: this.state.room,
                    status: this.state.status,
                    user: localStorage.getItem('login'),
                    queue: response.data,
                }).then(response => {
                    this.props.socket.emit('atualizaFlowCirculante')
                    this.props.socket.emit('atualizaFlowTecnico')
                    this.handleClose()
                }).catch(error => console.log(error))
            }).catch(error => {
                console.log(error)
            })
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={e => this.setState({ alert: false })} />}
                < Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth={'sm'}>
                    <DialogTitle id="form-dialog-title">Encaminhar paciente</DialogTitle>
                    <DialogContent>
                        <FormControl className={classes.formControl} disabled={this.state.disabledRoom}>
                            <InputLabel id="demo-simple-select-label">Salas</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                inputProps={{
                                    name: 'room',
                                }}
                                value={this.state.room}
                                onChange={this.handleChangeSelect}
                            >
                                {this.state.rooms.map(r => {
                                    return <MenuItem value={r.idroom}>{r.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl} disabled={this.state.disabledRoom}>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                inputProps={{
                                    name: 'status',
                                }}
                                value={this.state.status}
                                onChange={this.handleChangeSelect}
                            >
                                <MenuItem value={0}>Saída da recepção</MenuItem>
                                <MenuItem value={1}>Em espera</MenuItem>
                                <MenuItem value={2}>Em exame</MenuItem>
                                <MenuItem value={3}>Aguardando próximos exames</MenuItem>
                                <MenuItem value={4}>Aguardando entrega</MenuItem>
                                <MenuItem value={5}>Finalizado</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancelar
              </Button>
                        <Button onClick={this.handleClickAlterar} color="primary">
                            Alterar
              </Button>
                    </DialogActions>
                </Dialog >
            </div >
        );
    }

}
const styles = theme => ({
    formControl: {
        width: '100%'
        // marginLeft: theme.spacing(2),
        // minWidth: 175,
    }
}
)
const FormDialogWithSocket = (props) => (
    <SocketContext.Consumer>
        {socket => <FormDialog {...props} socket={socket} />}
    </SocketContext.Consumer>
)
export default withStyles(styles)(FormDialogWithSocket)