import React, { useState } from 'react';
import { Notification, useLogin, useNotify } from 'react-admin';

import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    makeStyles
} from '@material-ui/core/styles';
import { lightTheme } from './themes';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import CssBaseline from '@material-ui/core/CssBaseline';

const styles = makeStyles(function (theme) {
    return ({
        paper: {
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '30px',
            color: '#023575',
            borderRadius: '15px',
            boxShadow: '1px 4px 1px #d9d9d9',
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },

        title: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: 'linear-gradient(180deg, #023575 0%, rgba(2, 53, 117, 0) 100%)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },

        avatar: {
            margin: theme.spacing(1),
            backgroundColor: '#023575',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
            padding: '15px',
            
        },
        submit: {
            margin: theme.spacing(2, 0, 2),
            fontWeight: 'bold',

            backgroundColor: '#ff5656',
            '&:hover': {
                backgroundColor: '#ff5656',

            }
        },
    });
});
const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
        <TextField
            error={!!(touched && error)}
            helperText={touched && error}
            {...inputProps}
            {...props}
            fullWidth
        />
    );
const MyLoginPage = ({ theme }) => {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [base, setBase] = useState('');
    const classes = styles();
    var login = useLogin();
    const notify = useNotify();
    const submit = (e) => {
        e.preventDefault();
        if ((base.toUpperCase() != 'SRA_PRD') && (base.toUpperCase() != 'SRA_TRN')) {
            notify('Base inválida');
            setLoading(false)
        } else {
            setLoading(true);
            login({ username, password, base })
                .then(() => { setLoading(false) })
                .catch(() => { notify('Login ou Senha inválidos '); setLoading(false) });
        }

    };

    return (
        <div className={classes.title}>
        <Container component="main" maxWidth="xs" className={classes.card} >
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    CHRONOS
            </Typography>
                <form className={classes.form}  onSubmit={submit}> 
                    <TextField 
                        variant="outlined"
                        margin="dense"
                        size="small"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username} onChange={e => setUsername(e.target.value)}

                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password} onChange={e => setPassword(e.target.value)}
                    />

                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        required
                        fullWidth
                        name="base"
                        label="Data Base"
                        type="text"
                        id="base"
                        autoComplete="current-base"
                        value={base} onChange={e => setBase(e.target.value)}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}

                    >
                        {loading && (
                            <CircularProgress
                                className={classes.icon}
                                size={18}
                                thickness={2}
                            />
                        )}
                        entrar
                </Button>

                </form>
            </div>
        </Container>
        <Notification />

    </div>
    );
};
const LoginWithTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <MyLoginPage {...props} />
    </MuiThemeProvider>
);

export default LoginWithTheme;