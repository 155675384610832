import Tree from 'react-tree-graph';
// import 'react-tree-graph/dist/style.css'
import React from 'react';
import './Tree.css';
import api from '../../../services/api';
import { Button } from "@material-ui/core";

export default class TreeGraf extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cd_usuario: this.props.cd_usuario,
            unidades: {},
            conveio: {},
            plano: {},
            data: {}
        }
        this.getUnidades();
    }

    getUnidades = async () => {
        var unidades = []
        var filhos = []
        var teste = await api.post('/unidadeUsuarioTree', {
            cd_usuario: this.state.cd_usuario
        })
            .then(async (response) => {
                for (var i = 0; i < response.data.length; i++) {
                    filhos = await this.getConvenio(response.data[i].cd_units);
                    unidades = unidades.concat({
                        name: response.data[i].name,
                        children: filhos
                    })
                    if (unidades.length == response.data.length) {
                        this.setState({ data: { name: 'SRA', children: unidades } });
                        this.props.loadingFalse();
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    getConvenio = async (cd_unidade) => {
        var convenio = []
        var filhos = []
        await api.post('/convenioLocalTree', {
            cd_usuario: this.state.cd_usuario,
            cd_unidade: cd_unidade
        })
            .then(async (response) => {
                for (var i = 0; i < response.data.length; i++) {
                    filhos = await this.getPlano(response.data[i].cd_convenio);
                    convenio = convenio.concat({
                        name: response.data[i].name,
                        children: filhos
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        return convenio;

    }
    getPlano = async (cd_convenio) => {
        var plano = []
        await api.post('/planoLocalTree', {
            cd_usuario: this.state.cd_usuario,
            cd_convenio: cd_convenio
        })
            .then(async (response) => {
                for (var i = 0; i < response.data.length; i++) {
                    plano = plano.concat({
                        name: response.data[i].name
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        return plano;
    }
    render() {
        return (
            <div className="custom-container">
                <Tree
                    data={this.state.data}
                    height={600}
                    width={1000}
                    svgProps={{
                        className: 'custom'
                    }} />
            </div>
        )
    }

}
