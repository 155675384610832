import React from 'react';
import { Paper, TextField, Button, withStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Switch, Select, MenuItem, InputLabel, IconButton, CircularProgress } from '@material-ui/core';
import { Check, Search } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import api from '../../services/api';
import Datapicker from './Datapicker';

class PacienteSimplificado extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: true,
            nm_paciente: "",
            localidade: "",
            telefone: '',
            dddTelefone: '',
            dddCelular: '',
            celular: '',
        }
        this.myRefData = React.createRef();
    }
    componentDidMount() {
        this.selectedPaciente(this.props.paciente)
    }

    handleChange = name => (event) => {
        this.setState({ [name]: event.target.value });
    }
    selectedPaciente = (cd_paciente) => {
        api.post('/paciente', {
            paciente: cd_paciente
        }).then(response => {
            this.myRefData.current.setData(response.data[0].dt_nascimento)
            const estado = { value: response.data[0].cd_uf, label: response.data[0].cd_uf }
            const cidade = { value: response.data[0].cd_cidade, label: response.data[0].nm_cidade }
            this.setState({
                cd_paciente: cd_paciente,
                endereco: response.data[0].ds_endereco,
                nm_mae: response.data[0].nm_mae,
                nm_paciente: response.data[0].nm_paciente,
                dddTelefone: response.data[0].nr_ddd_fone,
                telefone: response.data[0].nr_fone,
                dddCelular: response.data[0].nr_ddd_celular,
                celular: response.data[0].nr_celular,
                numero: response.data[0].nr_endereco,
                bairro: response.data[0].nm_bairro,
                cpf: response.data[0].nr_cpf,
                cep: response.data[0].nr_cep,
                sexo: response.data[0].tp_sexo,
                dataNascimento: response.data[0].dt_nascimento,
                email: response.data[0].email,
                complemento: response.data[0].ds_complemento,
                selectedEstado: estado,
                selectedCidade: cidade,
            })
        })
    }
    save = () => {
        try {
            const formData = new FormData();
            formData.append("file", this.state.file);
            api.post('/storeSignatureUser', formData, {
                headers: { "Content-Type": "multipart/form-data" },
            }).then(respSignature => {
                api.post('http://144.126.222.103:8000/api/user/register', {
                    name: this.state.nome,
                    email: this.state.email,
                    password: this.state.password,
                    c_password: this.state.c_password,
                    signatureUrl: respSignature.data.signature,
                }).then(resp => {
                    this.props.save()
                })
            })
        } catch (error) {
            console.log(error)
        }
    }
    handleDataNasc = data => {
        this.setState({ dataNascimento: data })
    }
    handleFileSelect = (event) => {
        console.log(event.target.files[0])
        this.setState({ file: event.target.files[0] })
    }
    save = () => {
        api.put('/paciente', {
            paciente: this.state.cd_paciente,
            nm_paciente: this.state.nm_paciente.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
            nm_mae: this.state.nm_mae,
            dt_nascimento: this.state.dataNascimento,
            nr_ddd_fone: this.state.dddTelefone,
            nr_fone: this.state.telefone,
            nr_ddd_celular: this.state.dddCelular,
            nr_celular: this.state.celular,
            tp_sexo: this.state.sexo,
            cidade: this.state.selectedCidade.value,
            nr_endereco: this.state.numero,
            endereco: this.state.endereco,
            complemento: this.state.complemento,
            cpf: this.state.cpf,
            cep: this.state.cep,
            bairro: this.state.bairro,
            empresa: this.state.empresa !== undefined ? this.state.empresa : 1,
            estado: this.state.selectedEstado.value,
            email: this.state.email,
            usuario: localStorage.getItem('login')
        }).then(response => {
            this.props.savePacienteSimplificao(
                this.state.nm_paciente.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase(),
                this.state.dataNascimento,
                this.state.dddTelefone,
                this.state.telefone,
                this.state.dddCelular,
                this.state.celular,
            )
            this.setState({ alertConcluse: true, alertTitle: 'Sucesso', alertBody: 'Paciente alterado com sucesso' });
        }).catch(error => {
            if (error.response.data.includes("unique constraint")) {
                this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: 'CPF já preenchido por outro paciente' });
            } else {
                this.setState({ alertConcluse: true, alertTitle: 'Erro', alertBody: `${error.response.data}` });
            }
        })
    }
    render() {
        const { classes } = this.props
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.props.close()}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle id="form-dialog-title">Paciente</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            className={classes.text}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nome"
                            value={this.state.nm_paciente}
                            onChange={this.handleChange('nm_paciente')}
                        />
                        <Datapicker handleDataNasc={this.handleDataNasc} ref={this.myRefData} />
                        <div className={classes.divFones}>
                            <TextField
                                shrink
                                className={classes.inputDDD}
                                label="DDD"
                                variant="standard"
                                size="small"
                                type="number"
                                value={this.state.dddTelefone}
                                onChange={this.handleChange('dddTelefone')}
                            />
                            <TextField
                                shrink
                                className={classes.inputFone}
                                label="Telefone"
                                variant="standard"
                                size="small"
                                type="number"
                                value={this.state.telefone}
                                onChange={this.handleChange('telefone')}
                            />
                        </div>
                        <div className={classes.divFones}>
                            <TextField
                                shrink
                                className={classes.inputDDD}
                                label="DDD"
                                variant="standard"
                                size="small"
                                type="number"
                                value={this.state.dddCelular}
                                onChange={this.handleChange('dddCelular')}
                            />
                            <TextField
                                shrink
                                className={classes.inputFone}
                                label="Celular"
                                variant="standard"
                                size="small"
                                type="number"
                                value={this.state.celular}
                                onChange={this.handleChange('celular')}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.close()} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => this.save()} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const styles = theme => ({
    formControl: {
        width: 200,
        margin: '13px'
    },
    text: {
        margin: theme.spacing(2),
    },
    divFones: {
        margin: theme.spacing(2),
    },
    textId: {
        margin: theme.spacing(2),
        width: 40,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        display: 'flex'
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -4,
        left: -8,
        zIndex: 1,
    },
    searchButton: {
        marginTop: '20px'
    },
    inputDDD: {
        width: '50px',
        // marginTop: '5px'
    },
    inputFone: {
        // margin: '5px',
        width: '155px',
    },
})

export default withStyles(styles)(PacienteSimplificado)