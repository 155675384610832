import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import './Reset.css'
import TableReset from './TableReset';
export default class ResetSenha extends React.Component {
    
    render() {
        return (
            <Paper className="remarcar-paper">
                <div className="div-table">
                    <TableReset />
                </div>
            </Paper >
        );
    }

}

