import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import brLocate from '@fullcalendar/core/locales/pt-br';
import api from '../services/api'
import Horarios from './Horarios-Drawer'
import './main.scss'
import Button from '@material-ui/core/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Arrow from '@material-ui/icons/ArrowBack';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import Fab from "@material-ui/core/Fab";
import Timer from 'react-compound-timer';
import { format } from 'date-fns';
import Alert from './Alertas/Alert';
import Confirm from './Alertas/Confirm';


export default class DemoApp extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.queue)

  }
  calendarComponentRef = React.createRef()
  horariosRef = React.createRef();
  state = {
    calendarWeekends: true,
    calendarEvents: [],
    info_selecionado: [],
    exames_selecionados: [],
    drawer: false,
    unidade: [],
    convenio: null,
    exame: [],
    dataSelecionada: null,
    plano: [],
    nome: null,
    telefone: null,
    loadingRefresh: false,
    success: false,
    cd_paciente: null,
    alert: false,
    alertTitle: null,
    alertBody: null,
    confirm1: false,
    confirm2: false,
    confirm3: false,
    confirmTitle: null,
    confirmBody: null,
    comentario: null
  }

  handleCloseAlert = () => {
    this.setState({ alert: false });
  };

  handleCloseConfirmF = async () => {
    await this.setState({ confirm1: false, confirm2: false, confirm3: false });
    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Operação cancelada!' })
    this.props.clickLoad();
  }

  handleCloseConfirmT1 = async () => {
    //Volta calendario
    await this.limparHorariosMarcadosVoltar()
    this.setState({ confirm1: false })
  };

  handleCloseConfirmT2 = async () => {
    //SearchExameDisponivel
    this.setState({ calendarEvents: [] })
    await this.examesDisponiveis(this.state.unidade, this.state.convenio, this.state.exame, this.state.plano, this.state.nome, this.state.telefone)
    this.setState({ confirm2: false });
  };

  handleCloseConfirmT3 = async () => {
    //SearchExameRefresh
    await this.examesDisponiveis(this.state.unidade, this.state.convenio, this.state.exame, this.state.plano, this.state.nome, this.state.telefone)
    this.setState({ confirm3: false });
  };
  voltaFullCalendar = () => {
    this.setState({ confirm1: true, confirmTitle: "Atenção", confirmBody: "Os horários selecionados serão descartados, deseja prosseguir ?" });
  }
  //
  onClickSelect = (infoSelecionado, exame) => {
    this.setState({ info_selecionado: this.state.info_selecionado.concat(infoSelecionado), exames_selecionados: exame });
  }

  iniciarLoad = () => {
    this.setState({ loadingRefresh: true })
  }

  atualizarMes = () => {
    this.props.clickReload();
  }
  limparHorariosMarcados = () => {
    this.state.info_selecionado.map(s => {
      api.post('/excluirAgendamento', {
        it_ini: s.it_ini,
        it_fi: s.it_fim,
        agd_central: s.agenda,
      })
    })
    this.setState({ alert: true, alertTitle: 'Alerta', alertBody: 'Sessão expirada' });
    this.setState({ drawer: false })
    this.props.alterDrawer(false)
  }

  limparHorariosMarcadosVoltar = () => {
    this.state.info_selecionado.map(s => {
      api.post('/excluirAgendamento', {
        it_ini: s.it_ini,
        it_fi: s.it_fim,
        agd_central: s.agenda,
      })
    })
    this.setState({ drawer: false })
    this.props.alterDrawer(false)
  }

  getDateMonth = () => {
    let calendarApi = this.calendarComponentRef.current.getApi()
    var date = calendarApi.getDate()
    return date.getMonth() + 1
  }

  examesDisponiveis(unidade, convenio, exame, plano, nome, telefone) {
    var selecionados = new Array([this.state.info_selecionado])
    this.state.info_selecionado.map(s => {
      api.post('/excluirAgendamento', {
        it_ini: s.it_ini,
        it_fi: s.it_fim,
        agd_central: s.agenda,
      })
    })
    this.setState({ unidade: unidade, convenio: convenio, exame: exame, plano: plano, nome: nome, telefone: telefone, drawer: false })

    this.props.alterDrawer(false)
    for (var i = 1; i <= 2; i++) {
      exame.forEach(element => {
        var pos = i
        api.post('/diasLivres', {
          mes: this.consultaDoisMesesM(this.state.drawer ? new Date(this.state.dataSelecionada).getMonth() + 1 : this.getDateMonth(), i === 2 ? true : false),
          ano: this.consultaDoisMesesA(this.state.drawer ? new Date(this.state.dataSelecionada).getMonth() + 1 : this.getDateMonth(), new Date().getFullYear(), i === 2 ? true : false),
          cd: element.value,
          convenio: convenio.value,
          unidade: unidade.cd_unidade_atendimento,
        })
          .then((response) => {
            var resultado
            if (response.data.indexOf(",") != -1) {
              var array = JSON.parse("[" + response.data + "]");
              resultado = array.map(datas => {
                return { "title": element.ds_item_agendamento, "date": datas }
              })
            }
            else {
              resultado = { "title": element.ds_item_agendamento, "date": response.data }
            }
            this.setState({ calendarEvents: this.state.calendarEvents.concat(resultado) })
            if (pos == 2) {
              this.props.clickLoad()
            }
          })
          .catch(error => {
            console.log(error)
            // this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
          });
      });
    }
  }
  consultaDoisMesesM = (mes, boo) => {
    if (mes === 12 && boo === true) {
      return 1
    } else if (boo === false) {
      return mes
    }
    else {
      return mes + 1
    }
  }
  consultaDoisMesesA = (mes, ano, boo) => {
    if (mes === 12 && boo === true) {
      return ano + 1
    } else {
      return ano
    }
  }
  insertComentario = comentario => {
    this.setState({ comentario: comentario })
  }
  searchExameDisponivel = (unidade, convenio, exame, plano, nome, telefone, cd_paciente, dtNasc) => {
    if (this.state.drawer === true) {
      this.setState({ calendarEvents: [] })
      this.setState({ unidade: unidade, convenio: convenio, exame: exame, plano: plano, nome: nome, telefone: telefone, cd_paciente: cd_paciente, dtNasc: dtNasc })

      this.setState({ confirm2: true, confirmTitle: "Atenção", confirmBody: "Os horários selecionados serão descartados, deseja prosseguir ?" });
    } else {
      this.setState({ calendarEvents: [] })
      this.setState({ calendarEvents: [], unidade: unidade, convenio: convenio, exame: exame, plano: plano, nome: nome, telefone: telefone, cd_paciente: cd_paciente, dtNasc: dtNasc, drawer: false })
      this.props.alterDrawer(false)
      for (var i = 1; i <= 2; i++) {
        exame.map(element => {
          var pos = i
          api.post('/diasLivres', {
            mes: this.consultaDoisMesesM(this.state.drawer ? new Date(this.state.dataSelecionada).getMonth() + 1 : this.getDateMonth(), i == 2 ? true : false),
            ano: this.consultaDoisMesesA(this.state.drawer ? new Date(this.state.dataSelecionada).getMonth() + 1 : this.getDateMonth(), new Date().getFullYear(), i == 2 ? true : false),
            cd: element.value,
            convenio: convenio.value,
            unidade: unidade.cd_unidade_atendimento,
          })
            .then((response) => {
              var resultado
              if (response.data.indexOf(",") != -1) {
                var array = JSON.parse("[" + response.data + "]");
                resultado = array.map(datas => {
                  return { "title": element.ds_item_agendamento, "date": datas }
                })
              }
              else {
                resultado = { "title": element.ds_item_agendamento, "date": response.data }
              }
              this.setState({ calendarEvents: this.state.calendarEvents.concat(resultado) })
              if (pos == 2) {
                this.props.clickLoad()
              }
            })
            .catch(error => {
              console.log(error)
              // this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        });
      }

    }
  }
  searchExameDisponivelRefresh = (unidade, convenio, exame, plano, nome, telefone, cd_paciente, dtNasc) => {
    if (this.state.drawer === true) {
      this.setState({ calendarEvents: [] })
      this.setState({ unidade: unidade, convenio: convenio, exame: exame, plano: plano, nome: nome, telefone: telefone, cd_paciente: cd_paciente, dtNasc: dtNasc })

      this.setState({ confirm2: true, confirmTitle: "Atenção", confirmBody: "Os horários selecionados serão descartados, deseja prosseguir ?" });
    } else {
      this.setState({ calendarEvents: [] })
      this.setState({ calendarEvents: [], unidade: unidade, convenio: convenio, exame: exame, plano: plano, nome: nome, telefone: telefone, cd_paciente: cd_paciente, drawer: false, dtNasc: dtNasc })
      this.props.alterDrawer(false)
      for (var i = 1; i <= 2; i++) {
        exame.map(element => {
          var pos = i
          api.post('/diasLivres', {
            mes: this.consultaDoisMesesM(this.state.drawer ? new Date(this.state.dataSelecionada).getMonth() + 1 : this.getDateMonth(), i === 2 ? true : false),
            ano: this.consultaDoisMesesA(this.state.drawer ? new Date(this.state.dataSelecionada).getMonth() + 1 : this.getDateMonth(), new Date().getFullYear(), i === 2 ? true : false),
            cd: element.value,
            convenio: convenio.value,
            unidade: unidade.cd_unidade_atendimento,
          })
            .then((response) => {
              var resultado
              if (response.data.indexOf(",") != -1) {
                var array = JSON.parse("[" + response.data + "]");
                resultado = array.map(datas => {
                  return { "title": element.ds_item_agendamento, "date": datas }
                })
              }
              else {
                resultado = { "title": element.ds_item_agendamento, "date": response.data }
              }
              this.setState({ calendarEvents: this.state.calendarEvents.concat(resultado) })
              if (pos == 2) {
                this.props.stopLoadingR()
                this.setState({ loadingRefresh: false })
              }
            })
            .catch(error => {
              console.log(error)
              // this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        });
      }
    }
  }

  limparExames = () => {
    this.limparHorariosMarcadosVoltar()
    this.setState({ calendarEvents: [] })
  }

  handleDateClick = (arg) => {
    if (this.state.calendarEvents.length > 0) {
      this.setState({ drawer: true, dataSelecionada: arg.dateStr })
      this.props.alterDrawer(true)
    }
  }

  handleEventClick = (arg) => {
    var data = new Date(arg.event.start)
    var dataFinal = format(data, 'yyyy-MM-dd')
    // var dataFinal = data.getFullYear() + "-" + (data.getMonth() + 1) + "-" + (data.getDate() <= 9 ? "0" + data.getDate() : data.getDate())
    this.setState({ drawer: true, dataSelecionada: dataFinal })
    this.props.alterDrawer(true)
  }
  confirmacaoProsseguir = () => {
    this.setState({ exame: [], confirm2: true, confirmTitle: "Atenção", confirmBody: "Os horários selecionados serão descartados, deseja prosseguir ?" });
  }
  closeQueue = () => {
    this.props.closeQueue();
  }
  render() {
    const buttonClassname = clsx({
      ['buttonSuccess']: this.state.success,
    });
    return (
      <div className='demo-app'>
        {this.state.confirm1 && <Confirm title={this.state.confirmTitle} body={this.state.confirmBody} handleCloseConfirmF={this.handleCloseConfirmF} handleCloseConfirmT={this.handleCloseConfirmT1} />}
        {this.state.confirm2 && <Confirm title={this.state.confirmTitle} body={this.state.confirmBody} handleCloseConfirmF={this.handleCloseConfirmF} handleCloseConfirmT={this.handleCloseConfirmT2} />}
        {this.state.confirm3 && <Confirm title={this.state.confirmTitle} body={this.state.confirmBody} handleCloseConfirmF={this.handleCloseConfirmF} handleCloseConfirmT={this.handleCloseConfirmT3} />}
        {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
        {this.state.drawer ? <div><div className="buttonTimer"> <Button variant="contained" color="primary" onClick={this.voltaFullCalendar}>
          <Arrow />
        </Button>
          <div >
            <strong>Tempo para expirar sessão: </strong>
            <Timer
              initialTime={600000}
              direction="backward"
              checkpoints={[
                {
                  time: 0,
                  callback: () => this.limparHorariosMarcados(),
                },
              ]}
            >
              {() => (
                <React.Fragment>
                  <Timer.Minutes /> :
                  <Timer.Seconds />
                </React.Fragment>
              )}
            </Timer>
          </div>
        </div>
          <Horarios ref={this.horariosRef} itens={this.state.exame} queue={this.props.queue} closeQueue={this.closeQueue} clickSelecionado={this.onClickSelect} infoSelecionado={this.state.info_selecionado} selecionados={this.state.exames_selecionados} dataSelecionada={this.state.dataSelecionada} nome={this.state.nome} plano={this.state.plano} unidade={this.state.unidade} convenio={this.state.convenio} telefone={this.state.telefone} cd_paciente={this.state.cd_paciente} comentario={this.state.comentario} dtNasc={this.state.dtNasc} /> </div> :
          <div className='demo-app-calendar'>
            <div className='button-att'>
              <div className='wrapper'>
                <Fab
                  aria-label="refresh"
                  color="primary"
                  className={buttonClassname}
                  onClick={this.atualizarMes}
                >
                  {this.state.success ? <CheckIcon /> : <AutorenewIcon />}
                </Fab>
                {this.state.loadingRefresh && <CircularProgress size={65} className='fabProgress' />}
              </div>
            </div>
            <FullCalendar
              defaultView="dayGridMonth"
              customButtons={{
                myCustomButton: {
                  text: '>',
                  click: function () {
                  }
                }
              }}
              header={{
                left: 'none',
                center: 'title',
                right: 'prev, next, today'
              }}
              eventLimit={true}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
              locale={brLocate}
              height={600}
              themeSystem='cerulean'
              // timeZone={'America/Sao_paulo'}
              ref={this.calendarComponentRef}
              weekends={this.state.calendarWeekends}
              events={this.state.calendarEvents}
              dateClick={this.handleDateClick}
              validRange={{ start: new Date() }}
              eventClick={this.handleEventClick}
            />
          </div>
        }

      </div>
    )
  }


}