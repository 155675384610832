import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function DialogAnexo(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [files, setFiles] = React.useState([]);
    const [filesPreviews, setFilesPreviews] = React.useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const onDrop = (acceptedFiles => {
        console.log(acceptedFiles)
        const files = acceptedFiles
        setFiles(acceptedFiles)
        // setFilesPreviews(
        //     acceptedFiles.map((file) =>
        //         Object.assign(file, {
        //             preview: URL.createObjectURL(file)
        //         })
        //     )
        // );
    })

    const removeFile = (file) => {
        let newFiles = files
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles);
    }

    const handleSave = () => {
        console.log(files)
        props.handleSave(files)
    }
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open max-width dialog
            </Button>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Anexo</DialogTitle>
                <DialogContent>
                    <Dropzone onDrop={onDrop}
                        accept="image/*" >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <section className="container">
                                    <input {...getInputProps()} />
                                    <p style={{ color: '#0078F0', fontStretch: 'semi-condensed', fontSize: '10px' }}>Clique para anexar documentos</p>
                                    <aside>
                                        <h4 style={{ fontStretch: 'semi-condensed', fontSize: '10px' }}>Arquivos</h4>
                                        <ul>{files.map(file => (
                                            <li key={file.path}>
                                                {file.path} - {file.size} bytes{" "}
                                                <button className="drop" onClick={() => removeFile(file)}>Remover</button>
                                            </li>
                                        ))}</ul>
                                    </aside>
                                    {/* {files.length > 0 && <button className="drop" onClick={this.removeAll}>Remover todos</button>} */}
                                </section>
                            </div>
                        )}
                    </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Voltar
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Salvar Anexo
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}