import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import api from '../../services/api'
import { green } from '@material-ui/core/colors';
import Alert from '../Alertas/Alert';
import { format } from 'date-fns';
import TableConfirmaContato from './TableConfirmaContato';
const style = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing.unit,
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        width: '1000px',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',

    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    dialogPaper: {
        minHeight: '380px',
        maxHeight: '380px',
    },
    dialogTextPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    root: {
        marginLeft: '16px'
    },
    rootSelect: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    rootSelectExames: {
        margin: theme.spacing(1),
        width: '100%'
    },
    rootSelectInput: {
        margin: theme.spacing(1),
        width: 195
    },
    inputAuto: {
        marginTop: '4px',
        width: '360px'
        // [theme.breakpoints.up('md')]: {
        //     marginTop: '8px',
        //     width: '360px'
        // },
        // [theme.breakpoints.down('md')]: {
        //     marginTop: '8px',
        //     width: '195px',
        // }
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignSelf: 'flex-end',
    },
    inputText: {
        margin: theme.spacing(1),
        width: '166px',
    },
    inputHora: {
        margin: theme.spacing(1),
        width: '150px',
    },
    inputDate: {
        // margin: theme.spacing(1),
        width: '150px'
    },
    divForm: {
        marginTop: '16px',
    },
    divAutoSuggest: {
        display: 'flex'
    },
    inputSelect: {
        margin: theme.spacing(1),
        width: '250px',
    },
    inputSelectExames: {
        width: '98%',
    }
})

const ajusteDate = (data) => {
    var d = new Date(data);
    d.setDate(d.getDate() + 1);
    return d
}
const dataAgendaInput = data => {
    var data_agenda = new Date(data)
    return format(ajusteDate(new Date(data)), "yyyy-MM-dd")
}
const dataAgenda = data => {
    var data_agenda = new Date(data)
    return format(new Date(data), "yyyy-MM-dd")
}
class DialogConfirmaContato extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        open:true
    };
    render() {
        const { classes } = this.props
        return (
            <div >
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <Dialog
                    open={this.state.open}
                    aria-labelledby="form-dialog-title"
                    fullWidth='true'
                    maxWidth='md'
                >
                    <DialogContent>
                        <TableConfirmaContato it_agenda={this.props.it_agenda}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.close} color="primary">
                            Ok
                        </Button>
                        {/* <Button onClick={this.click} color="primary">
                            Salvar
                        </Button> */}
                    </DialogActions>
                </Dialog>
            </div >
        );

    }
}

DialogConfirmaContato.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(style)(DialogConfirmaContato)