import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import api from '../services/api'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import { format } from 'date-fns';
import Alert from './Alertas/Alert';
import AlertConcluse from './Alertas/Concluse';

const styles = theme => ({
    formControl: {
        width: '120px'
    },
    containerInputs: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '20px',
        // justifyContent: 'center',
        marginTop: 12,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing.unit,
    },
    container: {
        position: 'relative',
        width: '100%',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        width: '600px',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',

    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    dialogPaper: {
        // minHeight: '380px',
        maxHeight: '380px',
    },
    dialogTextPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    input: {
        marginRight: '10px'
    },
    inputNome: {
        [theme.breakpoints.up('md')]: {
            width: '360px',
            marginRight: '10px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '195px',
            marginRight: '10px'
        }
    },
    inputDDD: {
        width: '60px',
        marginRight: '10px'
    },
    inputEmail: {
        [theme.breakpoints.up('md')]: {
            marginRight: '10px',
            width: '400px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '195px',
            marginRight: '10px'
        }

    },
    inputPass: {
        [theme.breakpoints.up('md')]: {
            marginRight: '10px',
            width: '400px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '195px',
            marginRight: '10px'
        }
    }
});

class Cadastro extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        dtNasc: new Date(), //format(new Date(),'yyyy-MM-dd')
        alert: false,
        alertConcluse: false
    };

    closePergunta = () => {
        this.setState({ perguntas: false })
    }
    onChangeData = e => {
        this.setState({ dtNasc: e });
    };
    handleChange = name => (event) => {
        this.setState({ [name]: event.target.value });
    };
    handleChangeText = name => (event) => {
        this.setState({ [name]: event.target.value.toUpperCase() });
    };
    handleChangeCredenciais = name => (event) => {
        this.setState({ [name]: event.target.value });
    };
    removeAcentos = str => {
        str = str.replace(/[ÀÁÂÃÄÅ]/g, "A");
        str = str.replace(/[àáâãäå]/g, "a");
        str = str.replace(/[ÈÉÊË]/g, "E");
        str = str.replace(/[óòõôö]/ui, "O");
        str = str.replace(/[úùûü]/ui, "U");
        str = str.replace(/[']/g, "");
        return str;
    }
    submit = (e) => {
        e.preventDefault();
        api.post('/inserirPacienteMv', {
            nm_paciente: this.state.nome,
            dt_nascimento: format(this.state.dtNasc, 'yyyy-MM-dd'),
            nr_fone: this.state.nr_fone,
            nr_ddd_fone: this.state.nr_ddd,
            nr_cpf: this.state.cpf,
            tp_sexo: this.state.sexo,
            cd_multi_empresa: '1',
        })
            .then((response) => {
                // if(response.statusText)
                if (response.status == 277) {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: `Paciente já cadastrado como: ${response.data.nome}` })
                } else {
                    this.props.setCdPaciente(response.data.cd_paciente, this.removeAcentos(this.state.nome), this.state.cpf, this.state.dtNasc, this.state.nr_fone)
                    this.setState({ alertConcluse: true, alertTitle: 'Alerta', alertBody: 'Cadastrado com Cadastrado com sucesso' });
                    this.props.handleClose()
                }
            })
            .catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
    }
    render() {
        const { classes } = this.props;
        const { stateSuggestions } = this.state
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={e => this.setState({ alert: false })} />}
                {this.state.alertConcluse && <AlertConcluse title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleConcluseAlert} />}
                <Dialog
                    open={true}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth='md'
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    classes={{ paper: classes.dialogPaper }}
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="form-dialog-title">Cadastro de Paciente</DialogTitle>
                    <form onSubmit={this.submit}>

                        <DialogContent>
                            <div>
                                <DialogContentText>

                                </DialogContentText>
                            </div>
                            <Paper className={classes.containerInputs}>
                                <div>
                                    <TextField
                                        required
                                        label='Nome completo'
                                        id="nome"
                                        placeholder="Digite o Nome"
                                        className={classes.inputNome}
                                        value={this.state.nome}
                                        onChange={this.handleChangeText('nome')}
                                    />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disabled={this.state.drawer}
                                            disableToolbar
                                            className={classes.input}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            label="Data de Nascimento"
                                            value={this.state.dtNasc}
                                            onChange={this.onChangeData}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <TextField
                                        // required
                                        label='CPF'
                                        id="cpf"
                                        placeholder="Digite o CPF"
                                        className={classes.input}
                                        inputProps={{
                                            minlengt: 11,
                                            maxlength: 11
                                        }}
                                        value={this.state.cpf}
                                        onChange={this.handleChangeText('cpf')}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        required
                                        label='DDD'
                                        id="nome"
                                        placeholder="ddd"
                                        type="tel"
                                        className={classes.inputDDD}
                                        inputProps={{
                                            minlengt: 2,
                                            maxlength: 2
                                        }}
                                        value={this.state.nr_ddd}
                                        onChange={this.handleChangeText('nr_ddd')}
                                    />
                                    <TextField
                                        required
                                        label='CELULAR'
                                        id="nome"
                                        placeholder="Digite o celular"
                                        type="tel"
                                        className={classes.input}
                                        inputProps={{
                                            minlengt: 8,
                                            maxlength: 9
                                        }}
                                        value={this.state.nr_fone}
                                        onChange={this.handleChangeText('nr_fone')}
                                    />
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-controlled-open-select-label">Sexo</InputLabel>
                                        <Select
                                            labelId="demo-controlled-open-select-label"
                                            id="demo-controlled-open-select"
                                            value={this.state.sexo}
                                            onChange={this.handleChange('sexo')}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={'M'}>Masculino</MenuItem>
                                            <MenuItem value={'F'}>Feminino</MenuItem>
                                            <MenuItem value={'O'}>Outros</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </Paper>
                        </DialogContent>
                        <DialogActions classes={classes}>
                            <Button variant="contained" onClick={this.props.handleClose} color="primary">Cancelar</Button>
                            <Button variant="contained" type="submit" color="primary"> Confirmar</Button>
                        </DialogActions>
                    </form>

                </Dialog>
            </div>
        );

    }
}
Cadastro.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(styles)(Cadastro);