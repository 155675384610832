import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import api from '../../services/api';
import Alert from '../Alertas/Alert';



export default function ResponsiveDialog(props) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDateAut, handleDateChangeAut] = useState(new Date());
  const [selectedDateSol, handleDateChangeSol] = useState(new Date());
  const [numeroGuia, setNumeroGuia] = useState('');
  const [senha, setSenha] = useState('');
  const [cd_guia, setGuia] = useState('');

  React.useEffect(() => {
    if (props.cd_itped_rx != undefined) {
      getGuiIt();
    }
    const guias = props.guias.filter(g => g.indexGuia === props.indexGuia);
    if (guias.length > 0) {
      setNumeroGuia(guias[0].numeroGuia);
      setSenha(guias[0].senha);
      handleDateChangeSol(new Date(`${guias[0].dateSol} `));
      handleDateChangeAut(new Date(`${guias[0].dateAut} `));
    }
  }, []);
  const getGuiIt = () => {
    api.post('/informacoesGuiaItem', {
      item: props.cd_itped_rx
    }).then(resp => {
      setNumeroGuia(resp.data[0].nr_guia);
      setSenha(resp.data[0].cd_senha);
      setGuia(resp.data[0].cd_guia);
      resp.data[0].dt_solicitacao != null && handleDateChangeSol(new Date(`${resp.data[0].dt_solicitacao} `));
      resp.data[0].dt_autorizacao != null && handleDateChangeAut(new Date(`${resp.data[0].dt_autorizacao} `));
    })
  }
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    props.close();
  };
  const onChangeDataAut = e => {
    handleDateChangeAut(e);
  };
  const onChangeDataSol = e => {
    handleDateChangeSol(e);
  };
  const handleOk = () => {
    if (props.cd_itped_rx != undefined) {
      api.post('/updateGuiaItPed', {
        guia: cd_guia,
        nr_guia: numeroGuia,
        senha: senha,
        dt_autorizacao: format(selectedDateAut, "yyyy-MM-dd"),
        dt_solicitacao: format(selectedDateSol, "yyyy-MM-dd")
      }).then(resp => {
        var guia = {
          numeroGuia,
          senha,
          dateSol: format(selectedDateSol, "yyyy-MM-dd"),
          dateAut: format(selectedDateAut, "yyyy-MM-dd"),
          indexGuia: props.indexGuia,
          exame_guia: props.exame_guia,
          cd_ped_rx: props.pedido,
          cd_itped_rx: props.cd_itped_rx
        }
        props.pedido == null && props.guiasEspecificas(guia)
        props.pedido != null && props.guiasEspecificasAtendido(guia)
      })
    } else {
      var guia = {
        numeroGuia,
        senha,
        dateSol: format(selectedDateSol, "yyyy-MM-dd"),
        dateAut: format(selectedDateAut, "yyyy-MM-dd"),
        indexGuia: props.indexGuia,
        exame_guia: props.exame_guia,
        cd_ped_rx: props.pedido,
      }
      props.pedido == null && props.guiasEspecificas(guia)
      props.pedido != null && props.guiasEspecificasAtendido(guia)
    }

  }
  const handleNumeroGuia = (event) => {
    setNumeroGuia(event.target.value)
  }
  const handleSenha = (event) => {
    setSenha(event.target.value);
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="responsive-dialog-title">{"Guia"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ alignItems: 'center', textAlign: 'center' }} >
            <h2>Preencha os dados da guia</h2>
            <Fragment>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data de Autorização"
                  value={selectedDateAut}
                  onChange={e => onChangeDataAut(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Fragment>
            <Fragment>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label="Data de Solicitação"
                  value={selectedDateSol}
                  onChange={e => onChangeDataSol(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Fragment>
            <TextField style={{ margin: '5px' }} label="Número de Guia" variant="standard" size="small" onChange={e => handleNumeroGuia(e)} value={numeroGuia} />
            <TextField style={{ margin: '5px' }} label="Senha da Guia" variant="standard" size="small" onChange={e => handleSenha(e)} value={senha} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: '#fff', backgroundColor: '#EF6351' }} autoFocus onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button style={{ color: '#fff', backgroundColor: '#EF6351' }} onClick={handleOk} color="primary" autoFocus>
            {props.cd_itped_rx != undefined ? 'ALTERAR' : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
