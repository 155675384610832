import axios from 'axios';

const api = axios.create({
  baseURL: 'http://api.chronos.vallions.com.br/api',
  // baseURL: 'http://127.0.0.1:8000/api',
  transformRequest: [(data) => {
    data.domain = localStorage.getItem('domain')
    var count = Object.keys(data).length;
    console.log(data)
    if (count > 1) {
      return data
    } else {
      return JSON.stringify(data)
    }
  }, ...axios.defaults.transformRequest],

})

export default api
