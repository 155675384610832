import MaterialTable from 'material-table';
import React from 'react';
import { format } from 'date-fns';
import api from '../../services/api';
import Alert from '../Alertas/Alert';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = theme => ({
    formControl: {
        // margin: theme.spacing(1),
        width: 168,
    },
});

class TableConfirma extends React.Component {
    constructor(props) {
        super(props);
        const { classes } = props
        this.state = {
            columns: [
                { title: 'Detalhes do contato', field: 'ds_contato' },
                {
                    title: 'Assunto', field: 'ds_assunto', editComponent: props => (
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                defaultValue="Assunto"
                                value={props.value}
                                onChange={e => props.onChange(e.target.value)}
                            >
                                <MenuItem value={'CHRONOS_CC'}>CONFIRMADO</MenuItem>
                                <MenuItem value={'CHRONOS_N_C'}>CONTATO SEM SUCESSO</MenuItem>
                            </Select>
                        </FormControl>
                    )
                },
                { title: 'Data do contato', field: 'hr_contato', editable: 'true', type: 'datetime' },
            ],
            data: [
            ]
        }
    }
    componentDidMount() {
        this.getConfirmaContato();
    }
    handleCloseAlert = () => {
        this.setState({ alert: false });
    };
    getConfirmaContato = () => {
        api.post('/selectRegistro', {
            cd_it_agenda: this.props.it_agenda
        }).then(response => {
            this.setState({ data: response.data })
        }).catch(error => {
            console.log(error)
        })
    }
    setConfirmaContato = (ds_contato, ds_assunto, contatos) => {
        api.post('/registroAgendamento', {
            cd_it_agenda: this.props.it_agenda,
            ds_contato: ds_contato,
            ds_assunto: ds_assunto,
            cd_usuario: localStorage.getItem('login'),
        }).then(response => {
            this.setState({ data: contatos });
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <MaterialTable
                    title="Registro de contato"
                    columns={this.state.columns}
                    data={this.state.data}
                    options={{}}
                    editable={{
                        onRowAdd: newData => {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    {
                                        if (newData.ds_contato !== '' && newData.ds_contato !== undefined) {
                                            var novo = {}
                                            if (newData.ds_assunto === 'CHRONOS_CC') novo.ds_assunto = 'CONFIRMADO'
                                            else if (newData.ds_assunto === 'CHRONOS_N_C') novo.ds_assunto = 'CONTATO SEM SUCESSO'
                                            novo.ds_contato = newData.ds_contato.toUpperCase()
                                            var contatos = this.state.data;
                                            this.setState({ data: [] })
                                            novo.hr_contato = format(new Date(), "dd/MM/yyyy HH:mm:ss")
                                            contatos.push(novo);
                                            this.setConfirmaContato(newData.ds_contato.toUpperCase(), newData.ds_assunto, contatos);
                                        } else {
                                            this.setState({ alert: true, alertTitle: 'Alerta', alertBody: 'Preencher o detalhes do contato' });
                                        }

                                    }
                                    resolve()
                                }, 1000)
                            })
                        },
                    }}
                />
            </div>
        )
    }
}
TableConfirma.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(useStyles)(TableConfirma)