import MaterialTable from 'material-table';
import React from 'react';
import api from '../../services/api';
import Alert from '../Alertas/Alert';

function createData(cd_it_agenda_central, hr_agenda, nm_paciente, ds_item_agendamento, nm_convenio, ds_con_pla, cd_usuario, cd_convenio, cd_con_pla, cd_item_agendamento, cd_multi_empresa, fone, sn_atendido, sn_encaixe, cd_paciente, ds_assunto, cd_agenda_central,dt_nascimento) {
    return { cd_it_agenda_central, hr_agenda, nm_paciente, ds_item_agendamento, nm_convenio, ds_con_pla, cd_usuario, cd_convenio, cd_con_pla, cd_item_agendamento, cd_multi_empresa, fone, sn_atendido, sn_encaixe, cd_paciente, ds_assunto, cd_agenda_central,dt_nascimento };
}

export default class Editable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loading: true,
            terminou: false,
            columns: [
                { title: 'Hora', field: 'hr_agenda', editable: 'never', type: 'datetime', filtering: false, },
                { title: 'Nome Paciente', field: 'nm_paciente', filtering: false },
                { title: 'Exame', field: 'ds_item_agendamento', editable: 'never', filtering: false },
                { title: 'Convênio', field: 'nm_convenio', editable: 'never', filtering: false },
                { title: 'Plano', field: 'ds_con_pla', editable: 'never', filtering: false },
                { title: 'Usuário', field: 'cd_usuario', editable: 'never', filtering: false },
                { title: 'cd_convenio', field: 'cd_convenio', hidden: true, filtering: false },
                { title: 'cd_con_pla', field: 'cd_con_pla', hidden: true, filtering: false },
                { title: 'cd_item_agendamento', field: 'cd_item_agendamento', hidden: true, filtering: false },
                { title: 'cd_multi_empresa', field: 'cd_multi_empresa', hidden: true, filtering: false },
                { title: 'cd_paciente', field: 'cd_paciente', hidden: true, filtering: false },
                { title: 'cd_agenda_central', field: 'cd_agenda_central', hidden: true, filtering: false },
                { title: 'ds_assunto', field: 'ds_assunto', lookup: { 'CHRONOS_CC': 'CONFIRMADO', 'CHRONOS_N_C': 'CONTATO SEM SUCESSO' }, hidden: true },
            ],
            data: [
                // { hr_agenda: '08:00 14/11/2019', nm_paciente: 'MAIOLENE DA SILVA', ds_item_agendamento: 'TC TORAX', nm_convenio: 'CAAPSML', ds_con_pla: 'PLANO UNICO', cd_usuario: 'MELINDA.KAIBARA'},
                // { hr_agenda: '08:00 14/11/2019', nm_paciente: 'MAIOLENE DA SILVA', ds_item_agendamento: 'TC TORAX', nm_convenio: 'CAAPSML', ds_con_pla: 'PLANO UNICO', cd_usuario: 'MELINDA.KAIBARA'},
            ],
            alert: false,
            alertTitle: null,
            alertBody: null
        }
        this.setExamesStart()
    }

    setExamesStart = async () => {
        let unidade = JSON.parse(localStorage.getItem('unidades'));
        if (unidade !== null && Object.keys(unidade).length > 0) {
            let unidades = [];
            unidade.forEach(element => {
                unidades.push(element.value)
            });
            let dthoje = new Date().getFullYear() + "-" + ((new Date().getMonth() + 1) < 10 ? "0" + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)) + "-" + (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate());
            unidades = unidades.toString();
            unidade.push({ value: unidades, label: "Todas as Unidades" })
            api.post('/agendamentosTodos', {
                data: dthoje,
                cd_empresa: unidade[unidade.length - 1].value,
            }).then(response => {
                const res = response.data.map(res => { return createData(res.cd_it_agenda_central, res.hr_agenda, res.nm_paciente, res.ds_item_agendamento, res.nm_convenio, res.ds_con_pla, res.cd_usuario, res.cd_convenio, res.cd_con_pla, res.cd_item_agendamento, res.cd_multi_empresa, res.fone, res.sn_atendido, res.sn_encaixe, res.cd_paciente, res.ds_assunto,res.cd_agenda_central,res.dt_nascimento) })
                this.setState({ data: res, loading: false })
            }
            ).catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        }

    }

    searchData = async res => {
        const resposta = await res.map(res => {
            return createData(res.cd_it_agenda_central, res.hr_agenda, res.nm_paciente, res.ds_item_agendamento, res.nm_convenio, res.ds_con_pla, res.cd_usuario, res.cd_convenio, res.cd_con_pla, res.cd_item_agendamento, res.cd_multi_empresa, res.fone, res.sn_atendido, res.sn_encaixe, res.cd_paciente, res.ds_assunto,res.cd_agenda_central,res.dt_nascimento)
        })
        this.setState({
            data: resposta,
        })
    }

    handleCloseAlert = () => {
        this.setState({ alert: false });
    };

    render() {
        const { loading } = this.state;
        return (
            <div>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}

                <MaterialTable
                    isLoading={loading}
                    title="Exames"
                    columns={this.state.columns}
                    data={this.state.data}
                    
                    style={
                        { fontSize: '0.475rem !important', display: 'grid', overflow: 'auto',}
                    }
                    options={{
                        // filtering: true,
                        draggable: false,
                        selection: true,
                        selectionProps: rowData => ({
                            sn_encaixe: rowData.sn_encaixe == 'S',
                            color: 'primary',
                        }),
                        cellStyle: {
                            maxWidth: '7em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '0.675rem'
                        },
                        headerStyle: {
                            zIndex: '0',
                            backgroundColor: '#023575', 
                            color: '#fff'
                        },
                        rowStyle: rowData => {
                            var style = {
                            }
                            if (rowData.sn_atendido == 'S') {
                                style = {
                                    borderLeftColor: '#1E90FF',
                                    borderLeftWidth: 12,
                                    borderLeftStyle: 'solid'
                                }
                            } else {
                                if (rowData.ds_assunto == 'CHRONOS_CC') {
                                    style = {
                                        borderLeftColor: '#9ACD32',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                } else if (rowData.ds_assunto == 'CHRONOS_N_C') {
                                    style = {
                                        borderLeftColor: '#191970',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                } else if (rowData.ds_assunto != '' && rowData.ds_assunto != null) {
                                    style = {
                                        borderLeftColor: '#BA55D3',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                } else {
                                    style = {
                                        borderLeftColor: '#fff',
                                        borderLeftWidth: 12,
                                        borderLeftStyle: 'solid'
                                    }
                                }
                            }


                            return style
                        }
                    }}
                    actions={[
                        {
                            tooltip: 'Desmarcar',
                            icon: 'delete',
                            onClick: (evt, data) => this.props.desmarcar()
                        },
                        {
                            tooltip: 'Remarcar',
                            icon: 'swap_vert',
                            onClick: (evt, data) => this.props.remarcar()
                        },
                        {
                            tooltip: 'Imprimir',
                            icon: 'print',
                            onClick: (evt, data) => this.props.imprimir()
                        },
                    ]}
                    onSelectionChange={(rows) => {
                        this.props.checked(rows.map(s => s.cd_it_agenda_central), rows)
                    }
                    }
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    {
                                        if (newData.nm_paciente.length > 0) {
                                            api.post('/alterarPaciente', {
                                                nome: newData.nm_paciente.toUpperCase(),
                                                cd: newData.cd_it_agenda_central,
                                            })
                                                .then(async (response) => {
                                                    newData.nm_paciente = newData.nm_paciente.toUpperCase()
                                                    const data = this.state.data;
                                                    const index = data.indexOf(oldData);
                                                    data[index] = newData;
                                                    await this.setState({ data: [] })

                                                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Paciente alterado com sucesso.' });
                                                    this.setState({ data: data, terminou: true }, () => resolve());
                                                });
                                        } else {
                                            const dataE = this.state.data;
                                            this.setState({ data: dataE, alert: true, alertTitle: 'Atenção', alertBody: 'Preencha o nome do paciente.' });
                                        }
                                    }
                                    resolve()
                                }, this.state.terminou)
                            }),
                    }}
                />
            </div>
        )
    }
}

