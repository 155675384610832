import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Paper, IconButton, TextField, Input, Select, InputLabel, FormControl, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';

import api from '../../services/api';
import Alert from '../Alertas/Alert';

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const crm = suggestion.ds_codigo_conselho;
    const uf = suggestion.cd_uf;
    const nome = suggestion.nm_prestador;
    const view = `${nome} | CRM: ${crm} | UF: ${uf}`
    const matches = match(suggestion.nm_prestador, query);
    const parts = parse(view, matches);
    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map(part => (
                    <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                        {part.text}
                    </span>
                ))}
            </div>
        </MenuItem>
    );
}
const useStyles = theme => ({
    paper: {
        borderRadius: '15px',
        boxShadow: '1px 4px 1px  #d9d9d9'
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '5px',
    },

    med: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #ddd',
        padding: '10px',
        borderRadius: '5px',
    },

    btn: {
        marginTop: '10px'
    },

    button: {
        backgroundColor: '#ff5656',
        color: '#fff',
        padding: '5px',
        margin: '5px',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#ff5656',
        },
    },

    textfield: {
        marginLeft: '10px',
    },

    setor: {
        marginTop: '10px',
        border: '1px solid #ddd',
        padding: '10px',
        borderRadius: '5px',
    },
    inputSelect: {
        marginTop: '15px',
        width: '250px',
        margin: '5px'
    },
    input: {
        width: '450px',
    },
    root: {
        margin: '5px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        position: 'relative',
        width: '250px',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        width: '500px',
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',

    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    label: { width: '250px', alignSelf: 'center' }
})

class DialogMedSetor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            medSol: '',
            cdMedSol: '',
            crmMedSol: '',
            suggestionsMedSol: [],

            selectedSetor: [],
            setor: props.setorExec,
            setor_name: props.setor_name,

            medSolSetor: [],

            alert: false
        }
    }
    handleClose = () => {
        this.props.close();
    };

    renderInputComponentMedSol = (inputProps) => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <FormControl className={classes.input}>
                <InputLabel htmlFor="standard-adornment-password">
                    Médico Solicitante
              </InputLabel>
                <Input
                    id="standard-adornment-MedSol"
                    value={this.state.medSol}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                    }}
                    {...other}
                // onChange={handleChange("valueName")}
                />
            </FormControl >
        );
    }
    handleSuggestionsClearRequestedMedSol = () => {
        this.setState({
            suggestionsMedSol: []
        });
    }
    handleSuggestionsFetchRequestedMedSol = ({ value }) => {
        if (Number.isInteger(parseInt(value))) {
            this.searchCrmMed(value, 2);
        } else {
            this.searchNomeMed(value.toUpperCase(), 2);
        }
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        this.setState({
            medSol: value.toUpperCase()
        });
        if (this.state.medSol.length > inputValue.length) {
            this.setState({
                medSol: value.toUpperCase()
            });
        }
    }
    getSuggestionValueMedSol = (suggestion) => {
        this.setState({ cdMedSol: suggestion.cd_prestador, crmMedSol: suggestion.ds_codigo_conselho })
        return suggestion.nm_prestador;
    }
    handleChange = name => (event, { newValue }) => {
        this.setState({ [name]: newValue.toUpperCase() });
    }
    searchNomeMed = (value, tp) => {
        if (value.length > 2) {
            api.post('/selectNomeMedico', {
                name: value
            }).then(response => {
                tp == 1 ? this.setState({ suggestions: response.data }) : this.setState({ suggestionsMedSol: response.data })
            }).catch(error => console.log(error))
        }

    }
    searchCrmMed = (value, tp) => {
        if (value.length > 3) {
            api.post('/selectCrmMedico', {
                crm: value
            }).then(response => {
                tp == 1 ? this.setState({ suggestions: response.data }) : this.setState({ suggestionsMedSol: response.data })
            }).catch(error => console.log(error))
        }

    }
    handleChangeCheck = (event) => {
        if (event.target.checked == true) {
            this.setState({ selectedSetor: this.state.selectedSetor.concat({ value: event.target.name }) });
        } else {
            this.setState({ selectedSetor: this.state.selectedSetor.filter(m => m.value !== event.target.name) });
        }
    };
    linkMedSolWithSetor = async () => {
        if (this.state.cdMedSol !== '') {
            if (this.state.selectedSetor.length > 0) {
                for (var i = 0; i < this.state.selectedSetor.length; i++) {
                    const newMed = await this.state.medSolSetor.filter(med => med.setorExec != this.state.selectedSetor[i].value).concat({ setorExec: this.state.selectedSetor[i].value, cdMedSol: this.state.cdMedSol, nm_prestador: this.state.medSol });
                    this.setState({ medSolSetor: newMed })
                }
            } else {
                this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Selecione algum setor para obter vínculo' });
            }
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Selecione o médico solicitante' });
        }
    }
    /*-------------------------------------------------------------------------------Alert------------------------------------------------------- */
    handleCloseAlert = () => {
        this.setState({ alert: false });
    };
    /**------------------------------------------------------------------------------------------------------------------------------------------ */

    confirmar = () => {
        if (this.state.medSolSetor.length == this.state.setor_name.length) {
            this.props.setMedSol(this.state.medSolSetor)
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Selecione o médico solicitante para os setores' });
        }
    }
    render() {
        const { classes } = this.props
        return (
            < div >
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <Dialog
                    className={classes.title}
                    fullWidth={true}
                    maxWidth={'md'}
                    open={true}
                    onClose={this.handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="responsive-dialog-title">{" Médico Solicitante"}</DialogTitle>

                    <DialogContent>
                        <DialogContentText style={{ alignItems: 'center', textAlign: 'center' }} >
                            <div className={classes.setor}>
                                {this.state.setor_name.map(s => <div className={classes.title}>
                                    <div>
                                        <FormControlLabel
                                            className={{ label: { fontSize: '12px' } }}
                                            key={this.state.setor.filter(sn => sn.nm_set_exa == s)[0].value}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                    onChange={e => this.handleChangeCheck(e)}
                                                    name={this.state.setor.filter(sn => sn.nm_set_exa == s)[0].value}
                                                    checked={this.state.selectedSetor.filter(setor => setor.value == this.state.setor.filter(sn => sn.nm_set_exa == s)[0].value).join()}
                                                    checkedIcon={<CheckBoxIcon />} />

                                            }
                                            label={s} />
                                    </div>
                                    <div className={classes.label}>
                                        <labe>{this.state.medSolSetor.filter(ms => ms.setorExec == this.state.setor.filter(sn => sn.nm_set_exa == s)[0].value).map(ms => ms.nm_prestador).join()}</labe>
                                    </div>
                                </div>)}
                            </div>
                            <div className={classes.med}>
                                <div className={classes.root}>
                                    <Autosuggest
                                        renderInputComponent={this.renderInputComponentMedSol}
                                        suggestions={this.state.suggestionsMedSol}
                                        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequestedMedSol}
                                        onSuggestionsClearRequested={this.handleSuggestionsClearRequestedMedSol}
                                        getSuggestionValue={this.getSuggestionValueMedSol}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={{
                                            classes,
                                            id: 'react-autosuggest-simple',
                                            label: 'Médico Solicitante',
                                            placeholder: 'Digite o nome',
                                            value: this.state.medSol,
                                            onChange: this.handleChange('medSol'),
                                        }}
                                        theme={{
                                            container: classes.container,
                                            suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                            suggestionsList: classes.suggestionsList,
                                            suggestion: classes.suggestion,
                                        }}
                                        renderSuggestionsContainer={options => (
                                            <Paper {...options.containerProps} square>
                                                {options.children}
                                            </Paper>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Button className={classes.button} onClick={this.linkMedSolWithSetor}>Ok</Button>
                                </div>
                            </div>
                            <div className={classes.btn}>
                                <Button className={classes.button} onClick={this.props.close}>cancelar</Button>
                                <Button className={classes.button} onClick={this.confirmar} >confirmar</Button>
                            </div>

                        </DialogContentText>
                    </DialogContent>

                </Dialog>
            </div >
        )
    }
}
export default withStyles(useStyles)(DialogMedSetor)
