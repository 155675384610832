import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import api from '../../services/api';
import './Remarcar.css'
import InputPaciente from './Input';
import Horarios from './Horarios2';
import Hashids from 'hashids';
import Encaixe from './Encaixe';
import DialogTableConfirma from './DialogTableConfirmaContato';
// import Table2 from './TableAgendados';
import Table from './TableAgendados2';

import Alert from '../Alertas/Alert';
import Confirm from '../Alertas/Confirm';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CheckIcon from '@material-ui/icons/Check';

const dataInicial = () => {
    var data_agenda = new Date()
    var dataFinal = data_agenda.getFullYear() + "-" + ((data_agenda.getMonth() + 1) < 10 ? "0" + (data_agenda.getMonth() + 1) : (data_agenda.getMonth() + 1)) + "-" + data_agenda.getDate()
    return dataFinal
}
export default class Remarcar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkedItems: new Map(),
            success: false,
            loading: false,
            agendamentos: [],
            status: false,
            pacientes: [],
            remarca: false,
            selecionado: null,
            nome: null,
            fone: null,
            unidade: null,
            convenio: null,
            plano: null,
            exame: null,
            statusDesmarca: false,
            cd_paciente: null,
            editar: false,
            cd_agenda: '',
            selected: [],
            exames_selected: [],
            encaixe: false,
            recurso: null,
            data: dataInicial(),
            dataInput: new Date(),
            alert: false,
            alertTitle: null,
            alertBody: null,
            confirm: false,
            confirmTitle: null,
            confirmBody: null,
            ds_obs: false,
            confirmaContato: false,
            ds_tel: false,
            telefone: null,
            loadingConfirmRemove: false
        }
        this.getRecursosUnidades();
        this.myRef = React.createRef();
        this.myRefConfirm = React.createRef();
        this.editarPaciente = this.editarPaciente.bind(this);
        this.editPacient = React.createRef();
    }

    getRecursosUnidades = async () => {
        const unidade = JSON.parse(localStorage.getItem('unidades'));
        if (unidade !== null && Object.keys(unidade).length > 0) {
            let unidades = [];
            await unidade.forEach(element => {
                unidades.push(element.value)
            });
            unidades = unidades.toString();
            unidade.push({ value: unidades, label: "Todas as Unidades" })
            var resultado = []
            api.post('/recursosSearch', {
                cd_empresa: unidade[unidade.length - 1].value
            })
                .then(async response => {
                    resultado = response.data
                    let recursos = [];
                    await response.data.forEach(element => {
                        recursos.push(element.value)
                    });
                    recursos = recursos.toString();
                    resultado.push({ value: recursos, label: "Todos os Recursos" })
                    this.setState({ recurso: resultado[resultado.length - 1], unidade: unidade[unidade.length - 1] })
                })
        }

    }
    fecharObs = () => {
        this.setState({ ds_obs: false, ds_tel: false, selected: [], exames_selected: [] })
    }
    getObsTelefone = it => {
        if (it != '' && it.length == 1) {
            api.post('/selectObs', {
                cd_it_agenda: it.join()
            }).then(response => {
                api.post('/selectTelefone', {
                    cd_it_agenda: it.join()
                }).then(responseTelefone => {
                    response.data[0].ds_observacao_geral != null ? this.setState({ obs: response.data[0].ds_observacao_geral, ds_obs: true }) : this.setState({ obs: null, ds_obs: true })
                    responseTelefone.data[0].fone != null ? this.setState({ telefone: responseTelefone.data[0].fone, ds_tel: true }) : this.setState({ telefone: null, ds_tel: true })
                })
            })
        }
        if (it.length > 1 || it.length == 0) {
            this.setState({ ds_obs: false, ds_tel: false })
        }
    }
    handleChecked = (newSelecteds, pac) => {
        this.getObsTelefone(newSelecteds)
        this.setState({ selected: newSelecteds, exames_selected: pac })
    }
    printOrientacao = async () => {
        const hashids = new Hashids();
        var pacVal = this.state.exames_selected.map(a => a.nm_paciente)
        var domain = localStorage.getItem('domain');
        if (this.state.exames_selected.filter(p => p.nm_paciente === pacVal[0]).length === this.state.exames_selected.length) {
            this.state.selected.map(s => {
                window.open(`http://api.chronos.vallions.com.br/print/orientacao/${domain}/${hashids.encode(s)}`, '_blank');
            })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível imprimir orientações de pacientes diferentes.' });
        }
    }

    checkDesmarcar = async () => {
        //consulta os que já foram atendidos
        const response = await api.post('/checkDesmarcar', {
            it_agc: this.state.selected.join(),
        });
        if (response.data.length === this.state.exames_selected.length) {
            //se for true, vai desmarcar
            this.setState({ statusDesmarca: true })
        }
    }

    desmarcar = () => {
        this.setState({ confirm: true, confirmTitle: "Atenção", confirmBody: "Esta ação realizará o cancelamento do exame, Deseja confirmar?" });
    }

    enviar = () => {
        if (this.state.exames_selected[0].sn_encaixe == 'N') {
            sessionStorage.setItem('remarca', JSON.stringify(this.state.exames_selected))
            this.setState({ remarca: true });
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção:', alertBody: 'Não é possivel remarcar encaixe' });
        }
    }

    editarPaciente = () => {
        this.setState({ editar: true });
    };

    handleClose = () => {
        this.setState({ encaixe: false });
    };

    handleCloseAlert = () => {
        this.setState({ alert: false });
    };

    handleCloseConfirmF = async () => {
        await this.setState({ confirm: false });
        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Operação cancelada!' })
    }

    handleCloseConfirmT = async () => {
        await this.checkDesmarcar();

        let desmarca = (item, index) => {
            this.myRefConfirm.current.alterLoading(true)
            api.post('/removeAgendamento', {
                it_agc: item,
                user: localStorage.getItem('login')
            }).then(() => {
                let remarca = sessionStorage.getItem('remarca');
                if (remarca !== null) {
                    remove()
                }
                if ((this.state.selected.length - 1) == index) {
                    document.location.reload(true)
                }
            }).catch(error => {
                this.myRefConfirm.current.alterLoading(false)
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        }

        let desmarcarEncaixe = (item, cd_agenda_central, index) => {
            this.myRefConfirm.current.alterLoading(true)
            api.post('/removeEncaixe', {
                cd_it_ag: item,
                user: localStorage.getItem('login'),
                cd_agenda_central: cd_agenda_central
            }).then(() => {
                let remarca = sessionStorage.getItem('remarca');
                if (remarca !== null) {
                    remove()
                }
                if ((this.state.selected.length - 1) == index) {
                    document.location.reload(true)
                }
            }).catch(error => {
                this.myRefConfirm.current.alterLoading(false)
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
        }

        let remove = () => {
            this.setState({ statusDesmarca: false })
            this.myRefConfirm.current.alterLoading(false)
            this.setState({ loadingConfirmRemove: false })
            sessionStorage.removeItem('remarca');
        }

        let confirmado = () => {
            this.state.selected.map((s, index) => {
                var exame = this.state.exames_selected.filter(r => r.cd_it_agenda_central == s)
                exame[0].sn_encaixe == 'S' ? desmarcarEncaixe(s, exame[0].cd_agenda_central, index) : desmarca(s, index)
            })
        }

        this.state.statusDesmarca ? confirmado() : this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'O paciente já foi atendido!' })
    }

    remarcar = async () => {
        const response = await api.post('/checkDesmarcar', {
            it_agc: this.state.selected.join(),
        });
        if (response.data.length === this.state.exames_selected.length) {
            var qtdPaciente = this.state.exames_selected.filter(s => s.nm_paciente == this.state.exames_selected[0].nm_paciente).length
            this.state.exames_selected.length != qtdPaciente ? this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível remarcar multiplos agendamentos de pacientes diferentes!' }) : this.enviar()
        }
        else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'O paciente já foi atendido!' })
        }
    }

    editar = () => {
        if (this.state.selected.length > 1 || this.state.selected.length === 0) {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Não é possível editar o paciente!' });
        } else {
            this.editarPaciente()
        }
    }

    onClick = async (resp) => {
        this.setState({ pacientes: [], agendamentos: resp })
        this.clickSearch(resp)
    }

    onClickRemove = async (resp) => {
        await this.setState({ agendamentos: resp })
        this.myRef.current.searchData([])
    }
    onClickReload = () => {
        this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Paciente inserido com sucesso.' });
        this.myRef.current.setExamesStart()
        this.handleClose()
    }
    clickSearch = res => {
        this.setState({ ds_obs: false, ds_tel: false, selected: [], exames_selected: [] })
        this.myRef.current.searchData(res)
    }

    handleRecurso = recurso => {
        this.setState({ recurso: recurso })
    }
    handleSetor = setor => {
        console.log("Mudou setor :", setor)
        this.setState({ setor: setor })
    }
    handleUnidade = unidade => {
        console.log("Mudou unidade :", unidade)
        this.setState({ unidade: unidade })
    }

    handleData = (dataInput, data) => {
        this.setState({ dataInput: dataInput, data: data })
    }

    validaEncaixe = exame => {
        console.log(this.state.setor.label)
        console.log(this.state.unidade.label)
        if (this.state.setor.label === "Todos os Setores" || this.state.unidade.label === "Todas as Unidades") {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Selecione somente uma unidade e setor' });
        } else {
            this.setState({ encaixe: true })
        }
    }
    handleChange = name => (event, { newValue }) => {

        this.setState({ [name]: newValue.toUpperCase() });
        if (name == 'valueNome' && newValue == '') this.setState({ valueNome: newValue, telefone: '', dtNasc: new Date(), valueCpf: '' })
    };
    setOrientação = () => {
        api.post('/updateObservacao', {
            cd: this.state.selected.join(),
            obs: this.state.obs
        }).then(response => {
            this.setState({ alert: true, alertTitle: 'Concluído', alertBody: 'Alteração concluída com sucesso !' });
        }).catch(error => {
            this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
        });
    }
    render() {
        const { success, loading, agendamentos, status } = this.state;

        return (
            <div className="remarcar-container" id="remarcar_id">
                {this.state.confirmaContato && <DialogTableConfirma close={e => this.setState({ confirmaContato: false })} it_agenda={this.state.selected.join()} />}
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                {this.state.confirm && <Confirm ref={this.myRefConfirm} title={this.state.confirmTitle} body={this.state.confirmBody} handleCloseConfirmF={this.handleCloseConfirmF} handleCloseConfirmT={this.handleCloseConfirmT} />}
                {this.state.encaixe && <Encaixe handleClose={this.handleClose} cd_agenda={this.state.cd_agenda} unidade={this.state.unidade} recurso={this.state.recurso} setor={this.state.setor} dataInput={this.state.dataInput} data={this.state.data} reload={this.onClickReload} />}
                {this.state.remarca ? <Horarios nome={this.state.exames_selected[0].nm_paciente} unidade={this.state.exames_selected[0].cd_multi_empresa} convenio={this.state.exames_selected[0].cd_convenio} dtNasc={this.state.exames_selected[0].dt_nascimento} plano={this.state.exames_selected[0].cd_con_pla} exame={this.state.exames_selected.map(s => s.cd_item_agendamento)} fone={this.state.exames_selected[0].fone} cd_paciente={this.state.exames_selected[0].cd_paciente} /> :
                    <Paper className="remarcar-paper">
                        <div className="remarcar-input">
                            <InputPaciente onClick={this.onClick} onClickRemove={this.onClickRemove} changeUnidade={this.handleUnidade} changeSetor={this.handleSetor} changeRecurso={this.handleRecurso} changeData={this.handleData} />
                        </div>
                        <div className="remarcar-table">
                            <Table ref={this.myRef} checked={this.handleChecked} imprimir={this.printOrientacao} desmarcar={this.desmarcar} remarcar={this.remarcar} />
                        </div>
                        <div className="remarcar-buttons">
                            <div className="remarcar-buttons">
                                {this.state.ds_obs && <div className="remarcar-buttons">
                                    <textarea placeholder="Observação" className="textarea-obs" onChange={e => this.setState({ obs: e.target.value })} rows="5" >{this.state.obs}</textarea>
                                    <div className="button-obs">
                                        <IconButton aria-label="delete" color="primary" size="small" onClick={e => this.setOrientação()}>
                                            <SaveIcon fontSize="inherit" />
                                        </IconButton>
                                    </div>
                                </div>}
                                {this.state.ds_tel && <div className="button-tell">
                                    <FormControl>
                                        <OutlinedInput
                                            placeholder="Telefone"
                                            id="standard-adornment-password"
                                            value={this.state.telefone}
                                            onChange={this.handleChange("telefone")}
                                            endAdornment={
                                                < InputAdornment position="end" >
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={e => this.setState({ confirmaContato: true })}
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl >
                                </div>}
                            </div>
                            <Button variant="contained" color="primary" className="remarcar-button" onClick={e => this.validaEncaixe()} >Encaixe</Button>
                        </div>
                    </Paper>
                }
            </div>
        );
    }

}

