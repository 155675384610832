import { AUTH_LOGIN, AUTH_GET_PERMISSIONS, AUTH_CHECK, AUTH_LOGOUT, AUTH_ERROR } from 'react-admin';
export default (type, params) => {
    if (type === AUTH_LOGIN) {
        // localStorage.removeItem('unidades');
        const { username, password, base } = params;
        // const domain = 'SRA_PRD';
        localStorage.setItem('domain', base.toUpperCase());

        const unidades = new Request('http://api.chronos.vallions.com.br/api/empresasUsuarioLogin', {
            method: 'POST',
            body: JSON.stringify({ cd_usuario: username.toUpperCase() }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }),
        });
        fetch(unidades)
            .then(response => {
                return response.json();
            }).then(({ unidades,base }) => {
                localStorage.setItem('unidades', JSON.stringify(unidades))
                localStorage.setItem('base', JSON.stringify(base))
            });
        const request = new Request('http://api.chronos.vallions.com.br/api/auth/login', {
            method: 'POST',
            body: JSON.stringify({ username: username.toUpperCase(), password }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                //'Content-Type': 'application/x-www-form-urlencoded',
            }),
        })


        return fetch(request)

            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, type_user, login, s_p, s_a,name }) => {
                sessionStorage.setItem('token', token);
                localStorage.setItem('permissions', type_user);
                localStorage.setItem('name_chronos', name);
                localStorage.setItem('login', login);
                localStorage.setItem('s_p', s_p);
                localStorage.setItem('s_a', s_a);
            });

    }
    if (type === AUTH_LOGOUT) {
        sessionStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('login');
        localStorage.removeItem('unidades');
        localStorage.removeItem('name_chronos');
        // window.location.reload();
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return sessionStorage.getItem('token') && localStorage.getItem('login')
            ? Promise.resolve()
            : Promise.reject();
    }
    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const permissions = localStorage.getItem('permissions');
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    }
    return Promise.resolve();
}