import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Emoji from "react-emoji-render";

export default class AlertDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            body: this.props.body
        }
    }

    render() {
        return (
            <div>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{backgroundColor: '#023575', color: '#fff', textAlign: 'center'}} id="alert-dialog-title">{this.state.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText  id="alert-dialog-description">
                        <Emoji text={this.state.body}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.props.handleCloseAlert} color="primary" autoFocus>
                            OK!
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}