import React from 'react';
import Dropzone from 'react-dropzone';
import { Paper, Button } from '@material-ui/core'
import './dropzone.css';
import MaterialTable from 'material-table'
import axios from 'axios';
import Alert from '../Alertas/Alert';


export default class Conciliacao extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            files: [],
            data: [],
            loading: false
        }
    }
    onDrop = (acceptedFiles => {
        this.setState({ files: acceptedFiles });
    })

    removeFile = (file) => {
        let newFiles = this.state.files
        newFiles.splice(newFiles.indexOf(file), 1)
        this.setState({ files: newFiles });
    }
    sendFile = () => {
        this.setState({ loading: true })
        this.state.files.map((file) => {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('domain', localStorage.getItem('domain'));
            axios({
                method: 'post',
                url: `http://api-convenio.chronos.vallions.com.br/api/xmlReconciliacao`, 
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData
            }).then(res => {
                this.setState({ data: res.data, loading: false })
            }).catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro', alertBody: `${error}`, loading: false });
            })
        });
    }
    handleCloseAlert = () => {
        this.setState({ alert: false })
    };
    render() {
        const files = this.state.files.map(file => (
            <li key={file.path}>
                {file.path} - {file.size} bytes{" "}
                <button className="drop" onClick={() => this.removeFile(file)}>Remover</button>
            </li>
        ));
        return (
            <Paper style={{ width: '100%', padding: '15px' }}>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <div style={{ margin: '15px' }}>
                    <Dropzone onDrop={this.onDrop} >
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                                <section className="container">
                                    <input {...getInputProps()} />
                                    <p style={{ color: '#0078F0', fontStretch: 'semi-condensed', fontSize: '10px' }}>Clique para anexar</p>
                                    <aside>
                                        <h4 style={{ fontStretch: 'semi-condensed', fontSize: '10px' }}>Arquivos</h4>
                                        <ul>{files}</ul>
                                    </aside>
                                    {/* {files.length > 0 && <button className="drop" onClick={this.removeAll}>Remover todos</button>} */}
                                </section>
                            </div>
                        )}
                    </Dropzone>
                    <div style={{ margin: '5px' }}>
                        <Button variant="contained" color="primary" onClick={() => this.sendFile()}>Enviar</Button>
                    </div>
                </div>
                <div style={{ maxWidth: '100%' }}>
                    <MaterialTable
                        columns={[
                            { title: 'Paciente', field: 'paciente' },
                            { title: 'Carteira', field: 'carteira' },
                            { title: 'Data', field: 'data', type: 'date' },
                            { title: 'Procedimento', field: 'procedimento' },
                            { title: 'Valor Liberado', field: 'valorLiberado' },
                            { title: 'Glosa', field: 'valorGlosa' },
                            { title: 'Erro', field: 'error' },
                        ]}
                        data={this.state.data}
                        isLoading={this.state.loading}
                        title="Erros"
                        style={
                            { fontSize: '0.675rem !important' }
                        }
                        options={{
                            cellStyle: {
                                maxWidth: '7em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '0.675rem'
                            },
                            headerStyle: {
                                zIndex: '0',
                            },
                            
                        }}
                    />
                </div>
            </Paper>
        );
    }
}