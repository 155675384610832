import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            body: this.props.body,
            loading: false
        }
    }

    alterLoading = valor => {
        this.setState({ loading: valor })
    }
    render() {
        return (
            <div>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="alert-dialog-title">{this.state.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.body}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.props.handleCloseConfirmF} color="primary" autoFocus>
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={this.props.handleCloseConfirmT} disabled={this.state.loading} color="primary" autoFocus>
                            {this.state.loading && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />}Concordo
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}