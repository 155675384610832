import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from '../services/api'
import Perguntas from './Perguntas/Perguntas-Form';
import PropTypes from 'prop-types';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import deburr from 'lodash/deburr';

import Alert from './Alertas/Alert';
import { format } from 'date-fns';

import Cadastro from './CadastroPaciente';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';

var suggestions = []
const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing.unit,
    },
    container: {
        position: 'relative',
        width: '100%',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        width: '600px',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',

    },
    suggestionsList: {
        margin: 0,
        padding: theme.spacing.unit * 2,
        listStyleType: 'none',
    },
    divider: {
        height: theme.spacing(2),
    },
    dialogPaper: {
        minHeight: '380px',
        maxHeight: '380px',
    },
    dialogTextPaper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    }
});
const ajusteDate = (data) => {
    var d = new Date(data);
    d.setDate(d.getDate() + 1);
    return d
}
class DemoApp extends React.Component {
    constructor(props) {
        super(props);

        this.searchPacient = this.searchPacient.bind(this);

    }
    state = {
        cd_paciente: null,
        valueNome: '',
        valueTelefone: '',
        open: true,
        perguntas: false,
        itens: this.props.itens,
        nome: this.props.nome,
        unidade: this.props.unidade,
        convenio: this.props.convenio,
        plano: this.props.plano,
        info_itens: this.props.info_itens,
        obs: this.props.obs,
        stateSuggestions: [],
        alert: false,
        alertTitle: null,
        alertBody: null,
        cadastroPaciente: false,
        dtNasc: this.props.dtNasc
    };

    closePergunta = () => {
        this.setState({ perguntas: false })
    }

    handleCloseAlert = () => {
        this.setState({ alert: false });
    };

    handleNome = event => {
        this.setState({ valueNome: event.target.value.toUpperCase() });
        // setState(prevState => { return { ...prevState, valueNome: event.target.value } });
    }
    handleTelefone = event => {
        this.setState({ valueTelefone: event.target.value });
    }
    click = () => {
        // console.log(this.state.valueNome,this.state.dtNasc,this.state.valueTelefone)
        if (this.state.valueNome != '' && this.state.valueTelefone != '') {
            this.setState({ perguntas: true })
        }
        else {
            this.setState({ alert: true, alertTitle: 'Alerta', alertBody: 'Preencher o Nome do Paciente e o Telefone, para realizar o agendamento!' });
        }
    }

    ajusteDate = (data) => {
        var d = new Date(data);
        d.setDate(d.getDate() + 1);
        return d
    }
    searchPacient = (nameValue) => {
        if (nameValue.length > 3) {
            api.post('/searchNome', {
                nome: nameValue
            })
                .then((response) => {
                    this.setState({ stateSuggestions: response.data })
                })
                .catch(error => {
                    this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
                });
        }

    }
    searchDataNasc = (nameValue, dtNasc) => {
        api.post('/searchT4', {
            nome: nameValue,
            dt_nasc: dtNasc
        })
            .then(function (response) {
                // console.log(response.data)
                suggestions = response.data
                // console.log(suggestions)

            })
            .catch(error => {
                this.setState({ alert: true, alertTitle: 'Erro:', alertBody: `${error}` });
            });
    }
    renderInputComponent = (inputProps) => {
        const { classes, inputRef = () => { }, ref, ...other } = inputProps;

        return (
            <FormControl className={classes.input}>
                <InputLabel htmlFor="standard-adornment-password">
                    Nome
                </InputLabel>
                <Input
                    id="standard-adornment-password"
                    value={this.state.valueNome}
                    InputProps={{
                        inputRef: node => {
                            ref(node);
                            inputRef(node);
                        },
                    }}
                    {...other}
                    // onChange={handleChange("valueName")}
                    endAdornment={
                        < InputAdornment position="end" >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => this.setState({ cadastroPaciente: true })}
                            >
                                <PersonAddIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl >
        );
    }
    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const mae = suggestion.nm_mae == null ? " NÃO CADASTRADO" : suggestion.nm_mae;
        const celular = suggestion.celular == null ? " NÃO CADASTRADO" : suggestion.celular;
        const dt_nasc = suggestion.dt_nascimento == null ? " NÃO CADASTRADO" : suggestion.dt_nascimento;
        const matches = match(suggestion.nm_paciente, query);
        const parts = parse(suggestion.nm_paciente + "|Dt. Nasc: " + (suggestion.dt_nascimento == null || suggestion.dt_nascimento == undefined ? 'NÃO CADASTRADO' : format(ajusteDate(new Date(dt_nasc)), "dd/MM/yyyy")) + " | CEL:" + celular, matches);


        return (
            <MenuItem selected={isHighlighted} component="div">
                <div>
                    {parts.map(part => (
                        <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                            {part.text}
                        </span>
                    ))}
                </div>
            </MenuItem>
        );
    }
    getSuggestions(value) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;
        return inputLength === 0
            ? []
            : suggestions.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.nm_paciente.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }
                return keep;
            });
    }
    getSuggestionValue = (suggestion) => {
        this.setState({ cd_paciente: suggestion.cd_paciente, valueNome: suggestion.nm_paciente, valueTelefone: suggestion.celular != null ? suggestion.celular : '', dtNasc: ajusteDate(suggestion.dt_nascimento) })
        // setTimeout(function(){ searchAtendimento() }, 3000);
        return suggestion.nm_paciente;
    }
    handleSuggestionsFetchRequested = ({ value }) => {
        this.searchPacient(value.toUpperCase())
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        this.setState({ valueNome: value.toUpperCase() })
        if (this.state.valueNome.length > inputValue.length) {
            this.setState({ valueNome: value.toUpperCase(), valueTelefone: '' })

        }
    };
    handleChange = name => (event, { newValue }) => {
        this.setState({ [name]: newValue.toUpperCase() });

    };
    handleSuggestionsClearRequested = () => {
        this.setState({ stateSuggestions: [] })

    };
    autosuggestProps = {
        renderInputComponent: this.renderInputComponent,
        suggestions: this.state.stateSuggestions,
        onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
        onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
        getSuggestionValue: this.getSuggestionValue,
        renderSuggestion: this.renderSuggestion,
    };
    renderCadastroSucess = (cd_paciente, nm_paciente, nr_cpf, dt_nascimento, celular) => {
        this.setState({ cd_paciente: cd_paciente, valueNome: nm_paciente, valueCpf: nr_cpf != null ? nr_cpf : '', dtNasc: dt_nascimento, valueTelefone: celular != null ? celular : '' })
    }
    closeQueue = () => {
        this.setState({ perguntas: false });
        this.props.closeQueue();
    }
    render() {
        const { classes } = this.props;
        const { stateSuggestions } = this.state
        return (
            <div>
                {this.state.cadastroPaciente && <Cadastro handleClose={e => this.setState({ cadastroPaciente: false })} setCdPaciente={this.renderCadastroSucess} />}
                {this.state.perguntas ? <Perguntas obs={this.state.obs} queue={this.props.queue} itens={this.state.itens} info_itens={this.state.info_itens} close={this.closePergunta} closeQueue={this.closeQueue} nome={this.state.valueNome} plano={this.state.plano} unidade={this.state.unidade} convenio={this.state.convenio} telefone={this.state.valueTelefone} cd_paciente={this.state.cd_paciente} dtNasc={this.state.dtNasc} /> : ""}
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                <Dialog
                    open={this.state.open}
                    // onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth='md'
                    classes={{ paper: classes.dialogPaper }}
                >
                    <DialogTitle style={{ backgroundColor: '#023575', color: '#fff', textAlign: 'center' }} id="form-dialog-title">Alerta</DialogTitle>
                    <DialogContent classes={{ root: classes.dialogTextPaper }}>
                        <div>

                            <DialogContentText style={{ textAlign: 'center' }}>
                                Necessário preencher o Nome e o Telefone para prosseguir.
                            </DialogContentText>
                            <Autosuggest
                                renderInputComponent={this.renderInputComponent}
                                suggestions={stateSuggestions}
                                onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={{
                                    classes,
                                    id: 'react-autosuggest-simple',
                                    label: 'Nome',
                                    placeholder: 'Digite o nome',
                                    value: this.state.valueNome,
                                    onChange: this.handleChange('valueNome'),
                                }}
                                theme={{
                                    container: classes.container,
                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                    suggestionsList: classes.suggestionsList,
                                    suggestion: classes.suggestion,
                                }}
                                renderSuggestionsContainer={options => (
                                    <Paper {...options.containerProps} square>
                                        {options.children}
                                    </Paper>
                                )}
                            />
                        </div>
                        <TextField

                            margin="dense"
                            id="tel"
                            label="Telefone"
                            type="text"
                            value={this.state.valueTelefone}
                            onChange={e => this.handleTelefone(e)}
                            fullWidth
                        />

                    </DialogContent>
                    <DialogActions classes={classes}>
                        <Button variant="contained" onClick={this.props.handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={this.click} color="primary">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );

    }
}
DemoApp.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(styles)(DemoApp);