import React from 'react';
import { withStyles, Typography, IconButton, Input, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core'
import SelectReact, { components } from 'react-select';
import api from '../../../services/api';

import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ClearIcon from '@material-ui/icons/Clear';
import Alert from '../../Alertas/Alert';

class Pedido extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            cd_atendimento: this.props.cd_atendimento,
            sn_atendido: this.props.sn_atendido,
            isLoadingExame: false,
            selectedExame: null,

            exames_completos: this.props.exames,
            exames: this.props.exames,
            exames_atendido: [],

            examesConvenioPlanos: [],
            examesConvenioPlano: null,

            selectedSetorExec: [],

            pedidos: [],
            selectedPedido: null,
        }
        this.props.sn_atendido == 'N' && this.getExame(this.props.convenio.value, this.props.plano.value, this.props.empresa);
        this.getSetorExec();
        this.state.sn_atendido == 'S' && this.getPedido();
        this.state.sn_atendido == 'S' && this.getSelecionadosSetorExecAtendimento();
    }
    /*-------------------------------------------------PEDIDO-----------------------------------------------------------------*/
    getPedido = () => {
        api.post('/informacoesPedido', {
            atendimento: this.state.cd_atendimento
        }).then(async response => {
            const pedido = await response.data.map(e => { return { value: e.cd_ped_rx, label: e.cd_ped_rx, exame: e.cd_exa_rx, cd_set_exa: e.cd_set_exa } });
            this.setState({ exames_atendido: response.data, pedidos: pedido })
            this.props.setExamesAtendido(response.data)
        }).catch(error => {

        })
    }
    /*-----------------------------------------------------------------------------------------------------------------------*/

    /*-------------------------------------------------SETOR-----------------------------------------------------------------*/

    handleChangeSetor = (event, e) => {
        this.setState({ selectedSetorExec: this.state.selectedSetorExec.filter(set => set.exame != e.cd_item_agendamento).concat({ value: event.target.value, exame: e.cd_item_agendamento, nm_set_exa: event.target.name }) })
        this.props.setorExec(this.state.selectedSetorExec.filter(set => set.exame != e.cd_item_agendamento).concat({ value: event.target.value, exame: e.cd_item_agendamento, nm_set_exa: event.target.name }));
    }
    handleChangeSetorAtendido = (event, e) => {
        this.setState({ selectedSetorExec: this.state.selectedSetorExec.filter(set => set.cd_exa_rx != e.cd_exa_rx).concat({ cd_set_exa: event.target.value, cd_exa_rx: e.cd_exa_rx, exame: e.cd_item_agendamento, nm_set_exa: event.target.name }) })
        this.props.setorExec(this.state.selectedSetorExec.filter(set => set.cd_exa_rx != e.cd_exa_rx).concat({ cd_set_exa: event.target.value, cd_exa_rx: e.cd_exa_rx, exame: e.cd_item_agendamento, nm_set_exa: event.target.name }));
    }
    getSetorExec = (exames) => {
        if (exames != null) {
            exames.map((e) => {
                api.post('/setorExecutante', {
                    exame: e.cd_item_agendamento,
                    empresa: this.props.empresa
                }).then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        response.data[i].exame = e.cd_item_agendamento
                    }
                    if (this.state.selectSetorExec != undefined && this.state.selectSetorExec.length > 0) {
                        const result = this.state.selectSetorExec.filter(set => set.exame != e.cd_item_agendamento).concat(response.data);
                        this.setState({ selectSetorExec: result })
                    }
                    else {
                        this.setState({ selectSetorExec: response.data })
                    }
                }).catch(error => {
                    console.log(error)
                })
            })
        } else {
            this.state.exames.map((e) => {
                api.post('/setorExecutante', {
                    exame: e.cd_item_agendamento,
                    empresa: e.cd_multi_empresa
                }).then(response => {
                    for (var i = 0; i < response.data.length; i++) {
                        response.data[i].exame = e.cd_item_agendamento
                    }
                    if (this.state.selectSetorExec != undefined && this.state.selectSetorExec.length > 0) {
                        const result = this.state.selectSetorExec.filter(set => set.exame != e.cd_item_agendamento).concat(response.data);
                        this.setState({ selectSetorExec: result })
                    }
                    else {
                        this.setState({ selectSetorExec: response.data })
                    }
                }).catch(error => {
                    console.log(error)
                })
            })

        }

    }

    getSetorExecExaRx = (exame) => {
        api.post('/setorExecutanteExaRx', {
            exame: exame,
            empresa: this.props.empresa
        }).then(response => {
            if (this.state.selectSetorExec != undefined && this.state.selectSetorExec.length > 0) {
                const result = this.state.selectSetorExec.filter(set => set.cd_exa_rx != exame).concat(response.data);
                this.setState({ selectSetorExec: result })
            }
            else {
                this.setState({ selectSetorExec: response.data })
            }
        }).catch(error => {
            console.log(error)
        })
    }
    getSelecionadosSetorExecAtendimento = () => {
        api.post('/setorExecutanteAtendimento', {
            atendimento: this.state.cd_atendimento,
        }).then(response => {
            this.setState({ selectedSetorExec: response.data, selectSetorExec: response.data })
        }).catch(error => {
            console.log(error)
        })
    }
    handleClickOpenGuia = index => {
        this.setState({ dialogGuia: true, indexGuia: index })
    }
    /*-----------------------------------------------------------------------------------------------------------------------*/
    /*-------------------------------------------------EXAME-----------------------------------------------------------------*/
    getExame = (cd_convenio, cd_plano, cd_empresa) => {
        this.setState({ isLoadingExame: true })
        api.post('/exames', {
            cd_convenio: cd_convenio,
            cd_plano: cd_plano,
            cd_empresa: cd_empresa
        }).then(response => {
            this.setState({ exame: response.data, isLoadingExame: false })
        })
    }

    getExamePorSetor = (cd_convenio, cd_plano, cd_empresa, cd_set_exa) => {
        this.setState({ isLoadingExame: true })
        api.post('/examesPorSetor', {
            cd_convenio: cd_convenio,
            cd_plano: cd_plano,
            cd_empresa: cd_empresa,
            cd_set_exa: cd_set_exa
        }).then(response => {
            this.setState({ exame: response.data, isLoadingExame: false })
        })
    }
    handleChangePedido = (event) => {
        this.setState({ selectedPedido: event.target.value })
        var set_exa = this.state.pedidos.filter(pedido => pedido.value == event.target.value).map(pedido => pedido.cd_set_exa)
        if (set_exa.length > 0) {
            this.getExamePorSetor(this.props.convenio.value, this.props.plano.value, this.props.empresa, set_exa[0])
        }
    }
    handleChangeExame = (event) => {
        this.setState({ selectedExame: event })
    }
    removeExame = async (index) => {
        const newMapExame = await this.state.exames;
        const setoresExec = await this.state.selectedSetorExec.filter(set => set.exame != this.state.exames[index].cd_item_agendamento);
        this.props.setorExec(setoresExec);
        newMapExame.splice(index, 1)
        this.setState({ exames: newMapExame, selectedSetorExec: setoresExec })
        this.props.setAddExame(newMapExame);
        this.props.getPerguntas(newMapExame.map(e => e.cd_item_agendamento).join());
    }
    removeExameAtendido = (index, cd_itped_rx) => {
        if (cd_itped_rx != undefined) {
            api.post('/getRealizadoItPedido', {
                item: cd_itped_rx
            }).then(resp => {
                if (resp.data[0].sn_realizado != 'S') {
                    const newMapExame = this.state.exames_atendido.filter(e => e.cd_ped_rx == this.state.selectedPedido)[index]
                    newMapExame.delete = true;
                    newMapExame.insert = false;
                    this.setState({ exames_atendido: this.state.exames_atendido.filter(e => e.cd_ped_rx != this.state.selectedPedido).concat(this.state.exames_atendido.filter(e => e.cd_ped_rx == this.state.selectedPedido).filter((e, idx) => idx != index).concat(newMapExame)) })
                    this.props.setAddExame(this.state.exames_atendido);
                } else {
                    this.setState({ alert: true, alertTitle: 'Atenção', alertBody: ' Não é permitido exclusão, item já realizado' });
                }
            })
        } else {
            const newMapExame = this.state.exames_atendido.filter(e => e.cd_ped_rx == this.state.selectedPedido)[index]
            newMapExame.delete = true;
            newMapExame.insert = false;
            this.setState({ exames_atendido: this.state.exames_atendido.filter(e => e.cd_ped_rx != this.state.selectedPedido).concat(this.state.exames_atendido.filter(e => e.cd_ped_rx == this.state.selectedPedido).filter((e, idx) => idx != index).concat(newMapExame)) })
            this.props.setAddExame(this.state.exames_atendido);
        }


    }
    setPedidoExame = () => {
        if (this.state.sn_atendido == 'N') {
            if (this.state.selectedExame != null) {
                const medSolicitante = { nm_prestador: this.state.medSol, crm: this.state.crmMedSol, cd_prestador: this.state.cdMedSol }
                const pedidoExame = Object.assign(medSolicitante, this.state.selectedExame)
                this.props.setAddExame(this.state.exames.concat(pedidoExame));
                this.getSetorExec(this.state.exames.concat(pedidoExame))
                this.setState({ exames: this.state.exames.concat(pedidoExame) })
                this.props.getSetorExec(this.state.exames.concat(pedidoExame))
                this.props.getPerguntas(this.state.exames.concat(pedidoExame).map(e => e.cd_item_agendamento).join());
            } else {
                alert('Selecione um exame')
            }
        } else if (this.state.sn_atendido == 'S') {
            const medSolicitante = { nm_prestador: this.state.medSol, crm: this.state.crmMedSol, cd_prestador: this.state.cdMedSol, cd_ped_rx: this.state.selectedPedido, insert: true }
            const pedidoExame = Object.assign(medSolicitante, this.state.selectedExame)
            this.props.setAddExame(this.state.exames_atendido.concat(pedidoExame));
            this.getSetorExecExaRx(this.state.selectedExame.cd_exa_rx);
            this.setState({ exames_atendido: this.state.exames_atendido.concat(pedidoExame) })
            console.log(this.state.exames_atendido.concat(pedidoExame))
            // this.props.setExamesAtendido(response.data)

            // this.getSetorExec(this.state.exames_atendido.concat(pedidoExame))
            // this.props.getSetorExec(this.state.exames_atendido.concat(pedidoExame))
        }

    }
    /*----------------------------------------------------------------------------------------------------------------------*/

    handleChangePrestadorExame = (event) => {
        this.setState({ examesConvenioPlano: event.target.value })
    };
    render() {
        const { classes } = this.props
        return (
            <div style={{ border: '1px solid #ddd', margin: '20px', boxShadow: '1px 2px 1px #ddd', padding: '10px', borderRadius: '5px' }}>
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={() => this.setState({ alert: false })} />}
                <Typography style={{ fontSize: '1.0rem', fontWeight: 'bold' }} className="subtitle-pedido" variant="subtitle1" gutterBottom>PEDIDO DE EXAME</Typography>
                <div className="sub-title">
                    <FormControl style={{ width: '250px', margin: '5px' }} >
                        <InputLabel id="demo-simple-select-label">Pesquisa Pedido</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.selectedPedido}
                            onChange={this.handleChangePedido}
                        >

                            {this.state.pedidos.map(p => p.value).filter((elem, index, self) => index === self.indexOf(elem)).map(pedido => {
                                return <MenuItem value={pedido}>{pedido}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <SelectReact
                            className={classes.inputSelect}
                            styles={selectStyle}
                            placeholder='Selecione o Exame *'
                            value={this.state.selectedExame}
                            onChange={this.handleChangeExame}
                            options={this.state.exame}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                            }}
                            isDisabled={this.state.isDisabledExame}
                            closeMenuOnSelect={true}
                            getOptionValue={option => option.value}
                            isLoading={this.state.isLoadingExame}
                            isOptionDisabled={option => option.proibido == "S"}
                            name={'exames[]'}
                            closeMenuOnScroll={true}
                            pageSize={2}
                            hideSelectedOptions={false}
                        />
                    </FormControl>
                    <Tooltip title="Adicionar item">
                        <IconButton color="primary" style={{ color: '#ff5656', backgroundColor: '#fff', margin: '10px' }} onClick={this.setPedidoExame}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>

                </div>
                <div className='exame'>
                    {this.state.sn_atendido == 'N' && this.state.exames.map((e, index) => <div key={e.cd_it_agenda_central} className="exames">
                        <div style={{ marginTop: '21px' }}>
                            {e.ds_item_agendamento}
                        </div>
                        <div>
                            <span className={classes.pList}>{e.valor != null ? "R$" + e.valor + ",00" : e.tp_convenio === 'C' ? "Convênio" : "Sem valor cadastrado"}</span>
                            <FormControl style={{ width: '200px', margin: '5px' }} >
                                <InputLabel id="demo-simple-select-label">Setor Executante</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.selectedSetorExec.filter(set => set.exame == e.cd_item_agendamento).map(s => s.value)}
                                    onChange={event => this.handleChangeSetor(event, e)}
                                    name={this.state.selectSetorExec != undefined && this.state.selectSetorExec.filter(set => set.exame == e.cd_item_agendamento).map(set => set.nm_set_exa).join()}
                                >
                                    {this.state.selectSetorExec != undefined && this.state.selectSetorExec.filter(set => set.exame == e.cd_item_agendamento).map(set => <MenuItem value={set.cd_set_exa}>{set.nm_set_exa}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <Tooltip title="Deletar item">
                                <IconButton variant="outlined" color="primary" style={{ margin: '10px' }} onClick={() => this.removeExame(index)} ><ClearIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Guia">
                                <IconButton variant="outlined" color="primary" style={{ margin: '10px' }} onClick={() => this.props.handleClickOpenGuia(index, e.cd_item_agendamento)}>
                                    <AssignmentIcon />
                                </IconButton>
                            </Tooltip>


                        </div>
                    </div>
                    )}
                    {this.state.sn_atendido == 'S' && this.state.exames_atendido.filter(e => e.cd_ped_rx == this.state.selectedPedido).filter(e => e.delete != true).map((e, index) => <div key={e.cd_itped_rx} className="exames">
                        <div style={{ marginTop: '21px' }}>
                            {e.ds_exa_rx}
                        </div>
                        <div>
                            <FormControl style={{ width: '200px', margin: '5px' }} >
                                <InputLabel id="demo-simple-select-label">Setor Executante </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.selectedSetorExec.filter(set => set.cd_exa_rx == e.cd_exa_rx).map(s => s.cd_set_exa)[0]}
                                    onChange={event => this.handleChangeSetorAtendido(event, e)}
                                    name={this.state.selectSetorExec != undefined && this.state.selectSetorExec.filter(set => set.cd_exa_rx == e.cd_exa_rx).map(set => set.nm_set_exa).join()}
                                >
                                    {this.state.selectSetorExec != undefined && this.state.selectSetorExec.filter(set => set.cd_exa_rx == e.cd_exa_rx).map(set => <MenuItem value={set.cd_set_exa}>{set.nm_set_exa}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <Tooltip title="Deletar item">
                                <IconButton variant="outlined" color="primary" style={{ margin: '10px' }} onClick={() => this.removeExameAtendido(index, e.cd_itped_rx)} ><ClearIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Guia">
                                <IconButton variant="outlined" color="primary" style={{ margin: '10px' }} onClick={() => this.props.handleClickOpenGuiaAtendido(index, e.cd_exa_rx, this.state.selectedPedido, e.cd_itped_rx)}>
                                    <AssignmentIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    )}

                </div>
            </div>

        )
    }
}
const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        padding: 15,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid',
        borderRadius: 'none',
        boxShadow: 'none',
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: 'none',
            boxShadow: 'none'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    })
};
const styles = theme => ({
    inputSelect: {
        marginTop: '15px',
        width: '250px',
        margin: '5px'
    },
})
export default withStyles(styles)(Pedido);