import React from 'react';
import './Configuracao.css'
import Select, { components } from 'react-select';
import api from '../../../services/api';
import { Button } from "@material-ui/core";
import Alert from '../../Alertas/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        padding: 15,
        zIndex: 3,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 3,
    }),
    control: (provided, state) => ({
        ...provided,
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid red',
        borderRadius: 'none',
        boxShadow: 'none',
        zIndex: 3,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: 'none',
            boxShadow: 'none'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    })
};
export default class Inputs extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedUsuario: null,
            selectedUsuarioCopia: null,
            usuario: [],
            unidade: [],
            alert: false,
            loadingCopiar: false
        }
        this.setUsuarios();
    }

    componentDidMount() {

    }
    getUnidades = cd_usuario => {
        api.post('/empresasUsuarioConfig', {
            cd_usuario: cd_usuario
        }).then(response => {
            this.props.setUnidades(response.data.unidades, cd_usuario)
        }).catch(error => {
            console.log(error)
        })
    }
    setUsuarios = () => {
        const unidades = JSON.parse(localStorage.getItem('unidades'));
        if (unidades !== null && Object.keys(unidades).length > 0) {
            api.post('/usersEmpresa', {
                cd_empresa: unidades.map(u => u.value).join()
            }).then(response => {
                this.setState({ usuario: response.data })
            }).catch(error => {
                console.log(error)
            })
        }
    }
    handleChangeUsuario = selected => {
        this.getUnidades(selected.value)
        this.setState({ selectedUsuario: selected })
    }
    handleChangeUsuarioCopia = selected => {
        this.props.setUsuarioCopia(selected)
        this.setState({ selectedUsuarioCopia: selected })
    }
    handleCloseAlert = () => {
        this.setState({ alert: false })
    }
    copiarUsuario = () => {
        if (this.state.selectedUsuarioCopia != null && this.state.selectedUsuario != null) {
            this.setState({ loadingCopiar: true })
            api.post('/copiaConfigUser', {
                user: this.state.selectedUsuario.value,
                user_copia: this.state.selectedUsuarioCopia.value
            }).then(response => {
                this.setState({ alert: true, alertTitle: 'Sucesso', alertBody: 'Dados copiados com sucesso', loadingCopiar: false })
            }).catch(error => {
                this.setState({ loadingCopiar: false })
                console.log(error)
            })
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Usuário não selecionado' })
        }
    }
    render() {
        return (
            <div className="div-inputs">
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}

                <div className="div-formInputs">
                    <Select
                        className="input"
                        styles={selectStyle}
                        placeholder='Selecione o Usuário '
                        value={this.state.selectedUsuario}
                        onChange={this.handleChangeUsuario}
                        options={this.state.usuario}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        getOptionValue={option => option.value}
                        name={'unidade'}
                    />
                </div>
                <div className="div-formInputs">
                    <Select
                        className="input"
                        styles={selectStyle}
                        placeholder='Selecione o Usuário para copia'
                        value={this.state.selectedUsuarioCopia}
                        onChange={this.handleChangeUsuarioCopia}
                        options={this.state.usuario}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        getOptionValue={option => option.value}
                        name={'unidade'}
                    />
                </div>
                <div className="div-button">
                    <Button onClick={this.copiarUsuario}>{this.state.loadingCopiar && <CircularProgress size={28} style={{ color: '#3f51b5', marginRight: '8px' }} />} Copiar</Button>
                    <Button onClick={this.props.visualizarConfigs} > Visualizar</Button>
                </div>

            </div>

        );
    }

}

