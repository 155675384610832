import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));

export default function DialogAnexo(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [unidade, setUnidade] = React.useState(props.guiche != null ? props.guiche.branch : null);
    const [unidades, setUnidades] = React.useState(JSON.parse(localStorage.getItem('unidades')) != null ? JSON.parse(localStorage.getItem('unidades')) : null);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [statusActive, setStatusActive] = React.useState(props.guiche != null ? (props.guiche.status_active == "1" ? true : false) : true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [name, setName] = React.useState(props.guiche != null ? props.guiche.name : "");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleSave = () => {
        if (props.guiche == null) {
            api.post('/guiche', {
                name: name,
                branch: unidade,
                status_active: statusActive ? "1" : "0"
            }).then(resp => {
                console.log(resp.data)
                props.handleSave()
            })
        } else {
            api.put(`/guiche/${props.guiche.id}`, {
                name: name,
                branch: unidade,
                status_active: statusActive ? "1" : "0"
            }).then(resp => {
                console.log(resp.data)
                props.handleSave()
            })
        }

    }
    const handleName = (event) => {
        setName(event.target.value)
    }
    const handleChangeUnidade = (event) => {
        setUnidade(event.target.value);
    }

    const handleChange = (event) => {
        setStatusActive(event.target.checked);
    }
    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: "center" }}>Cadastro de Guichê</DialogTitle>
                <DialogContent style={{ textAlign: 'CENTER' }}>
                    <TextField id="standard-basic" label="Guichê" value={name} style={{ width: '300px' }} onChange={handleName} />
                    <FormControl style={{ width: '300px', marginLeft: '5px', marginRight: '5px' }} >
                        <InputLabel id="demo-simple-select-label">Unidade</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={unidade}
                            onChange={handleChangeUnidade}
                        >

                            {unidades.map(unidade => {
                                return <MenuItem value={unidade.value}>{unidade.label}</MenuItem>
                            })}

                        </Select>
                    </FormControl>
                    <FormControlLabel
                        style={{ padding: '15px' }}
                        control={
                            <Checkbox
                                checked={statusActive}
                                onChange={handleChange}
                                name="checkedB"
                                color="secondary"
                            />
                        }
                        label="Ativar guichê"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Voltar
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}