import React from 'react';
import Paper from '@material-ui/core/Paper';
import './Configuracao.css'
import ListConfiguracao from './ListConfiguracao';
import api from '../../../services/api';
import Inputs from './Inputs'
import DialogTree from './DialogTree';
import Alert from '../../Alertas/Alert';
const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        padding: 15,
        zIndex: 3,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 3,
    }),
    control: (provided, state) => ({
        ...provided,
        border: '0px',
        outline: '0px',
        borderBottom: '1px solid red',
        borderRadius: 'none',
        boxShadow: 'none',
        zIndex: 3,
        opacity: state.isDisabled ? 0.5 : 1,
        transition: 'opacity 300ms',
        '&:hover': {
            borderColor: 'none',
            boxShadow: 'none'
        },
        '&:focus': {
            borderColor: 'none',
            boxShadow: 'none'
        }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
    })
};
export default class Configuracao extends React.Component {

    constructor(props) {
        super(props)

        this.myRef = React.createRef();
        this.state = {
            dialogTree: false,
            cd_usuario_copia: null,
            cd_usuario: null,
            alert: false
        }
    }
    setUnidades = (unidade, cd_usuario) => {
        this.setState({ cd_usuario: cd_usuario })
        this.myRef.current.setUnidades(unidade, cd_usuario)
    }
    visualizarConfigs = () => {
        if (this.state.cd_usuario != null) {
            this.setState({ dialogTree: true })
        } else {
            this.setState({ alert: true, alertTitle: 'Atenção', alertBody: 'Usuário não selecionado' })
        }
    }
    handleClose = () => {
        this.setState({ dialogTree: false })
    }
    setUsuarioCopia = (usuarioCopia) => {
        this.setState({ cd_usuario_copia: usuarioCopia })
    }
    handleCloseAlert = () => {
        this.setState({ alert: false })
    }
    render() {
        return (
            <Paper className="remarcar-paper">
                {this.state.alert && <Alert title={this.state.alertTitle} body={this.state.alertBody} handleCloseAlert={this.handleCloseAlert} />}
                {this.state.dialogTree && <DialogTree handleClose={this.handleClose} cd_usuario={this.state.cd_usuario} />}
                <Paper className="div-table">
                    <div className="input-paper">
                        <div className="div-formControl">
                            <Inputs setUnidades={this.setUnidades} visualizarConfigs={this.visualizarConfigs} setUsuarioCopia={this.setUsuarioCopia} />
                        </div>
                    </div>
                </Paper>
                <div className="div-table">
                    <ListConfiguracao ref={this.myRef} />
                </div>
            </Paper >
        );
    }

}

